import { getApiDetailsDecorator } from '@supy/common';
import { NonFunctionProperties } from '@supy/core';

import { PercentageValue } from '../order';
import { GrnTotalsResponse } from './grn.model';

const ApiProperty = getApiDetailsDecorator<GrnTotalsResponse>();

export class GrnTotals {
  protected constructor(args: NonFunctionProperties<Omit<GrnTotals, 'adjustmentRate'>>) {
    this.discount = args.discount;
    this.fees = Number(args.fees ?? 0);
    this.subTotal = Number(args.subTotal ?? 0);
    this.taxAmount = Number(args.taxAmount ?? 0);
    this.total = Number(args.total ?? 0);

    // Computed properties
    this.adjustmentRate = GrnTotals.calculateAdjustmentRate(args);
  }

  @ApiProperty() discount: PercentageValue;
  @ApiProperty() fees: number;
  @ApiProperty() subTotal: number;
  @ApiProperty() taxAmount: number;
  @ApiProperty() total: number;

  // Computed proeprties
  readonly adjustmentRate: number;

  static deserialize(data: GrnTotalsResponse): GrnTotals {
    return new GrnTotals({
      discount: data.discount,
      fees: data.fees,
      subTotal: data.subTotal,
      taxAmount: data.taxAmount,
      total: data.total,
    });
  }

  static calculateAdjustmentRate({
    subTotal,
    discount,
    fees,
  }: Pick<GrnTotals, 'subTotal' | 'discount' | 'fees'>): number {
    return subTotal > 0 ? ((Number(discount?.value ?? 0) - fees) / subTotal) * 100 : 0;
  }
}
