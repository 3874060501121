import { Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { inject, Injectable } from '@angular/core';

import { IQueryResponse, Query } from '@supy/common';

import { CK_CUSTOMERS_BFF_URI } from '../config';
import {
  CkCatalogItemsQueryProps,
  CkCustomerAutoCompleteQueryProps,
  CkCustomerAutoCompleteResponse,
  CkCustomerCreatePayload,
  CkCustomerItemResponse,
  CkCustomerItemsUpdateRequest,
  CkCustomerResponse,
  CkCustomersQueryProps,
  CkCustomerUpdatePayload,
  CkDeleteManyRequest,
} from '../core';

@Injectable()
export class CkCustomersService {
  readonly #httpClient = inject(HttpClient);
  readonly #uriBff = inject(CK_CUSTOMERS_BFF_URI);

  get(id: string): Observable<CkCustomerResponse> {
    return this.#httpClient.get<CkCustomerResponse>(`${this.#uriBff}/${id}`);
  }

  getMany(query: Query<CkCustomersQueryProps>): Observable<IQueryResponse<CkCustomerResponse>> {
    return this.#httpClient.get<IQueryResponse<CkCustomerResponse>>(`${this.#uriBff}`, {
      params: query.toQueryParams(),
    });
  }

  autoComplete(
    query: Query<CkCustomerAutoCompleteQueryProps>,
  ): Observable<IQueryResponse<CkCustomerAutoCompleteResponse>> {
    return this.#httpClient.get<IQueryResponse<CkCustomerAutoCompleteResponse>>(`${this.#uriBff}/autocomplete`, {
      params: query.toQueryParams(),
    });
  }

  create(payload: CkCustomerCreatePayload): Observable<CkCustomerResponse> {
    return this.#httpClient.post<CkCustomerResponse>(`${this.#uriBff}`, payload.serialize());
  }

  update(id: string, payload: CkCustomerUpdatePayload): Observable<void> {
    return this.#httpClient.patch<void>(`${this.#uriBff}/${id}`, payload.serialize());
  }

  deleteMany(payload: CkDeleteManyRequest): Observable<void> {
    return this.#httpClient.patch<void>(`${this.#uriBff}`, payload);
  }

  getItems(id: string, query: Query<CkCatalogItemsQueryProps>): Observable<IQueryResponse<CkCustomerItemResponse>> {
    return this.#httpClient.get<IQueryResponse<CkCustomerItemResponse>>(`${this.#uriBff}/${id}/ck-items`, {
      params: query.toQueryParams(),
    });
  }

  updateItems(id: string, payload: CkCustomerItemsUpdateRequest): Observable<void> {
    return this.#httpClient.patch<void>(`${this.#uriBff}/${id}/ck-items`, payload);
  }
}
