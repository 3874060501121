import {
  CkCustomerGroupCreatePayload,
  CkCustomerGroupsRequestMetadata,
  CkCustomerGroupUpdatePayload,
  CkDeleteManyRequest,
} from '../../core';
import { CkCustomerGroupsFilters } from '../state/ck-customer-groups.state';

export class CkCustomerGroupsGetMany {
  static readonly type = '[CkCustomerGroups] GetMany';
}

export class CkCustomerGroupsInitFilters {
  static readonly type = '[CkCustomerGroups] InitFilters';
}

export class CkCustomerGroupsPatchFilters {
  static readonly type = '[CkCustomerGroups] PatchFilters';
  constructor(readonly payload?: Partial<CkCustomerGroupsFilters>) {}
}

export class CkCustomerGroupsResetFilters {
  static readonly type = '[CkCustomerGroups] ResetFilters';
}

export class CkCustomerGroupsPatchRequestMetadata {
  static readonly type = '[CkCustomerGroups] PatchRequestMetadata';
  constructor(readonly payload?: Partial<CkCustomerGroupsRequestMetadata>) {}
}

export class CkCustomerGroupsCreate {
  static readonly type = '[CkCustomerGroups] Create';
  constructor(readonly payload: CkCustomerGroupCreatePayload) {}
}

export class CkCustomerGroupsUpdate {
  static readonly type = '[CkCustomerGroups] Update';
  constructor(
    readonly id: string,
    readonly payload: CkCustomerGroupUpdatePayload,
  ) {}
}

export class CkCustomerGroupsDeleteMany {
  static readonly type = '[CkCustomerGroups] DeleteMany';
  constructor(readonly payload: CkDeleteManyRequest) {}
}
