import { getApiDetailsDecorator, LocalizedData } from '@supy/common';
import { NonFunctionProperties } from '@supy/core';

import {
  CkCatalogItemMargin,
  CkCatalogItemMarginType,
  CkCatalogItemPricingMethod,
  CkCatalogPriceList,
} from '../catalog';
import { CkPriceList } from './ck-price-list.entity';
import { CkPriceListItemResponse } from './ck-price-list.model';

const ApiProperty = getApiDetailsDecorator<CkPriceListItemResponse>();

export class CkPriceListItem {
  private constructor(args: NonFunctionProperties<CkPriceListItem>) {
    this.category = args.category;
    this.code = args.code;
    this.cost = args.cost;
    this.id = args.id;
    this.name = args.name;
    this.packagingId = args.packagingId;
    this.price = args.price;
    this.priceList = args.priceList;
    this.priceLists = args.priceLists;
    this.sellAtCost = args.sellAtCost;
    this.addMargin = args.addMargin;
    this.pricingMethod = args.pricingMethod;
  }

  @ApiProperty() readonly cost: number;
  @ApiProperty() readonly id: string;
  @ApiProperty() readonly priceLists: CkCatalogPriceList[];
  readonly category: LocalizedData | null;
  readonly code: string;
  readonly name: string;
  readonly packagingId: string;
  readonly price: number;
  readonly priceList: CkPriceList;
  readonly sellAtCost: boolean;
  readonly addMargin: CkCatalogItemMargin;
  readonly pricingMethod: CkCatalogItemPricingMethod;

  static deserialize(data: CkPriceListItemResponse, { priceList }: DeserializedArgs): CkPriceListItem {
    const catalogPriceLists = data.priceLists.map(priceList => CkCatalogPriceList.deserialize(priceList));
    const itemPriceList = catalogPriceLists.find(({ id }) => id === priceList.id);

    return new CkPriceListItem({
      category: data.retailerItem.category.name as LocalizedData,
      code: data.packaging.code,
      cost: data.cost,
      id: data.id,
      name: data.packaging.name,
      packagingId: data.packaging.id,
      price: itemPriceList?.price ?? 0,
      priceList,
      priceLists: catalogPriceLists,
      sellAtCost: itemPriceList?.pricingMethod === CkCatalogItemPricingMethod.AtCost,
      addMargin: {
        amount: itemPriceList?.addMargin.amount ?? 0,
        type: itemPriceList?.addMargin.type ?? CkCatalogItemMarginType.Percentage,
      },
      pricingMethod: itemPriceList?.pricingMethod,
    });
  }
}

interface DeserializedArgs {
  readonly priceList: CkPriceList;
}
