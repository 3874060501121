import { UpdateOrderRequest } from '@supy/orders';

import {
  CkOrdersRequestMetadata,
  CreateCkOrderB2bPayload,
  ShipCkOrdersB2bPayload,
  UpdateCkOrderB2bPayload,
} from '../../core';
import { CkOrdersFilters } from '../state';

export class CkOrdersGetDetailed {
  static readonly type = '[CkOrders] GetDetailed';
  constructor(readonly payload: { id: string; fromCache?: boolean }) {}
}

export class CkOrdersGetB2b {
  static readonly type = '[CkOrders] GetB2bDetailed';
  constructor(readonly payload: { id: string; fromCache?: boolean }) {}
}

export class CkOrdersResetB2b {
  static readonly type = '[CkOrders] ResetB2b';
}

export class CkOrdersGetMany {
  static readonly type = '[CkOrders] GetMany';
}

export class CkGetRetailerBranches {
  static readonly type = '[CkOrders] GetRetailerBranches';
  constructor(readonly retailerId: string) {}
}

export class CkOrdersInitFilters {
  static readonly type = '[CkOrders] InitFilters';
}

export class CkOrdersSetFilters {
  static readonly type = '[CkOrders] SetFilters';
  constructor(readonly payload?: CkOrdersFilters) {}
}

export class CkOrdersPatchFilters {
  static readonly type = '[CkOrders] PatchFilters';
  constructor(readonly payload?: Partial<CkOrdersFilters>) {}
}

export class CkOrdersResetFilters {
  static readonly type = '[CkOrders] ResetFilter';
}

export class CkOrdersPatchRequestMeta {
  static readonly type = '[CkOrders] PatchRequestMeta';
  constructor(readonly payload: Partial<CkOrdersRequestMetadata>) {}
}

export class CkOrdersUpdate {
  static readonly type = '[CkOrders] Update';
  constructor(readonly payload: { id: string; body: UpdateOrderRequest }) {}
}

export class CkOrdersConfirmMany {
  static readonly type = '[CkOrders] ConfirmMany';
  constructor(readonly orderIds: string[]) {}
}

export class CkOrdersShipMany {
  static readonly type = '[CkOrders] ShipMany';
  constructor(
    readonly payload: {
      readonly orderIds: string[];
      readonly updateStock: boolean;
    },
  ) {}
}

export class CkOrdersRejectMany {
  static readonly type = '[CkOrders] RejectMany';
  constructor(readonly orderIds: string[]) {}
}

export class CkOrdersCreateB2b {
  static readonly type = '[CkOrders] CreateB2b';
  constructor(readonly payload: CreateCkOrderB2bPayload) {}
}

export class CkOrdersUpdateB2b {
  static readonly type = '[CkOrders] UpdateB2b';
  constructor(
    readonly orderId: string,
    readonly payload: UpdateCkOrderB2bPayload | ShipCkOrdersB2bPayload,
  ) {}
}
