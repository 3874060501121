import { ChangeDetectionStrategy, Component, Input, input, output } from '@angular/core';

import { BaseOrder } from '../../../core';

@Component({
  selector: 'supy-retailer-order-details-drawer-header[order]',
  templateUrl: './retailer-order-details-drawer-header.component.html',
  styleUrls: ['./retailer-order-details-drawer-header.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class RetailerOrderDetailsDrawerHeaderComponent<T extends BaseOrder> {
  @Input() protected readonly isInventoryOrder: boolean;
  @Input() protected readonly isCkOrder: boolean;
  @Input() protected readonly isInitialLoading: boolean;
  @Input() protected readonly nextDisabled: boolean;
  @Input() protected readonly order: T;
  @Input() protected readonly previousDisabled: boolean;
  protected readonly hidePrices = input<boolean>();

  readonly closeClicked = output<void>();
  readonly nextClicked = output<T>();
  readonly previousClicked = output<T>();
  readonly expandClicked = output<T>();

  readonly downloadPdfClicked = output<void>();
}
