import { getApiDetailsDecorator, Supplier } from '@supy/common';
import { BaseActivity } from '@supy/components';
import { NonFunctionProperties } from '@supy/core';
import { CreationSourceEnum, DetailedOrderUpdate, DiscountType, OrderStatus, OrderUpdateStatus } from '@supy/orders';

import { CkOrderB2bCustomerSnapshot, CkOrderB2bResponse, CkOrderDiscount } from './ck-order-b2b.model';
import { CkOrderItemB2b } from './ck-order-item-b2b.entity';

const ApiProperty = getApiDetailsDecorator<CkOrderB2bResponse>();

type CkOrderB2bArgs = Omit<
  CkOrderB2b,
  'activities' | 'comments' | 'invoiceDiscountAmount' | 'invoiceDiscountType' | 'isCkCreationSource'
>;

export class CkOrderB2b {
  private constructor(args: NonFunctionProperties<CkOrderB2bArgs>) {
    this.id = args.id;
    this.status = args.status;
    this.items = args.items;
    this.creationSource = args.creationSource;
    this.otherFees = args.otherFees;
    this.discount = args.discount;
    this.externalDocNumber = args.externalDocNumber;
    this.number = args.number;
    this.invoiceDueDate = args.invoiceDueDate;
    this.customer = args.customer;
    this.centralKitchen = args.centralKitchen;
    this.deliveryDate = args.deliveryDate;
    this.updates = args.updates;

    // Computed properties

    this.invoiceDiscountAmount = this.discount?.amount;
    this.invoiceDiscountType = this.discount?.type;
    this.isCkCreationSource = this.creationSource === CreationSourceEnum.CentralKitchen;
    this.activities = this.updates.reduce<BaseActivity[]>(
      (acc, { status, user, createdAt }) => [{ action: status, user, createdAt }, ...acc],
      [],
    );
    this.comments = this.updates.reduce<string[]>((acc, { status, comment }) => {
      if (status === OrderUpdateStatus.comment) {
        acc.push(comment);
      }

      return acc;
    }, []);
  }

  @ApiProperty() readonly id: string;
  @ApiProperty() readonly status: OrderStatus;
  @ApiProperty({ key: 'ckItems' }) readonly items: CkOrderItemB2b[];
  @ApiProperty() readonly creationSource: CreationSourceEnum;
  @ApiProperty({ key: 'fees' }) readonly otherFees: number;
  @ApiProperty() readonly discount: CkOrderDiscount | null;
  @ApiProperty() readonly externalDocNumber: string | null;
  @ApiProperty() readonly number: string;
  @ApiProperty() readonly invoiceDueDate: Date;
  @ApiProperty() readonly customer: CkOrderB2bCustomerSnapshot;
  @ApiProperty({ key: 'supplier' }) readonly centralKitchen: Supplier;
  @ApiProperty() readonly deliveryDate: Date;
  @ApiProperty() readonly updates: DetailedOrderUpdate[];

  // Computed properties
  readonly invoiceDiscountAmount?: number;
  readonly invoiceDiscountType?: DiscountType;
  readonly isCkCreationSource: boolean;
  readonly activities: BaseActivity[];
  readonly comments: string[];

  static deserialize(data: CkOrderB2bResponse): CkOrderB2b {
    return new CkOrderB2b({
      id: data.id,
      status: data.status,
      items: data.ckItems.map(ckItem => CkOrderItemB2b.deserialize(ckItem, { creationSource: data.creationSource })),
      creationSource: data.creationSource,
      otherFees: data.fees,
      discount: data.discount,
      centralKitchen: data.supplier,
      customer: data.customer,
      deliveryDate: data.deliveryDate && new Date(data.deliveryDate),
      invoiceDueDate: data.invoiceDueDate && new Date(data.invoiceDueDate),
      externalDocNumber: data.externalDocNumber,
      number: data.number,
      updates: data.updates ?? [],
    });
  }
}
