import { FilterActionsOptions } from '@supy/common';

import {
  CkCustomerCreatePayload,
  CkCustomersRequestMetadata,
  CkCustomerUpdatePayload,
  CkDeleteManyRequest,
} from '../../core';
import { CkCustomersFilters } from '../state';

export class CkCustomersGet {
  static readonly type = '[CkCustomers] Get';
  constructor(readonly payload: { readonly id: string }) {}
}

export class CkCustomersGetMany {
  static readonly type = '[CkCustomers] GetMany';

  constructor(readonly args?: { readonly noLimit?: boolean }) {}
}

export class CkCustomersInitFilters {
  static readonly type = '[CkCustomers] InitFilters';
}

export class CkCustomersPatchFilters {
  static readonly type = '[CkCustomers] PatchFilters';
  constructor(
    readonly payload?: Partial<CkCustomersFilters>,
    readonly options?: FilterActionsOptions,
  ) {}
}

export class CkCustomersResetFilters {
  static readonly type = '[CkCustomers] ResetFilters';
}

export class CkCustomersPatchRequestMetadata {
  static readonly type = '[CkCustomers] PatchRequestMetadata';
  constructor(readonly payload?: Partial<CkCustomersRequestMetadata>) {}
}

export class CkCustomersCreate {
  static readonly type = '[CkCustomers] Create';
  constructor(readonly payload: CkCustomerCreatePayload) {}
}

export class CkCustomersUpdate {
  static readonly type = '[CkCustomers] Update';
  constructor(
    readonly id: string,
    readonly payload: CkCustomerUpdatePayload,
  ) {}
}

export class CkCustomersAutoComplete {
  static readonly type = '[CkCustomers] AutoCompleteSearch';
  constructor(readonly term: string) {}
}

export class CkCustomersDeleteMany {
  static readonly type = '[CkCustomers] DeleteMany';
  constructor(readonly payload: CkDeleteManyRequest) {}
}

export class CkCustomersExport {
  static readonly type = '[CkCustomers] Export';
}
