import { getApiDetailsDecorator, IdType } from '@supy/common';
import { NonFunctionProperties } from '@supy/core';

import { BaseCkCustomerReturnItem } from './ck-customer-return-item.entity';
import {
  CkCustomerReturnItemBaseRequest,
  CkCustomerReturnItemCreateRequest,
  CkCustomerReturnItemUpdateRequest,
} from './ck-customer-return-item.model';
import { CkCustomerReturnItemTaxRatePayload } from './ck-customer-return-tax-rate.payload';

const ApiProperty = getApiDetailsDecorator<CkCustomerReturnItemCreateRequest | CkCustomerReturnItemUpdateRequest>();

export abstract class CkCustomerReturnItemBasePayload extends BaseCkCustomerReturnItem {
  constructor(args: NonFunctionProperties<CkCustomerReturnItemBasePayload>) {
    super(args);
  }

  protected serialize(): CkCustomerReturnItemBaseRequest {
    return {
      appliedTax: new CkCustomerReturnItemTaxRatePayload({
        ...this.taxRate,
        itemPrice: this.price,
        itemQuantity: this.quantity,
      }).serialize(),
      comment: this.comment,
      price: this.price,
      quantity: this.quantity,
    };
  }
}

export class CkCustomerReturnItemCreatePayload extends CkCustomerReturnItemBasePayload {
  constructor(args: NonFunctionProperties<CkCustomerReturnItemCreatePayload>) {
    super(args);
    this.ckItem = args.ckItem;
  }

  @ApiProperty() readonly ckItem: IdType;

  serialize(): CkCustomerReturnItemCreateRequest {
    return {
      ...super.serialize(),
      ckItem: this.ckItem,
    };
  }
}

export class CkCustomerReturnItemUpdatePayload extends CkCustomerReturnItemBasePayload {
  constructor(args: NonFunctionProperties<CkCustomerReturnItemUpdatePayload>) {
    super(args);
    this.id = args.id;
  }

  @ApiProperty() readonly id: string;

  serialize(): CkCustomerReturnItemUpdateRequest {
    return {
      ...super.serialize(),
      id: this.id,
    };
  }
}
