import { getApiDetailsDecorator, LocalizedData } from '@supy/common';
import { NonFunctionProperties } from '@supy/core';

import { CkPriceList } from '../price-list';
import { CkCatalogPackagingBaseItemResponse, CkCatalogPackagingResponse } from './ck-catalog-packaging.model';
import { CkCatalogPriceList } from './ck-catalog-price-list.entity';

const ApiProperty = getApiDetailsDecorator<CkCatalogPackagingResponse>();

type CkCatalogPackagingArgs = Omit<CkCatalogPackaging, 'category' | 'code' | 'cost' | 'quantity'>;

export class CkCatalogPackaging {
  private constructor(args: NonFunctionProperties<CkCatalogPackagingArgs>) {
    this.id = args.id;
    this.name = args.name;
    this.baseItem = args.baseItem;
    this.priceLists = args.priceLists;

    // Computed properties
    this.category = this.baseItem?.category?.name as LocalizedData;
    this.code = this.baseItem.code;
    this.cost = this.baseItem.inventoryItem.cost;
    this.quantity = this.baseItem.inventoryItem.quantity;
  }

  @ApiProperty() readonly id: string;
  @ApiProperty({ key: 'fullPackageName' }) readonly name: string;
  @ApiProperty() readonly baseItem: CkCatalogPackagingBaseItemResponse;
  readonly priceLists: CkCatalogPriceList[];

  // Computed properties
  readonly category: LocalizedData | null;
  readonly code: string;
  readonly cost: number;
  readonly quantity: number;

  static deserialize(
    { id, fullPackageName, baseItem }: CkCatalogPackagingResponse,
    { priceLists }: DeserializedArgs,
  ): CkCatalogPackaging {
    return new CkCatalogPackaging({
      id,
      name: fullPackageName,
      baseItem,
      priceLists: priceLists.map(priceList => CkCatalogPriceList.from(priceList)),
    });
  }
}

interface DeserializedArgs {
  readonly priceLists: CkPriceList[];
}
