import { IdType, LocalizedSkeletonObjectType, SimpleUser, SkeletonObjectType, SupplierSnapshot } from '@supy/common';

import { CkCustomerReturnDocumentRequest, CkCustomerReturnDocumentResponse } from './ck-customer-return-document.model';
import {
  CkCustomerReturnItemCreateRequest,
  CkCustomerReturnItemResponse,
  CkCustomerReturnItemUpdateRequest,
} from './ck-customer-return-item.model';

export enum CkCustomerReturnStatus {
  Saved = 'saved',
  Posted = 'posted',
  New = 'new',
  Pending = 'pending',
  Discarded = 'discarded',
}

export enum CkCustomerReturnActivityType {
  Saved = 'saved',
  Posted = 'posted',
  Pushed = 'pushed',
  Transferred = 'transferred',
}

export interface CkCustomerReturnActivity {
  readonly comment?: string;
  readonly type: CkCustomerReturnActivityType;
  readonly createdAt: Date;
  readonly user: SimpleUser;
}

export interface CkCustomerReturnMetadata {
  readonly isLocked?: boolean;
  readonly isSynced?: boolean;
}

export interface CkCustomerReturnTotals {
  readonly amount?: number;
  readonly count?: number;
  readonly grandTotal?: number;
  readonly subTotal?: number;
  readonly taxAmount?: number;
  readonly total?: number;
}

export interface CkCustomerReturnResponse {
  readonly activities: CkCustomerReturnActivity[];
  readonly comment?: string;
  readonly createdAt: Date;
  readonly createdBy: SimpleUser;
  readonly customer: SkeletonObjectType;
  readonly document: CkCustomerReturnDocumentResponse;
  readonly id: string;
  readonly items: CkCustomerReturnItemResponse[];
  readonly location: SkeletonObjectType;
  readonly metadata: CkCustomerReturnMetadata;
  readonly outlet: LocalizedSkeletonObjectType;
  readonly retailer: SkeletonObjectType;
  readonly status: CkCustomerReturnStatus;
  readonly supplier: SupplierSnapshot;
  readonly totals: CkCustomerReturnTotals;
}

export type CkCustomerReturnPostManyResponse = {
  readonly synced: string[];
  readonly unsynced: string[];
} | void;

export interface CkCustomerReturnPostManyRequest {
  readonly supplierReturns: IdType[];
}

export interface CkCustomerReturnLockManyRequest {
  readonly supplierReturns: IdType[];
}

export interface CkCustomerReturnApproveManyRequest {
  readonly supplierReturns: IdType[];
}

export interface CkCustomerReturnDiscardManyRequest {
  readonly supplierReturns: IdType[];
}

export interface CkCustomerReturnBaseRequest {
  readonly comment?: string;
  readonly document: CkCustomerReturnDocumentRequest;
  readonly notifySupplier?: boolean;
  readonly pushToStock?: boolean;
}

export interface CkCustomerReturnCreateRequest extends CkCustomerReturnBaseRequest {
  readonly creationSource: 'central-kitchen';
  readonly customer: IdType;
  readonly supplier: IdType;
  readonly items: CkCustomerReturnItemCreateRequest[];
}

export interface CkCustomerReturnUpdateRequest extends Partial<CkCustomerReturnBaseRequest> {
  readonly items: CkCustomerReturnItemUpdateRequest[];
}

export interface CkCustomerReturnGenerateDocumentNumberResponse {
  readonly number: string;
}

export interface CkCustomerReturnGenerateDocumentNumberRequest {
  readonly retailer: IdType;
}

export interface CkCustomerReturnDownloadPdfResponse {
  readonly signedUrl: string;
}

export interface CkCustomerReturnUploadResponse {
  readonly path: string;
}

export interface CkCustomerReturnQueryProps {
  readonly 'customer.id': string;
  readonly 'document.documentDate': number;
  readonly 'document.number': string;
  readonly 'retailer.id': string;
  readonly 'supplier.id': string;
  readonly 'supplier.metadata.retailerId': string;
  readonly id: string;
  readonly status: string;
}
