import {
  assertProcessEnv,
  CommonConfig,
  CsrfConfig,
  GoogleSheetConfig,
  IntegrationsConfig,
  IntercomConfig,
  SentryConfig,
  UnleashConfig,
} from '@supy/common';
import { CaptchaConfig, MaintenanceConfig } from '@supy/components';
import { OpenTelemetryConfig } from '@supy/opentelemetry';
import { WebSocketApp, WebSocketConfig } from '@supy/websockets';

assertProcessEnv();

export const environment: CommonConfig &
  GoogleSheetConfig &
  IntercomConfig &
  SentryConfig &
  IntegrationsConfig &
  UnleashConfig &
  OpenTelemetryConfig &
  WebSocketConfig &
  MaintenanceConfig &
  CaptchaConfig &
  CsrfConfig = {
  foodics: {
    apiUrl: process.env.FOODICS_API_URL,
    clientId: process.env.FOODICS_CLIENT_ID,
  },
  lightSpeed: {
    apiUrl: process.env.LIGHTSPEED_API_URL,
    clientId: process.env.LIGHTSPEED_CLIENT_ID,
    redirectUri: process.env.LIGHTSPEED_REDIRECT_URL,
  },
  lightSpeedX: {
    apiUrl: process.env.LIGHTSPEED_X_API_URL,
    redirectUri: process.env.LIGHTSPEED_X_REDIRECT_URL,
    clientId: process.env.LIGHTSPEED_X_CLIENT_ID,
  },
  lightSpeedO: {
    apiUrl: process.env.LIGHTSPEED_O_API_URL,
    clientId: process.env.LIGHTSPEED_O_CLIENT_ID,
    redirectUri: process.env.LIGHTSPEED_O_REDIRECT_URL,
  },
  square: {
    apiUrl: process.env.SQUARE_API_URL,
    clientId: process.env.SQUARE_CLIENT_ID,
    redirectUri: process.env.SQUARE_REDIRECT_URL,
  },
  loyverse: {
    apiUrl: process.env.LOYVERSE_API_URL,
    clientId: process.env.LOYVERSE_CLIENT_ID,
    redirectUri: process.env.LOYVERSE_REDIRECT_URL,
  },
  xero: {
    apiUrl: process.env.XERO_AUTH_URL,
    clientId: process.env.XERO_CLIENT_ID,
    redirectUri: process.env.XERO_REDIRECT_URL,
  },
  oracleNetsuite: {
    apiUrl: process.env.ORACLE_NETSUITE_AUTH_URL,
    clientId: process.env.ORACLE_NETSUITE_CLIENT_ID,
    redirectUri: process.env.ORACLE_NETSUITE_REDIRECT_URL,
  },
  zoho: {
    apiUrl: process.env.ZOHO_AUTH_URL,
    clientId: process.env.ZOHO_CLIENT_ID,
    redirectUri: process.env.ZOHO_REDIRECT_URL,
  },
  quickbooks: {
    apiUrl: process.env.QUICKBOOKS_AUTH_URL,
    clientId: process.env.QUICKBOOKS_CLIENT_ID,
    redirectUri: process.env.QUICKBOOKS_REDIRECT_URL,
  },
  myob: {
    apiUrl: process.env.MYOB_AUTH_URL,
    clientId: process.env.MYOB_CLIENT_ID,
    redirectUri: process.env.MYOB_REDIRECT_URL,
  },
  poster: {
    apiUrl: process.env.POSTER_AUTH_URL,
    appId: process.env.POSTER_APP_ID,
    redirectUri: process.env.POSTER_REDIRECT_URL,
  },
  zid: {
    apiUrl: process.env.ZID_AUTH_URL,
    clientId: process.env.ZID_CLIENT_ID,
    redirectUri: process.env.ZID_REDIRECT_URL,
  },
  wafeq: {
    apiUrl: process.env.WAFEQ_AUTH_URL,
    clientId: process.env.WAFEQ_CLIENT_ID,
    redirectUri: process.env.WAFEQ_REDIRECT_URL,
  },
  shopify: {
    clientId: process.env.SHOPIFY_CLIENT_ID,
    redirectUri: process.env.SHOPIFY_REDIRECT_URL,
  },
  intercom: {
    appId: process.env.INTERCOM_APP_ID,
  },
  production: JSON.parse(process.env.PRODUCTION) as boolean,
  apiUrlPrefix: process.env.API_URL_PREFIX,
  apiUrl: process.env.API_URL_CORE,
  apiUrlBff: process.env.API_URL_BFF,
  google: {
    sheets: {
      apiKey: process.env.GOOGLE_SHEETS_API_KEY,
      clientId: process.env.GOOGLE_SHEETS_CLIENT_ID,
      discoveryDocs: process.env.GOOGLE_SHEETS_DISCOVERY_DOCS.split(','),
      scopes: process.env.GOOGLE_SHEETS_SCOPES,
    },
  },
  sentry: {
    dsn: process.env.SENTRY_DSN,
    sampleRate: +process.env.SENTRY_SAMPLE_RATE,
    tracesSampleRate: +process.env.SENTRY_SAMPLE_RATE,
    environment: process.env.SENTRY_ENVIRONMENT,
    enabled: JSON.parse(process.env.SENTRY_ENABLED) as boolean,
  },
  opentelemetry: {
    enabled: JSON.parse(process.env.OPENTELEMETRY_ENABLED) as boolean,
    application: {
      name: process.env.OPENTELEMETRY_APPLICATION_NAME,
      version: process.env.OPENTELEMETRY_APPLICATION_VERSION,
    },
    tracing: {
      enabled: JSON.parse(process.env.OPENTELEMETRY_TRACING_ENABLED) as boolean,
      propagate: process.env.OPENTELEMETRY_TRACING_PROPAGATE.split(','),
      whitelist: process.env.OPENTELEMETRY_TRACING_WHITELIST.split(','),
      url: process.env.OPENTELEMETRY_TRACING_URL,
    },
    metrics: {
      enabled: JSON.parse(process.env.OPENTELEMETRY_METRICS_ENABLED) as boolean,
      url: process.env.OPENTELEMETRY_METRICS_URL,
    },
  },
  unleash: {
    appName: process.env.UNLEASH_APP_NAME,
    clientKey: process.env.UNLEASH_PROXY_CLIENT_KEY,
    proxyUrl: process.env.UNLEASH_PROXY_URL,
  },
  websockets: {
    clients: {
      [WebSocketApp.Default]: {
        appKey: process.env.WEBSOCKET_DEFAULT_APP_KEY,
        host: process.env.WEBSOCKET_CLUSTER_URL,
        auth: {
          user: {
            endpoint: process.env.WEBSOCKET_DEFAULT_APP_AUTH_USER_ENDPOINT,
          },
          channel: {
            endpoint: process.env.WEBSOCKET_DEFAULT_APP_AUTH_CHANNEL_ENDPOINT,
          },
        },
      },
    },
  },
  maintenance: {
    enabled: process.env.MAINTENANCE_ENABLED === true.toString(),
    configUrl: process.env.MAINTENANCE_URL,
  },
  versionHash: process.env.VERSION_HASH ?? 'local', // version hash is injected inside of Dockerfile to sync git commit hash
  captcha: {
    siteKey: process.env.CLOUDFLARE_TURNSTILE_SITE_KEY,
  },
  csrf: {
    disabled: process.env.CSRF_DISABLED === true.toString(),
    cookieName: process.env.CSRF_COOKIE_NAME,
    headerName: process.env.CSRF_HEADER_NAME,
  },
};

Object.freeze(environment);
