import { IANATimezone } from '@supy.api/dictionaries';

import { getApiDetailsDecorator, IdType, removeEmpty } from '@supy/common';
import { NonFunctionProperties } from '@supy/core';

import {
  CkCustomerReturnBaseRequest,
  CkCustomerReturnCreateRequest,
  CkCustomerReturnUpdateRequest,
} from './ck-customer-return.model';
import { CkCustomerReturnDocument } from './ck-customer-return-document.entity';
import { CkCustomerReturnDocumentPayload } from './ck-customer-return-document.payload';
import { CkCustomerReturnItem } from './ck-customer-return-item.entity';
import {
  CkCustomerReturnItemCreatePayload,
  CkCustomerReturnItemUpdatePayload,
} from './ck-customer-return-item.payload';

const ApiProperty = getApiDetailsDecorator<CkCustomerReturnCreateRequest | CkCustomerReturnUpdateRequest>();

export abstract class CkCustomerReturnBasePayload {
  protected constructor(args: NonFunctionProperties<CkCustomerReturnBasePayload>) {
    this.comment = args.comment;
    this.document = args.document;
    this.notifySupplier = args.notifySupplier;
    this.pushToStock = args.pushToStock;
    this.ianaTimeZone = args.ianaTimeZone;
    this.utcOffset = args.utcOffset;
  }

  @ApiProperty() readonly comment?: string;
  @ApiProperty() document: CkCustomerReturnDocument;
  @ApiProperty() readonly notifySupplier?: boolean;
  @ApiProperty() readonly pushToStock?: boolean;

  readonly ianaTimeZone: IANATimezone;
  readonly utcOffset: number;

  protected serializeBase(): CkCustomerReturnBaseRequest {
    return {
      document: new CkCustomerReturnDocumentPayload({
        ...this.document,
        ianaTimeZone: this.ianaTimeZone,
        utcOffset: this.utcOffset,
      }).serialize(),
      comment: this.comment,
      notifySupplier: this.notifySupplier ?? false,
      pushToStock: this.pushToStock ?? false,
    };
  }

  appendFiles(files: string[]): void {
    this.document = {
      ...this.document,
      attachments: this.document.attachments.concat(files.map(file => ({ path: file, signedUrl: file }))),
    };
  }
}

export class CkCustomerReturnCreatePayload extends CkCustomerReturnBasePayload {
  constructor(args: NonFunctionProperties<Omit<CkCustomerReturnCreatePayload, 'creationSource'>>) {
    super(args);
    this.creationSource = 'central-kitchen';
    this.customer = args.customer;
    this.supplier = args.supplier;
    this.items = args.items;
  }

  @ApiProperty() readonly creationSource: 'central-kitchen';
  @ApiProperty() readonly customer: IdType;
  @ApiProperty() readonly supplier: IdType;
  @ApiProperty() readonly items: CkCustomerReturnItem[];

  serialize(): CkCustomerReturnCreateRequest {
    return removeEmpty({
      ...this.serializeBase(),
      creationSource: this.creationSource,
      customer: { id: this.customer.id },
      items: this.items.map(item =>
        new CkCustomerReturnItemCreatePayload({
          ...item,
          ckItem: { id: item.ckItemId },
        }).serialize(),
      ),
      supplier: { id: this.supplier.id },
    });
  }
}

export class CkCustomerReturnUpdatePayload extends CkCustomerReturnBasePayload {
  constructor(args: NonFunctionProperties<CkCustomerReturnUpdatePayload>) {
    super(args);
    this.items = args.items;
  }

  @ApiProperty() readonly items: CkCustomerReturnItem[];

  serialize(): CkCustomerReturnUpdateRequest {
    return {
      ...this.serializeBase(),
      items: this.items.map(item => new CkCustomerReturnItemUpdatePayload(item).serialize()),
    };
  }
}
