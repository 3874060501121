import { ChangeDetectionStrategy, Component, Input, input, output } from '@angular/core';

import { Order, OrderStatus } from '../../../core';

@Component({
  selector: 'supy-retailer-order-details-header',
  templateUrl: './retailer-order-details-header.component.html',
  styleUrls: ['./retailer-order-details-header.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class RetailerOrderDetailsHeaderComponent<
  T extends {
    readonly number: string;
    readonly status: OrderStatus;
  },
> {
  @Input() protected readonly isInventoryOrder: boolean;
  @Input() protected readonly isInitialLoading: boolean;
  @Input() protected readonly isCkOrder: boolean;
  @Input() protected readonly nextDisabled: boolean;
  @Input() protected readonly order: T;
  @Input() protected readonly orders: Order[] = [];
  @Input() protected readonly previousDisabled: boolean;
  @Input() protected readonly isRepeatOrderShown: boolean;
  protected readonly hidePrices = input<boolean>();

  readonly nextClicked = output<T>();
  readonly previousClicked = output<T>();

  readonly downloadPdfClicked = output<void>();
  readonly repeatOrderClicked = output<void>();
}
