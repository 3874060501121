import produce from 'immer';
import { of, tap } from 'rxjs';
import { inject, Injectable } from '@angular/core';
import { toSignal } from '@angular/core/rxjs-interop';
import { Router } from '@angular/router';
import { Action, createSelector, Selector, State, StateContext, StateToken, Store } from '@ngxs/store';

import {
  BaseResponseMetadata,
  EntityListState,
  EntityListStateModel,
  getShiftedDate,
  Query,
  QueryBuilder,
} from '@supy/common';
import { SettingsState } from '@supy/settings';
import { CurrentUserState } from '@supy/users';

import { CkOrderItem, CkOrderItemsRequestMetadata, CkOrderItemsRequestProps } from '../../core';
import { downloadCkOrderItemsList } from '../../helpers';
import { CkOrdersService } from '../../services';
import {
  CkOrderItemsExportXLSX,
  CkOrderItemsGet,
  CkOrderItemsGetMany,
  CkOrderItemsInitFilters,
  CkOrderItemsPatchFilters,
  CkOrderItemsPatchRequestMetadata,
  CkOrderItemsReset,
  CkOrderItemsResetFilters,
} from '../actions';

export interface CkOrderItemsFilters {
  readonly endDeliveryDate: number;
  readonly search: string;
  readonly startDeliveryDate: number;
  readonly status: string;
  readonly supplierId: string;
  readonly type: string;
  readonly itemCategoryIds: string[];
}

export interface CkOrderItemsStateModel extends EntityListStateModel<CkOrderItem> {
  readonly filters: CkOrderItemsFilters;
  readonly requestMetadata: CkOrderItemsRequestMetadata;
  readonly responseMetadata: BaseResponseMetadata;
}

const DEFAULT_FILTERS: CkOrderItemsFilters = {
  endDeliveryDate: null,
  search: null,
  type: null,
  startDeliveryDate: null,
  status: null,
  supplierId: null,
  itemCategoryIds: [],
};

const DEFAULT_REQUEST_METADATA: CkOrderItemsRequestMetadata = {
  retailerId: null,
  page: 0,
  limit: 25,
};

const DEFAULT_RESPONSE_METADATA: BaseResponseMetadata = {
  count: 0,
  total: 0,
};

const CK_ORDER_ITEMS_STATE_TOKEN = new StateToken<CkOrderItemsStateModel>('ckOrderItems');

@State<CkOrderItemsStateModel>({
  name: CK_ORDER_ITEMS_STATE_TOKEN,
  defaults: {
    ...EntityListState.default(),
    filters: DEFAULT_FILTERS,
    requestMetadata: DEFAULT_REQUEST_METADATA,
    responseMetadata: DEFAULT_RESPONSE_METADATA,
  },
})
@Injectable()
export class CkOrderItemsState extends EntityListState {
  readonly #ordersService = inject(CkOrdersService);
  readonly #store = inject(Store);
  readonly #hidePrices = toSignal(this.#store.select(CurrentUserState.hideApproxPrice));
  readonly #utcOffset = this.#store.selectSignal(SettingsState.utcOffset);

  constructor(protected readonly router: Router) {
    super(router);
  }

  @Selector()
  static ckOrderItems(state: CkOrderItemsStateModel) {
    return EntityListState.all(state);
  }

  @Selector()
  static currentCkOrderItem(state: CkOrderItemsStateModel) {
    return EntityListState.current(state);
  }

  @Selector()
  static isFirst(state: CkOrderItemsStateModel) {
    return EntityListState.isFirst(state);
  }

  @Selector()
  static isLast(state: CkOrderItemsStateModel) {
    return EntityListState.isLast(state);
  }

  static ckOrderItem(id: string, next?: boolean) {
    return createSelector([CkOrderItemsState], (state: CkOrderItemsStateModel) => {
      return EntityListState.one(id, next)(state);
    });
  }

  @Selector()
  static filters(state: CkOrderItemsStateModel) {
    return EntityListState.currentFilters<CkOrderItemsFilters>(state);
  }

  @Selector()
  static requestMetadata(state: CkOrderItemsStateModel) {
    return state.requestMetadata;
  }

  @Selector()
  static responseMetadata(state: CkOrderItemsStateModel) {
    return state.responseMetadata;
  }

  @Action(CkOrderItemsInitFilters)
  initFilters(ctx: StateContext<CkOrderItemsStateModel>) {
    super.initFilter(ctx, DEFAULT_FILTERS);
  }

  @Action(CkOrderItemsPatchFilters)
  patchFilters(ctx: StateContext<CkOrderItemsStateModel>, { payload }: CkOrderItemsPatchFilters) {
    super.patchFilter(ctx, payload, DEFAULT_FILTERS);
    ctx.dispatch([new CkOrderItemsPatchRequestMetadata({ page: 0 }), new CkOrderItemsGetMany()]);
  }

  @Action(CkOrderItemsResetFilters)
  resetFilters(ctx: StateContext<CkOrderItemsStateModel>) {
    const { supplierId, status } = ctx.getState().filters;

    super.resetFilter(ctx, {
      ...DEFAULT_FILTERS,
      supplierId,
      status,
    });

    ctx.dispatch([new CkOrderItemsPatchRequestMetadata({ page: 0 }), new CkOrderItemsGetMany()]);
  }

  @Action(CkOrderItemsPatchRequestMetadata)
  patchRequestMetadata(ctx: StateContext<CkOrderItemsStateModel>, { payload }: CkOrderItemsPatchRequestMetadata) {
    ctx.setState(
      produce(draft => {
        draft.requestMetadata = {
          ...ctx.getState().requestMetadata,
          ...payload,
        };
      }),
    );
  }

  @Action(CkOrderItemsGet)
  getOrder(ctx: StateContext<CkOrderItemsStateModel>, { payload }: CkOrderItemsGet) {
    return super.getOne(ctx, {
      ...payload,
      fetchApi: () => of(null),
    });
  }

  @Action(CkOrderItemsGetMany, { cancelUncompleted: true })
  getOrderItems(ctx: StateContext<CkOrderItemsStateModel>) {
    return this.#ordersService.getItems(this.getQuery(ctx.getState())).pipe(
      tap(({ data, metadata }) => {
        ctx.patchState({ responseMetadata: metadata });
        super.setMany(ctx, CkOrderItem.deserializeList(data));
      }),
    );
  }

  @Action(CkOrderItemsReset)
  reset(ctx: StateContext<CkOrderItemsStateModel>) {
    return super.setMany(ctx, []);
  }

  @Action(CkOrderItemsExportXLSX)
  exportXLSX(ctx: StateContext<CkOrderItemsStateModel>) {
    const state = ctx.getState();
    const items = CkOrderItemsState.ckOrderItems(state);

    void downloadCkOrderItemsList(items, undefined, this.#hidePrices());
  }

  private getQuery({ filters, requestMetadata }: CkOrderItemsStateModel): Query<CkOrderItemsRequestProps> {
    const { status, search, supplierId, startDeliveryDate, endDeliveryDate, type, itemCategoryIds } = filters;

    const qb = new QueryBuilder<CkOrderItemsRequestProps>({
      filtering: [],
      paging: { offset: requestMetadata.page * requestMetadata.limit, limit: requestMetadata.limit },
      ordering: [
        {
          by: 'deliveryDate',
          dir: 'desc',
        },
      ],
    });

    if (requestMetadata.retailerId) {
      qb.filtering.setFilter({
        by: 'retailer.id',
        match: requestMetadata.retailerId,
        op: 'eq',
      });
    }

    if (requestMetadata.supplierId) {
      qb.filtering.setFilter({
        by: 'supplier.id',
        match: requestMetadata.supplierId,
        op: 'eq',
      });
    } else if (supplierId) {
      qb.filtering.setFilter({
        by: 'supplier.id',
        match: supplierId,
        op: 'eq',
      });
    }

    if (supplierId) {
      qb.filtering.setFilter({
        by: 'supplier.id',
        match: supplierId,
        op: 'eq',
      });
    }

    if (requestMetadata.orderId) {
      qb.filtering.setFilter({
        by: 'orderId',
        match: requestMetadata.orderId,
        op: 'eq',
      });
    }

    if (search) {
      qb.filtering.withGroup('or', [
        {
          by: 'name.en',
          match: search,
          op: 'like',
        },
        {
          by: 'name.ar',
          match: search,
          op: 'like',
        },
        {
          by: 'retailerItemCode',
          match: search,
          op: 'like',
        },
      ]);
    }

    if (status) {
      qb.filtering.setFilter({
        by: 'status',
        match: status,
        op: 'eq',
      });
    }

    if (type) {
      qb.filtering.setFilter({
        by: 'type',
        match: type,
        op: 'eq',
      });
    }

    if (itemCategoryIds?.length) {
      qb.filtering.setFilter({
        by: 'category.id',
        match: decodeURIComponent(itemCategoryIds as unknown as string).split(','),
        op: 'in',
      });
    }

    if (startDeliveryDate && endDeliveryDate) {
      const utcOffset = this.#utcOffset();

      qb.filtering.withFiltering([
        { by: 'deliveryDate', op: 'lte', match: getShiftedDate(endDeliveryDate, utcOffset).getTime() },
        { by: 'deliveryDate', op: 'gte', match: getShiftedDate(startDeliveryDate, utcOffset).getTime() },
      ]);
    }

    return qb.build();
  }
}
