import { Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { inject, Injectable } from '@angular/core';

import { IQueryResponse, Query } from '@supy/common';

import { CK_PRICE_LISTS_BFF_URI } from '../config';
import {
  CkCatalogItemResponse,
  CkCatalogItemsQueryProps,
  CkCatalogPackagingResponse,
  CkDeleteManyRequest,
  CkPriceListCreatePayload,
  CkPriceListItemResponse,
  CkPriceListItemsImportPayload,
  CkPriceListItemsPayload,
  CkPriceListQueryProps,
  CkPriceListResponse,
  CkPriceListUpdatePayload,
} from '../core';

@Injectable()
export class CkPriceListsService {
  readonly #httpClient = inject(HttpClient);
  readonly #uriBff = inject(CK_PRICE_LISTS_BFF_URI);

  get(id: string): Observable<CkPriceListResponse> {
    return this.#httpClient.get<CkPriceListResponse>(`${this.#uriBff}/${id}`);
  }

  getMany(query: Query<CkPriceListQueryProps>): Observable<IQueryResponse<CkPriceListResponse>> {
    return this.#httpClient.get<IQueryResponse<CkPriceListResponse>>(`${this.#uriBff}`, {
      params: query.toQueryParams(),
    });
  }

  create(payload: CkPriceListCreatePayload): Observable<CkPriceListResponse> {
    return this.#httpClient.post<CkPriceListResponse>(`${this.#uriBff}`, payload.serialize());
  }

  update(id: string, payload: CkPriceListUpdatePayload): Observable<void> {
    return this.#httpClient.patch<void>(`${this.#uriBff}/${id}`, payload.serialize());
  }

  deleteMany(payload: CkDeleteManyRequest): Observable<void> {
    return this.#httpClient.patch<void>(`${this.#uriBff}`, payload);
  }

  getItems(id: string, query: Query<CkCatalogItemsQueryProps>): Observable<IQueryResponse<CkPriceListItemResponse>> {
    return this.#httpClient.get<IQueryResponse<CkPriceListItemResponse>>(`${this.#uriBff}/${id}/ck-items`, {
      params: query.toQueryParams(),
    });
  }

  updateItems(id: string, payload: CkPriceListItemsPayload): Observable<void> {
    return this.#httpClient.patch<void>(`${this.#uriBff}/${id}/ck-items/upsert`, payload.serialize());
  }

  deleteManyItems(id: string, payload: CkDeleteManyRequest): Observable<void> {
    return this.#httpClient.patch<void>(`${this.#uriBff}/${id}/ck-items`, payload);
  }

  exportAllCatalogItems(
    id: string,
    locationId: string,
  ): Observable<(CkCatalogItemResponse | CkCatalogPackagingResponse)[]> {
    return this.#httpClient.get<(CkCatalogItemResponse | CkCatalogPackagingResponse)[]>(
      `${this.#uriBff}/${id}/export-all-catalog/${locationId}`,
    );
  }

  importItems(payload: CkPriceListItemsImportPayload): Observable<void> {
    return this.#httpClient.post<void>(`${this.#uriBff}/import-items`, payload.serialize());
  }
}
