import { NonFunctionProperties } from '@supy/core';

import { CkCustomerContactRequest } from './ck-customer.model';
import { CkCustomerContactBase } from './ck-customer-contact.entity';

export class CkCustomerContactPayload extends CkCustomerContactBase {
  constructor(args: NonFunctionProperties<CkCustomerContactPayload>) {
    super(args);
  }

  serialize(): CkCustomerContactRequest {
    return {
      emails: this.emails,
      name: this.name,
      phones: this.phones,
    };
  }
}
