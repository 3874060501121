import { removeEmpty, Supplier } from '@supy/common';
import { NonFunctionProperties } from '@supy/core';
import { DiscountType, OrderStatus } from '@supy/orders';

import { CkCustomer } from '../customer';
import { CkOrderB2bRequest, CreateCkOrderB2bRequest, UpdateCkOrderB2bRequest } from './ck-order-b2b.model';
import { CkOrderItemB2b } from './ck-order-item-b2b.entity';
import { CkOrderItemB2bPayload } from './ck-order-item-b2b.payload';

export class CkOrderB2bPayload {
  constructor(args: NonFunctionProperties<CkOrderB2bPayload>) {
    this.invoiceDueDate = args.invoiceDueDate;
    this.externalDocNumber = args.externalDocNumber;
    this.invoiceDiscountAmount = args.invoiceDiscountAmount;
    this.invoiceDiscountType = args.invoiceDiscountType;
    this.deliveryDate = args.deliveryDate;
    this.otherFees = args.otherFees;
    this.status = args.status;
    this.comments = args.comments;
    this.updateStock = args.updateStock;
  }

  readonly invoiceDueDate: Date;
  readonly externalDocNumber: string;
  readonly invoiceDiscountAmount: number;
  readonly invoiceDiscountType: DiscountType;
  readonly deliveryDate: Date;
  readonly otherFees: number;
  readonly status: OrderStatus;
  readonly comments: string[];
  readonly updateStock?: boolean;

  serialize(): CkOrderB2bRequest {
    return removeEmpty<CkOrderB2bRequest>({
      invoiceDueDate: this.invoiceDueDate,
      deliveryDate: this.deliveryDate,
      status: this.status,
      discount:
        this.invoiceDiscountType || this.invoiceDiscountAmount
          ? {
              type: this.invoiceDiscountType ?? DiscountType.Value,
              amount: this.invoiceDiscountAmount ?? 0,
            }
          : null,
      externalDocNumber: this.externalDocNumber,
      fees: this.otherFees,
      comments: this.comments,
      updateStock: this.updateStock,
    });
  }
}

export class CreateCkOrderB2bPayload extends CkOrderB2bPayload {
  constructor(args: NonFunctionProperties<CreateCkOrderB2bPayload>) {
    super(args);

    this.centralKitchen = args.centralKitchen;
    this.customer = args.customer;
    this.items = args.items;
  }

  readonly centralKitchen: Supplier;
  readonly customer: CkCustomer;
  readonly items: CkOrderItemB2b[];

  serialize(): CreateCkOrderB2bRequest {
    return removeEmpty<CreateCkOrderB2bRequest>({
      ...super.serialize(),
      centralKitchenId: this.centralKitchen.id,
      customerId: this.customer.id,
      items: this.items.map(item => new CkOrderItemB2bPayload(item).serializeCreate()),
    });
  }
}

export class UpdateCkOrderB2bPayload extends CkOrderB2bPayload {
  constructor(args: NonFunctionProperties<UpdateCkOrderB2bPayload>) {
    super(args);

    this.itemsToCreate = args.itemsToCreate;
    this.itemsToUpdate = args.itemsToUpdate;
  }

  readonly itemsToCreate?: CkOrderItemB2b[];
  readonly itemsToUpdate?: CkOrderItemB2b[];

  serialize(): UpdateCkOrderB2bRequest {
    return removeEmpty<UpdateCkOrderB2bRequest>({
      ...super.serialize(),
      items: {
        created: this.itemsToCreate.map(item => new CkOrderItemB2bPayload(item).serializeCreate()),
        modified: this.itemsToUpdate.map(item => new CkOrderItemB2bPayload(item).serializeUpdate()),
      },
    });
  }
}

export class ShipCkOrdersB2bPayload extends UpdateCkOrderB2bPayload {
  constructor(args: NonFunctionProperties<ShipCkOrdersB2bPayload>) {
    super(args);
    this.updateStock = args.updateStock;
  }

  readonly updateStock?: boolean;

  serialize(): UpdateCkOrderB2bRequest {
    return {
      ...super.serialize(),
      updateStock: this.updateStock,
    };
  }
}
