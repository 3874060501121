import { capital } from 'case';
import { Currency } from '@supy.api/dictionaries';

import { createWorksheet, DataColumn, saveWorkbook } from '@supy/common';

import { CkCustomerReturn } from '../core';

export async function downloadCkCustomerReturnsList(
  items: CkCustomerReturn[],
  currency: Currency,
  hidePrices?: boolean,
): Promise<void> {
  const currencyCols = new Set(['total']);
  const dateCols = new Set(['returnDate']);
  const { Workbook } = (await import('exceljs')).default;
  const workbook = new Workbook();
  const data = items.map(item => ({
    ...item,
    documentNumber: item.document.number,
    supplierName: item.supplier.name,
    returnDate: item.document.returnDate,
    count: item.items?.length ?? 0,
    total: item.totals.total,
    status: capital(item.status),
  }));
  const columns: DataColumn[] = [
    { header: 'Document #', key: 'documentNumber' },
    { header: 'Customer', key: 'customerName' },
    { header: 'Central Kitchen', key: 'supplierName' },
    { header: `# of Items`, key: 'count' },
    { header: 'Return Date', key: 'returnDate' },
  ];

  if (!hidePrices) {
    columns.push({ header: `Grand Total (${currency})`, key: 'total' });
  }

  columns.push({ header: 'Status', key: 'status' });

  await createWorksheet(workbook, 'Customer Returns', { data, columns, currencyCols, dateCols });
  saveWorkbook(workbook, `customer-returns-${new Date().toISOString()}.xlsx`);
}
