import { IANATimezone } from '@supy.api/dictionaries';

import {
  createWorksheet,
  DataColumn,
  DEFAULT_IANA_TIMEZONE,
  getUTCOverriddenDateTime,
  saveWorkbook,
} from '@supy/common';

import { CkCustomer } from '../core';

export async function downloadCkCustomersList(
  items: CkCustomer[],
  options: { currency: string; hidePrices?: boolean },
  ianaTimeZone: IANATimezone = DEFAULT_IANA_TIMEZONE,
): Promise<void> {
  const columns: DataColumn[] = [
    { header: 'Customer Code', key: 'code' },
    { header: 'Customer Name', key: 'displayName' },
    { header: 'Customer Group', key: 'customerGroup' },
    { header: 'Price List', key: 'priceList' },
    { header: 'Last Order Date', key: 'lastOrderDate' },
  ];

  if (!options.hidePrices) {
    columns.push({ header: `Last Order Value (${options.currency})`, key: 'lastOrderValue' });
  }

  const { Workbook } = (await import('exceljs')).default;
  const workbook = new Workbook();

  const data = items.map(item => {
    return {
      code: item.code,
      displayName: item.displayName,
      customerGroup: item.customerGroup?.name,
      priceList: item.priceList?.name,
      lastOrderDate: item.lastOrderDate && getUTCOverriddenDateTime(new Date(item.lastOrderDate), ianaTimeZone),
      lastOrderValue: item.lastOrderValue,
    };
  });

  await createWorksheet(workbook, `Ck Customers`, { data, columns });

  const fileName = `ck-customers-${Date.now()}.xlsx`;

  saveWorkbook(workbook, fileName);
}
