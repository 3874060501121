import { getApiDetailsDecorator } from '@supy/common';
import { NonFunctionProperties } from '@supy/core';

import { CkCustomerGroupResponse } from './ck-customer-group.model';

const ApiProperty = getApiDetailsDecorator<CkCustomerGroupResponse>();

export abstract class CkCustomerGroupBase {
  protected constructor(args: NonFunctionProperties<CkCustomerGroupBase>) {
    this.name = args.name;
    this.defaultTaxCode = args.defaultTaxCode;
  }

  @ApiProperty() readonly name: string;
  @ApiProperty() readonly defaultTaxCode?: string;
}

export class CkCustomerGroup extends CkCustomerGroupBase {
  private constructor(args: NonFunctionProperties<CkCustomerGroup>) {
    super(args);
    this.id = args.id;
  }

  @ApiProperty() readonly id: string;

  static deserialize(data: CkCustomerGroupResponse): CkCustomerGroup {
    return new CkCustomerGroup({
      id: data.id,
      name: data.name,
      defaultTaxCode: data.defaultTaxCode,
    });
  }

  static deserializeList(data: CkCustomerGroupResponse[]): CkCustomerGroup[] {
    return data.map(customerGroup => this.deserialize(customerGroup));
  }
}
