import { capital } from 'case';
import { Currency } from '@supy.api/dictionaries';

import { createWorksheet, DataColumn, saveWorkbook } from '@supy/common';
import { getLocalizedName } from '@supy/settings';

import { SupplierReturn } from '../core';

export async function downloadSupplierReturnsList(
  items: SupplierReturn[],
  currency: Currency,
  hidePrices?: boolean,
): Promise<void> {
  const currencyCols = new Set(['total']);
  const dateCols = new Set(['returnDate']);
  const { Workbook } = (await import('exceljs')).default;
  const workbook = new Workbook();
  const data = items.map(item => ({
    ...item,
    documentNumber: item.document.number,
    supplierName: item.supplier.name,
    returnDate: item.document.returnDate,
    locationName: `${getLocalizedName(item.outlet?.name)} - ${item.location.name}`,
    count: item.items?.length ?? 0,
    total: item.totals.total,
    status: capital(item.status),
  }));
  const columns: DataColumn[] = [
    { header: 'Document #', key: 'documentNumber' },
    { header: 'Branch & Location', key: 'locationName' },
    { header: 'Supplier', key: 'supplierName' },
    { header: `# of Items`, key: 'count' },
    { header: 'Return Date', key: 'returnDate' },
  ];

  if (!hidePrices) {
    columns.push({ header: `Grand Total (${currency})`, key: 'total' });
  }

  columns.push({ header: 'Status', key: 'status' });

  await createWorksheet(workbook, 'Supplier Returns', { data, columns, currencyCols, dateCols });
  saveWorkbook(workbook, `supplier-returns-${new Date().toISOString()}.xlsx`);
}
