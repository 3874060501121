import { registerLocaleData } from '@angular/common';
import { APP_INITIALIZER, Injectable, LOCALE_ID } from '@angular/core';
import { loadTranslations, MessageId, TargetMessage } from '@angular/localize';

export type Translations = Record<MessageId, TargetMessage>;

export type WritingDirection = 'ltr' | 'rtl';
export type Locale = 'ar' | 'es' | 'en';

export const LocaleDirection: Record<Locale, WritingDirection> = {
  ar: 'rtl',
  es: 'ltr',
  en: 'ltr',
} as const;

@Injectable({
  providedIn: 'root',
})
export class I18n {
  #locale: Locale = 'en';

  get locale() {
    return this.#locale;
  }

  async setLocale() {
    const userLocale = localStorage.getItem('supy.locale') as Locale;

    // If the user has a preferred language stored in localStorage, use it.
    if (userLocale) {
      this.#locale = userLocale;

      const htmlTag = globalThis.document.documentElement;
      const dir = LocaleDirection[userLocale];

      if (htmlTag) {
        htmlTag.dir = dir;
        htmlTag.lang = userLocale;
      }

      localStorage.setItem('dir', dir);
    }

    const [ar, es, en, translations] = await Promise.all([
      import('@angular/common/locales/ar') as unknown as { default: Translations },
      import('@angular/common/locales/es') as unknown as { default: Translations },
      import('@angular/common/locales/en') as unknown as { default: Translations },
      getTranslationsByUrl(`assets/i18n/${this.locale}.json`),
    ]);

    const localesMap = new Map<Locale, Translations>([
      ['ar', ar.default],
      ['es', es.default],
      ['en', en.default],
    ]);

    // Set locale for built in pipes, etc.
    if (userLocale) {
      registerLocaleData(localesMap.get(userLocale), userLocale);
    } else {
      registerLocaleData(en.default);
    }

    if (translations) {
      // Load translations for the current locale at run-time
      loadTranslations(translations);
    }
  }
}

// Load locale data at app start-up
function setLocale() {
  return {
    provide: APP_INITIALIZER,
    useFactory: (i18n: I18n) => () => i18n.setLocale(),
    deps: [I18n],
    multi: true,
  };
}

// Set the runtime locale for the app
function setLocaleId() {
  return {
    provide: LOCALE_ID,
    useFactory: (i18n: I18n) => i18n.locale,
    deps: [I18n],
  };
}

export const I18nModule = {
  setLocale: setLocale,
  setLocaleId: setLocaleId,
} as const;

async function getTranslationsByUrl(url: string): Promise<Translations> {
  try {
    const response = await fetch(url);

    if (!response.ok) {
      return null;
    }

    const data = await response.text();

    return parseTranslations(data);
  } catch (error) {
    return null;
  }
}

function parseTranslations(fileData: string): Translations {
  return (JSON.parse(fileData) as { translations: Translations }).translations;
}
