import { getApiDetailsDecorator } from '@supy/common';
import { NonFunctionProperties } from '@supy/core';

import { CkCustomer } from '../customer';
import { CkPriceListResponse, CkPriceListRoundPrice, CkPriceListState } from './ck-price-list.model';

const ApiProperty = getApiDetailsDecorator<CkPriceListResponse>();

export abstract class CkPriceListBase {
  protected constructor(args: NonFunctionProperties<CkPriceListBase>) {
    this.customers = args.customers;
    this.name = args.name;
    this.roundPrice = args.roundPrice;
  }

  @ApiProperty() readonly customers: CkCustomer[];
  @ApiProperty() readonly name: string;
  @ApiProperty() readonly roundPrice?: CkPriceListRoundPrice;
}

export class CkPriceList extends CkPriceListBase {
  private constructor(args: NonFunctionProperties<CkPriceList>) {
    super(args);
    this.id = args.id;
    this.numberOfCustomers = args.numberOfCustomers;
    this.numberOfItems = args.numberOfItems;
    this.state = args.state;
  }

  @ApiProperty() readonly id: string;
  @ApiProperty() readonly state: CkPriceListState;
  @ApiProperty({ key: 'customerCount' }) readonly numberOfCustomers: number;
  @ApiProperty({ key: 'itemsCount' }) readonly numberOfItems: number;

  static deserialize(data: CkPriceListResponse): CkPriceList {
    return new CkPriceList({
      customers: CkCustomer.deserializeList(data.customers ?? []),
      id: data.id,
      name: data.name,
      numberOfCustomers: data.customerCount,
      numberOfItems: data.itemsCount,
      roundPrice: data.roundPrice,
      state: data.state,
    });
  }

  static deserializeList(data: CkPriceListResponse[]): CkPriceList[] {
    return data.map(priceList => this.deserialize(priceList));
  }
}

export const CkPriceListRoundPriceMapper: Record<CkPriceListRoundPrice, string> = {
  [CkPriceListRoundPrice.None]: 'None',
  [CkPriceListRoundPrice.RoundDown]: 'Round Down',
  [CkPriceListRoundPrice.RoundUp]: 'Round Up',
};
