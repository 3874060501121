import {
  CkDeleteManyRequest,
  CkPriceList,
  CkPriceListItemsImportPayload,
  CkPriceListItemsPayload,
  CkPriceListItemsRequestMetadata,
} from '../../core';
import { CkPriceListItemsFilters } from '../state';

export class CkPriceListItemsGetMany {
  static readonly type = '[CkPriceListItems] GetMany';
}

export class CkPriceListItemsInitFilters {
  static readonly type = '[CkPriceListItems] InitFilters';
}

export class CkPriceListItemsPatchFilters {
  static readonly type = '[CkPriceListItems] PatchFilters';
  constructor(readonly payload?: Partial<CkPriceListItemsFilters>) {}
}

export class CkPriceListItemsResetFilters {
  static readonly type = '[CkPriceListItems] ResetFilters';
}

export class CkPriceListItemsPatchRequestMetadata {
  static readonly type = '[CkPriceListItems] PatchRequestMetadata';
  constructor(readonly payload: Partial<CkPriceListItemsRequestMetadata>) {}
}

export class CkPriceListItemUpdateMany {
  static readonly type = '[CkPriceListItems] UpdateMany';
  constructor(
    readonly id: string,
    readonly payload: CkPriceListItemsPayload,
  ) {}
}

export class CkPriceListItemsDeleteMany {
  static readonly type = '[CkPriceListItems] DeleteMany';
  constructor(
    readonly id: string,
    readonly payload: CkDeleteManyRequest,
  ) {}
}

export class CkPriceListItemsExport {
  static readonly type = '[CkPriceListItems] Export';
  constructor(readonly payload: { readonly priceList: CkPriceList }) {}
}

export class CkPriceListItemsExportAllCatalog {
  static readonly type = '[CkPriceListItems] ExportAllCatalog';
  constructor(readonly payload: { readonly priceList: CkPriceList; readonly withoutDownload?: boolean }) {}
}

export class CKPriceListItemsImport {
  static readonly type = '[CkPriceListItems] Import';
  constructor(readonly payload: CkPriceListItemsImportPayload) {}
}
