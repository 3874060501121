import { inject, InjectionToken } from '@angular/core';

import { CommonConfig } from '@supy/common';
import { APP_CONFIG } from '@supy/core';

export const CK_ITEMS_BFF_URI = new InjectionToken<string>('Ck Items Bff URI', {
  factory() {
    const config = inject<CommonConfig>(APP_CONFIG);

    return `${config.apiUrlBff}${config.apiUrlPrefix}/ck-items`;
  },
});

export const CK_CUSTOMERS_BFF_URI = new InjectionToken<string>('Ck Customers Bff URI', {
  factory() {
    const config = inject<CommonConfig>(APP_CONFIG);

    return `${config.apiUrlBff}${config.apiUrlPrefix}/customers`;
  },
});

export const CK_CUSTOMER_GROUPS_BFF_URI = new InjectionToken<string>('Ck Customer Groups Bff URI', {
  factory() {
    const config = inject<CommonConfig>(APP_CONFIG);

    return `${config.apiUrlBff}${config.apiUrlPrefix}/customer-groups`;
  },
});

export const CK_PRICE_LISTS_BFF_URI = new InjectionToken<string>('Ck Price Lists Bff URI', {
  factory() {
    const config = inject<CommonConfig>(APP_CONFIG);

    return `${config.apiUrlBff}${config.apiUrlPrefix}/price-lists`;
  },
});

export const CK_CUSTOMER_RETURNS_URI = new InjectionToken<string>('Ck Customer Returns Bff URI', {
  factory() {
    const config = inject<CommonConfig>(APP_CONFIG);

    return `${config.apiUrlBff}${config.apiUrlPrefix}/supplier-returns`;
  },
});
