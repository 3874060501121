import type { CellValue, Worksheet } from 'exceljs';
import { takeUntil } from 'rxjs';
import {
  ChangeDetectionStrategy,
  Component,
  computed,
  EventEmitter,
  inject,
  Input,
  input,
  Output,
  output,
  signal,
  ViewChild,
} from '@angular/core';
import { Actions, ofActionSuccessful, Store } from '@ngxs/store';
import { hasActionsExecuting } from '@ngxs-labs/actions-executing';
import { Currency } from '@supy.api/dictionaries';

import {
  Destroyable,
  getAffectedRows,
  SkeletonObjectType,
  SupplierMetadata,
  SupplierPartnershipTypeEnum,
  ViewBranch,
} from '@supy/common';
import { DialogService, FilterChange, FilterGroup, ImportFileDialogComponent, SnackbarService } from '@supy/components';
import {
  ALL_LOCATIONS_OPTION_ID,
  ImportSupplierItem,
  ImportSupplierItemsRequest,
  RetailerSupplierItem,
  RetailerSupplierItemsGetAllData,
  RetailerSupplierItemsImport,
  UpdateRetailerSupplierItemsRequest,
} from '@supy/retailers';

import { RetailerSupplierItemsGridComponent } from '../retailer-supplier-items-grid';

interface SupplierItemsImportRowDataToCheck {
  readonly price: number;
  readonly supplierItemCode: string;
  readonly supplierItemName: string;
}

const SUPPLIER_ITEM_ID_INDEX = 1;
const SUPPLIER_ITEM_NAME_INDEX = 6;
const SUPPLIER_ITEM_CODE_INDEX = 7;
const PRICE_INDEX = 8;

@Component({
  selector: 'supy-retailer-supplier-items',
  templateUrl: './retailer-supplier-items.component.html',
  styleUrls: ['./retailer-supplier-items.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [DialogService],
})
export class RetailerSupplierItemsComponent extends Destroyable {
  @ViewChild(RetailerSupplierItemsGridComponent) supplierItemsGrid: RetailerSupplierItemsGridComponent;

  @Input() readonly retailerId: string;
  @Input() readonly isLoading: boolean;
  @Input() readonly isSaving: boolean;
  @Input() readonly currencyPrecision: number;
  @Input() readonly currency: Currency;
  @Input() readonly integratedSupplierInfo?: SupplierMetadata & { readonly name: string };
  readonly hidePrices = input<boolean>();
  @Input() readonly requestMetadata: { page: number; limit: number };
  @Input() readonly responseMetadata: { total: number; count: number };
  @Input() readonly isCKSupplierV1: boolean;
  @Input() readonly isCKSupplierV2: boolean;

  @Input() set data(supplierItems: RetailerSupplierItem[]) {
    if (supplierItems) {
      this.supplierItems.set(structuredClone(supplierItems ?? []));
    }
  }

  readonly filtersGroup = input.required<FilterGroup<FilterChange>>();
  readonly retailerLocations = input<ViewBranch[]>([]);
  readonly selectedLocationId = input<string>();
  readonly onSelectedLocationChange = output<string>();
  readonly canManageChannelItemsPerLocation = input<boolean>();

  @Output() readonly updateRetailerSupplierItems = new EventEmitter<UpdateRetailerSupplierItemsRequest>();
  @Output() readonly deleteRetailerSupplierItems = new EventEmitter<{
    deleteRequest: string[];
    isDataChanged?: boolean;
    selectedItemIds: string[];
  }>();

  @Output() readonly patchFilters = new EventEmitter<FilterChange>();
  @Output() readonly resetFilters = new EventEmitter<void>();

  @Output() readonly pageChange = new EventEmitter<number>();

  @Output() readonly exportSelected = new EventEmitter<string[]>();
  @Output() readonly exportAll = new EventEmitter<boolean>();
  @Output() readonly import = new EventEmitter<ImportSupplierItemsRequest>();

  readonly #dialogService = inject(DialogService);
  readonly #snackbarService = inject(SnackbarService);
  readonly #store = inject(Store);
  readonly #actions$ = inject(Actions);

  protected readonly supplierItems = signal<RetailerSupplierItem[]>([]);
  protected readonly isAllLocationsSelected = computed(() => this.selectedLocationId() === ALL_LOCATIONS_OPTION_ID);

  protected readonly locations = computed<SkeletonObjectType[]>(() => {
    const userLocations = this.retailerLocations().map(({ id, name }) => ({ id, name }));

    return [
      ...userLocations,
      {
        id: ALL_LOCATIONS_OPTION_ID,
        name: $localize`:@@common.allLocations: All (${userLocations.length} Locations)`,
      },
    ];
  });

  protected readonly hasInaccurateItem = computed(() => this.supplierItems().some(item => !item.isAccurate));

  get affectedRows(): RetailerSupplierItem[] {
    return getAffectedRows({
      originalList: this.supplierItems() ?? [],
      updatedList: this.supplierItemsGrid?.rows ?? [],
      keys: ['price', 'itemCode', 'supplierItem'],
    });
  }

  get isSaveDisabled(): boolean {
    return this.affectedRows.length === 0;
  }

  get isIntegratedSupplier(): boolean {
    return this.integratedSupplierInfo?.partnershipType === SupplierPartnershipTypeEnum.Integrated;
  }

  get integratedSupplierContact(): string | undefined {
    return this.integratedSupplierInfo?.partnershipContact;
  }

  get supplierName(): string | undefined {
    return this.integratedSupplierInfo?.name;
  }

  get selectedRows(): RetailerSupplierItem[] {
    return this.supplierItemsGrid?.selectedRows ?? [];
  }

  get selectedRowsIds(): string[] {
    return this.supplierItemsGrid?.selectedRows.map(item => item.id);
  }

  get isSelectedRows(): boolean {
    return this.selectedRows.length > 0;
  }

  get isEditable(): boolean {
    return !this.isIntegratedSupplier && !this.isCKSupplierV1 && !this.isCKSupplierV2;
  }

  protected onLocationChange(locationId: string): void {
    this.onSelectedLocationChange.emit(locationId);
  }

  protected onApplyFilter({ search, ...rest }: FilterChange): void {
    const filters = {
      codeName: search,
      ...rest,
    };

    this.patchFilters.emit(filters);
  }

  protected onUpdate(): void {
    if (this.isSaveDisabled || this.isSaving) return;

    const itemsRequest: UpdateRetailerSupplierItemsRequest = {
      items: this.affectedRows.map(item => ({
        channelItemsIds: item.channelItems
          .filter(({ location }) =>
            !this.isAllLocationsSelected() ? location?.id === this.selectedLocationId() : true,
          )
          .map(({ id }) => id),
        price: +item.price,
        name: item.supplierItem.name.en,
        code: item.itemCode,
      })),
    };

    this.updateRetailerSupplierItems.emit(itemsRequest);
  }

  protected onDelete(): void {
    const deleteRequest: string[] = this.selectedRows.flatMap(item =>
      item.channelItems.map(channelItem => channelItem.id),
    );

    this.deleteRetailerSupplierItems.emit({
      deleteRequest,
      isDataChanged: this.affectedRows.length > 0,
      selectedItemIds: this.selectedRowsIds,
    });

    this.supplierItemsGrid.clearSelection();
  }

  protected onPageChange(page: number): void {
    this.pageChange.emit(page);
  }

  protected onExportSelected(): void {
    this.exportSelected.emit(this.selectedRowsIds);
  }

  protected onImport(): void {
    this.exportAll.emit(true);

    const dialog = this.#dialogService.openDialog(ImportFileDialogComponent, {
      title: $localize`:@@suppliers.create.supplierItems.import:Import Supplier Items`,
      isLoading$: this.#store.select(hasActionsExecuting([RetailerSupplierItemsImport])),
      isImportDisabled$: this.#store.select(hasActionsExecuting([RetailerSupplierItemsGetAllData])),
    });

    dialog.imported.pipe(takeUntil(this.destroyed$)).subscribe(worksheet => {
      if (!this.isValidSheet(worksheet)) {
        return;
      }

      this.import.emit(this.getUpdatedItems(worksheet));
    });

    this.#actions$
      .pipe(takeUntil(this.destroyed$), ofActionSuccessful(RetailerSupplierItemsImport))
      .subscribe(() => dialog.closeDialog());
  }

  protected onExportAll(): void {
    this.exportAll.emit();
  }

  private getUpdatedItems(worksheet: Worksheet): ImportSupplierItemsRequest {
    const rows = worksheet.getRows(2, worksheet.actualRowCount) ?? [];

    const payload: ImportSupplierItem[] = [];

    for (const row of rows) {
      if (!row.values.length) {
        continue;
      }

      const cellValues = row.values as CellValue[];

      const supplierItemId = String(cellValues.at(SUPPLIER_ITEM_ID_INDEX));
      const price = parseFloat(String(cellValues.at(PRICE_INDEX)));
      const supplierItemName = String(cellValues.at(SUPPLIER_ITEM_NAME_INDEX) ?? '');
      const supplierItemCode = String(cellValues.at(SUPPLIER_ITEM_CODE_INDEX) ?? '');

      payload.push({
        supplierItemId,
        price,
        name: supplierItemName,
        code: supplierItemCode,
      });
    }

    return { items: payload };
  }

  private isValidSheet(worksheet: Worksheet): boolean {
    const rows = worksheet.getRows(2, worksheet.actualRowCount) ?? [];

    for (const row of rows) {
      if (!row.values.length) {
        continue;
      }

      const cellValues = row.values as CellValue[];

      const price = parseFloat(String(cellValues.at(PRICE_INDEX)));
      const supplierItemName = String(cellValues.at(SUPPLIER_ITEM_NAME_INDEX));
      const supplierItemCode = String(cellValues.at(SUPPLIER_ITEM_CODE_INDEX));

      if (!this.isValidRow({ supplierItemName, supplierItemCode, price }, row.number)) {
        return false;
      }
    }

    return true;
  }

  private isValidRow(
    { supplierItemName, supplierItemCode, price }: SupplierItemsImportRowDataToCheck,
    rowNumber: number,
  ): boolean {
    if (Number.isNaN(price) || price < 0) {
      this.#snackbarService.error($localize`:@@common.error.invalidPriceAtRow:Invalid Price at row ${rowNumber}`);

      return false;
    } else if (!supplierItemName?.length) {
      this.#snackbarService.error(
        $localize`:@@common.error.invalidSupplierItemName:Invalid Supplier Item Name at row ${rowNumber}`,
      );

      return false;
    } else if (!supplierItemCode?.length) {
      this.#snackbarService.error(
        $localize`:@@common.error.invalidSupplierItemCode:Invalid Supplier Item Code at row ${rowNumber}`,
      );

      return false;
    }

    return true;
  }
}
