<article
  *supyContext="{
    isCKv1: isCKv1(),
    channel: channel(),
    currency: currency(),
  } as context"
  class="review-order"
>
  <supy-loading-overlay *ngIf="isLoading()"></supy-loading-overlay>

  <div class="review-order__header review-order__section">
    <div class="review-order__row">
      <supy-button color="default" type="icon" name="back" (buttonClick)="goBack()">
        <supy-icon name="arrow-left"></supy-icon>
      </supy-button>
      <supy-avatar
        [initials]="context.channel?.displayName?.supplier ?? context.channel?.partyInfo?.supplier.name"
        [src]="context.channel?.partyInfo?.supplier.logoUrl"
        size="medium"
      ></supy-avatar>
      <h4>{{ context.channel?.displayName?.supplier ?? context.channel?.partyInfo?.supplier.name }}</h4>
    </div>
    <div class="review-order__row">
      <h5 i18n="@@placeOrder.review.itemsInOrder">Items in this order</h5>
    </div>
  </div>

  <div class="review-order__content">
    <supy-grid primaryKey="productId" cellSelection="none" [height]="null" [data]="channelItems()">
      <supy-grid-column width="30%" header="Name" i18n-header="@@name" field="name">
        <ng-template supyGridCell let-value>{{ getLocalizedName(value) }}</ng-template>
      </supy-grid-column>
      <supy-grid-column width="20%" header="Category" i18n-header="@@category" field="category.name">
        <ng-template supyGridCell let-value>{{ getLocalizedName(value) }}</ng-template>
      </supy-grid-column>
      <ng-container *ngIf="context.isCKv1; else price">
        <supy-grid-column
          *ngIf="!pushToInventorySettings()?.hideCKStock"
          width="15%"
          header="Supplier Stock Qty"
          i18n-header="@@grid.headers.supplierStockQty.label"
          field="ckStock"
        >
          <ng-template supyGridCell let-value>{{ value ?? 0 | supyPrecision: DEFAULT_QUANTITY_PRECISION }}</ng-template>
        </supy-grid-column>
      </ng-container>
      <ng-template #price>
        @if (!hidePrices()) {
          <supy-grid-column
            width="15%"
            header="Price ({{ context.currency }})"
            i18n-header="@@common.priceInterpolation"
            field="price"
          >
            <ng-template supyGridCell let-value>{{ value | supyPrecision: currencyPrecision() }}</ng-template>
          </supy-grid-column>
        }
      </ng-template>
      <supy-grid-column width="20%" header="Qty" i18n-header="@@qty" field="quantity">
        <ng-template supyGridCell let-rowData="rowData" let-value="value">
          <supy-counter-input
            [value]="value ?? 0"
            [step]="1"
            [min]="0"
            name="qty"
            (valueChange)="onQuantityChange(rowData, $event)"
          ></supy-counter-input>
        </ng-template>
      </supy-grid-column>
      <supy-grid-column width="10%" header="Actions" i18n-header="@@actions">
        <ng-template supyGridCell let-rowData="rowData">
          <supy-button color="default" type="icon" name="delete-row" (buttonClick)="onRemoveItem(rowData.id)">
            <supy-icon color="error" name="delete"></supy-icon>
          </supy-button>
        </ng-template>
      </supy-grid-column>
    </supy-grid>
  </div>

  <div class="review-order__inputs review-order__section" [formGroup]="form">
    <div class="review-order__input">
      <small i18n="@@placeOrder.review.notes">Supplier Comment</small>
      <supy-input
        formControlName="comment"
        name="comment"
        i18n-placeholder="@@typeSomething"
        placeholder="Type something"
      ></supy-input>
    </div>
    <div class="review-order__input">
      <p class="review-order__input-label">
        <small i18n="@@deliveryDate">Delivery Date</small>
        <supy-icon name="info" size="small" [supyTooltipDeprecated]="timezoneTooltip" position="top"></supy-icon>
        <supy-tooltip-deprecated #timezoneTooltip>
          {{ getRetailerTimeZoneHelperMessage(utcOffset()) }}
        </supy-tooltip-deprecated>
      </p>
      <supy-date-input
        formControlName="deliveryDate"
        placeholder="Select Date"
        i18n-placeholder="@@common.selectDate"
        [minValue]="minimumDeliveryDate()"
        [ianaTimeZone]="ianaTimeZone()"
      >
        <supy-input-error *ngIf="form.get('deliveryDate').getError('minDate')" i18n="@@common.deliveryDateError">
          Delivery date can't be before today
        </supy-input-error>
      </supy-date-input>
    </div>
  </div>
  <div class="review-order__footer review-order__section review-order__row--space-between">
    <div class="place-order__sum">
      <ng-container *ngIf="context.isCKv1; else estimatedPrice">
        <h6 class="place-order__sum-label" i18n="@@placeOrder.estimatedQty">Estimated quantity:</h6>
      </ng-container>
      <ng-template #estimatedPrice>
        @if (!hidePrices()) {
          <h6 class="place-order__sum-label" i18n="@@estimatedPrice">Estimated Price:</h6>
        }
      </ng-template>

      @if (context.isCKv1 || !hidePrices()) {
        <h4 class="place-order__sum-value">
          {{
            context.isCKv1
              ? (totalQuantity() | supyPrecision: DEFAULT_QUANTITY_PRECISION)
              : (totalPrice() | supyPrecision: currencyPrecision() | currency: context.currency)
          }}
        </h4>
      }
    </div>
    <div *supyContext="isFooterDisabled() as isFooterDisabled" class="review-order__row">
      <supy-button
        *ngIf="order()?.status === orderStatus.Draft; else draftOrder"
        i18n="@@common.actions.saveDraft"
        color="secondary"
        name="draft"
        [disabled]="isFooterDisabled"
        (buttonClick)="onUpdate()"
      >
        Save Draft
      </supy-button>

      <ng-template #draftOrder>
        <supy-button
          *ngIf="order()?.status !== orderStatus.Draft"
          i18n="@@draftOrders"
          color="secondary"
          name="draft"
          [disabled]="isFooterDisabled"
          (buttonClick)="onDraft()"
        >
          Draft Order
        </supy-button>
      </ng-template>

      <supy-button
        *ngIf="canSubmitOrder()"
        i18n="@@orderSubmit"
        color="primary"
        name="submit"
        [disabled]="isFooterDisabled"
        (buttonClick)="onSubmit()"
        >Submit Order</supy-button
      >
    </div>
  </div>
</article>
