import { BaseRequestMetadata, IdType, SimpleUser } from '@supy/common';
import { SimpleEntity } from '@supy/orders';

import { CkCatalogItemResponse, CkCatalogItemState } from '../catalog';
import { CkCustomer } from './ck-customer.entity';

export enum CkCustomerPaymentTerms {
  FifteenDays = '15 Days',
  ThirtyDays = '30 Days',
  SixtyDays = '60 Days',
  OnDelivery = 'OnDelivery',
}

export enum CkCustomerState {
  Active = 'active',
  Archived = 'archived',
}

export interface CkCustomerAutoCompleteResponse {
  readonly address: string;
  readonly id: string;
  readonly name: string;
  readonly outlet: SimpleEntity;
  readonly uuid: string;
}

export interface CkCustomerContactResponse {
  readonly name: string;
  readonly phones: string[];
  readonly emails: string[];
}

export interface CkCustomerPreferencesResponse {
  readonly minimumOrderLimit: number;
  readonly paymentTerms: CkCustomerPaymentTerms;
}

export interface CkCustomerLastOrderResponse {
  readonly order: IdType;
  readonly value: number;
  readonly date: Date;
}

export interface CkCustomerResponse {
  readonly address: string;
  readonly code: string;
  readonly contacts: CkCustomerContactResponse[];
  readonly createdBy: SimpleUser;
  readonly customerGroup: CustomerGroup;
  readonly displayName: string;
  readonly excludedCKItems: IdType[];
  readonly externalId?: string;
  readonly id: string;
  readonly lastOrder?: CkCustomerLastOrderResponse;
  readonly legalName: string;
  readonly preferences?: CkCustomerPreferencesResponse;
  readonly priceList: SimpleEntity;
  readonly retailer: IdType;
  readonly state: CkCustomerState;
  readonly taxRegistrationNumber: string;
  readonly updatedBy: SimpleUser;
  readonly location?: IdType;
}

export type CkCustomerItemResponse = CkCatalogItemResponse;
export type CkCustomerContactRequest = CkCustomerContactResponse;
export type CkCustomerPreferencesRequest = CkCustomerPreferencesResponse;

export interface CkCustomerRequest {
  readonly address: string;
  readonly code: string;
  readonly contacts?: CkCustomerContactRequest[];
  readonly customerGroup: IdType;
  readonly displayName: string;
  readonly externalId?: string;
  readonly legalName: string;
  readonly preferences: CkCustomerPreferencesRequest;
  readonly priceList?: IdType;
  readonly taxRegistrationNumber: string;
}

export interface CustomerGroup {
  id: string;
  createdAt: string;
  updatedAt: string;
  createdBy: SimpleUser;
  updatedBy: SimpleUser;
  name: string;
  isInternal: boolean;
  retailer: IdType;
  state: string;
  version: number;
  defaultTaxCode: string;
}

export interface CkCustomerCreateRequest extends CkCustomerRequest {
  readonly retailer: IdType;
}

export type CkCustomerUpdateRequest = CkCustomerRequest;

export interface CkCustomerItemsUpdateRequest {
  readonly ids: string[];
  readonly state: CkCatalogItemState;
}

export interface CkCustomersQueryProps {
  readonly 'centralKitchen.locationId': string;
  readonly 'centralKitchen.supplierId': string;
  readonly 'customerGroup.id': string;
  readonly 'priceList.id': string;
  readonly 'retailer.id': string;
  readonly code: string;
  readonly displayName: string;
  readonly id: string;
  readonly legalName: string;
}

export interface CkCustomerAutoCompleteQueryProps {
  readonly 'retailer.id': string;
  readonly 'centralKitchen.supplierId': string;
  readonly displayName: string;
  readonly legalName: string;
}

export interface CkCustomersRequestMetadata extends BaseRequestMetadata {
  readonly retailerId: string;
}

export interface CkCustomerItemsRequestMetadata extends BaseRequestMetadata {
  readonly retailerId: string;
  readonly customer: CkCustomer;
  readonly warehouseId: string;
}
