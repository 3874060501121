import { NonFunctionProperties } from '@supy/core';

import { GrnTotals, GrnTotalsRequest } from '../../entities';

export class GrnTotalsPayload extends GrnTotals {
  constructor(args: NonFunctionProperties<GrnTotalsPayload>) {
    super(args);
  }

  serialize(): GrnTotalsRequest {
    return {
      discount: this.discount,
      fees: this.fees,
      subTotal: this.subTotal,
      taxAmount: this.taxAmount,
      total: this.total,
    };
  }
}
