import { getApiDetailsDecorator, LocalizedData } from '@supy/common';
import { NonFunctionProperties } from '@supy/core';
import { getLocalizedName } from '@supy/settings';

import { CkCustomerAutoCompleteResponse } from './ck-customer.model';

const ApiProperty = getApiDetailsDecorator<CkCustomerAutoCompleteResponse>();

export class CkCustomerAutoComplete {
  private constructor(args: NonFunctionProperties<CkCustomerAutoComplete>) {
    this.address = args.address;
    this.id = args.id;
    this.name = args.name;
    this.outletLocalizedData = args.outletLocalizedData;
    this.supyRetailerId = args.supyRetailerId;
    this.displayName = args.displayName;
  }

  @ApiProperty() readonly address: string;
  @ApiProperty() readonly id: string;
  @ApiProperty() readonly name: string;
  @ApiProperty({ key: 'outlet', uiModel: LocalizedData }) readonly outletLocalizedData: LocalizedData;
  @ApiProperty({ key: 'uuid' }) readonly supyRetailerId: string;
  readonly displayName: string;

  static deserialize(data: CkCustomerAutoCompleteResponse): CkCustomerAutoComplete {
    const outletLocalizedData = data.outlet.name as LocalizedData;

    return new CkCustomerAutoComplete({
      address: data.address,
      displayName: `${getLocalizedName(outletLocalizedData)} - ${data.name}`,
      id: data.id,
      name: data.name,
      outletLocalizedData,
      supyRetailerId: data.uuid,
    });
  }

  static deserializeList(data: CkCustomerAutoCompleteResponse[]): CkCustomerAutoComplete[] {
    return data.map(customer => this.deserialize(customer));
  }
}
