import { Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { inject, Injectable } from '@angular/core';

import { IQueryResponse, Query } from '@supy/common';

import { CK_CUSTOMER_GROUPS_BFF_URI } from '../config';
import {
  CkCustomerGroupCreatePayload,
  CkCustomerGroupResponse,
  CkCustomerGroupsQueryProps,
  CkCustomerGroupUpdatePayload,
  CkDeleteManyRequest,
} from '../core';

@Injectable()
export class CkCustomerGroupsService {
  readonly #httpClient = inject(HttpClient);
  readonly #uriBff = inject(CK_CUSTOMER_GROUPS_BFF_URI);

  get(id: string): Observable<CkCustomerGroupResponse> {
    return this.#httpClient.get<CkCustomerGroupResponse>(`${this.#uriBff}/${id}`);
  }

  getMany(query: Query<CkCustomerGroupsQueryProps>): Observable<IQueryResponse<CkCustomerGroupResponse>> {
    return this.#httpClient.get<IQueryResponse<CkCustomerGroupResponse>>(`${this.#uriBff}`, {
      params: query.toQueryParams(),
    });
  }

  create(payload: CkCustomerGroupCreatePayload): Observable<void> {
    return this.#httpClient.post<void>(`${this.#uriBff}`, payload.serialize());
  }

  update(id: string, payload: CkCustomerGroupUpdatePayload): Observable<void> {
    return this.#httpClient.patch<void>(`${this.#uriBff}/${id}`, payload.serialize());
  }

  deleteMany(payload: CkDeleteManyRequest): Observable<void> {
    return this.#httpClient.patch<void>(`${this.#uriBff}`, payload);
  }
}
