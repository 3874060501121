import {
  CkCustomerReturn,
  CkCustomerReturnApproveManyRequest,
  CkCustomerReturnCreateRequest,
  CkCustomerReturnDiscardManyRequest,
  CkCustomerReturnLockManyRequest,
  CkCustomerReturnPostManyRequest,
  CkCustomerReturnUpdateRequest,
} from '../../core';
import { CkCustomerReturnsFilters, CkCustomerReturnsRequestMetadata } from '../state/ck-customer-returns.state';

export class CkCustomerReturnsGet {
  static readonly type = '[CkCustomerReturns] Get';

  constructor(readonly payload: { id: CkCustomerReturn['id']; fromCache?: boolean }) {}
}

export class CkCustomerReturnsGetMany {
  static readonly type = '[CkCustomerReturns] Get Many';
}

export class CkCustomerReturnsExportList {
  static readonly type = '[CkCustomerReturns] ExportList';
}

export class CkCustomerReturnsDownloadPdf {
  static readonly type = '[CkCustomerReturns] DownloadPdf';

  constructor(readonly id: string) {}
}

export class CkCustomerReturnsResetPostManyResponse {
  static readonly type = '[CkCustomerReturns] ClearPostMany';
}

export class CkCustomerReturnsInitFilters {
  static readonly type = '[CkCustomerReturns] InitFilters';
}

export class CkCustomerReturnsSetFilters {
  static readonly type = '[CkCustomerReturns] SetFilters';

  constructor(readonly payload: CkCustomerReturnsFilters) {}
}

export class CkCustomerReturnsPatchFilters {
  static readonly type = '[CkCustomerReturns] PatchFilters';

  constructor(readonly payload: Partial<CkCustomerReturnsFilters>) {}
}

export class CkCustomerReturnsResetFilters {
  static readonly type = '[CkCustomerReturns] ResetFilters';

  constructor(readonly payload?: Pick<CkCustomerReturnsFilters, 'start' | 'end'>) {}
}

export class CkCustomerReturnsPatchRequestMetadata {
  static readonly type = '[CkCustomerReturns] PatchRequestMetadata';

  constructor(readonly payload: Partial<CkCustomerReturnsRequestMetadata>) {}
}

export class CkCustomerReturnsGenerateDocumentNumber {
  static readonly type = '[CkCustomerReturns] GenerateDocumentNumber';
}

export class CkCustomerReturnsApprove {
  static readonly type = '[CkCustomerReturns] Approve';

  constructor(readonly id: CkCustomerReturn['id']) {}
}

export class CkCustomerReturnsApproveMany {
  static readonly type = '[CkCustomerReturns] ApproveMany';

  constructor(readonly body: CkCustomerReturnApproveManyRequest) {}
}

export class CkCustomerReturnsDiscard {
  static readonly type = '[CkCustomerReturns] Discard';

  constructor(readonly id: CkCustomerReturn['id']) {}
}

export class CkCustomerReturnsDiscardMany {
  static readonly type = '[CkCustomerReturns] DiscardMany';

  constructor(readonly body: CkCustomerReturnDiscardManyRequest) {}
}

export class CkCustomerReturnsLock {
  static readonly type = '[CkCustomerReturns] Lock';

  constructor(readonly id: CkCustomerReturn['id']) {}
}

export class CkCustomerReturnsLockMany {
  static readonly type = '[CkCustomerReturns] Lock Many';

  constructor(readonly payload: CkCustomerReturnLockManyRequest) {}
}

export class CkCustomerReturnsUnlock {
  static readonly type = '[CkCustomerReturns] Unlock';

  constructor(readonly id: CkCustomerReturn['id']) {}
}

export class CkCustomerReturnsCreate {
  static readonly type = '[CkCustomerReturns] Create';

  constructor(readonly payload: { body: CkCustomerReturnCreateRequest }) {}
}

export class CkCustomerReturnsUpdate {
  static readonly type = '[CkCustomerReturns] Update';

  constructor(
    readonly payload: { readonly id: CkCustomerReturn['id']; readonly body: CkCustomerReturnUpdateRequest },
  ) {}
}

export class CkCustomerReturnsPost {
  static readonly type = '[CkCustomerReturns] Post';

  constructor(readonly id: CkCustomerReturn['id']) {}
}

export class CkCustomerReturnsPostMany {
  static readonly type = '[CkCustomerReturns] Post Many';

  constructor(readonly payload: CkCustomerReturnPostManyRequest) {}
}

export class CkCustomerReturensNavigateToList {
  static readonly type = '[CkCustomerReturns] NavigateToList';
}

export class CkCustomerReturnsNavigateToDetails {
  static readonly type = '[CkCustomerReturns] NavigateToDetails';

  constructor(readonly id: CkCustomerReturn['id']) {}
}
