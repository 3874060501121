import { IANATimezone } from '@supy.api/dictionaries';

import {
  createWorksheet,
  DataColumn,
  DEFAULT_IANA_TIMEZONE,
  getUTCOverriddenDateTime,
  LocalizedData,
  saveWorkbook,
} from '@supy/common';

import { CkOrderItem } from '../core';

function isLocalizedData(value: string | LocalizedData): value is LocalizedData {
  return typeof value === 'object' && 'en' in value;
}

export async function downloadCkOrderItemsList(
  items: CkOrderItem[],
  ianaTimeZone: IANATimezone = DEFAULT_IANA_TIMEZONE,
  hidePrices?: boolean,
): Promise<void> {
  const columns: DataColumn[] = [
    { header: $localize`:@@orders.list.columns.itemCode:Item Code`, key: 'code' },
    { header: $localize`:@@orders.list.columns.packageItemName:Package Item Name`, key: 'name' },
    { header: $localize`:@@orders.list.columns.itemCategory:Item Category`, key: 'category' },
    { header: $localize`:@@orders.list.columns.expectedDeliveryDate:Expected Delivery Date`, key: 'deliveryDate' },
  ];

  if (!hidePrices) {
    columns.push(
      { header: $localize`:@@orders.list.columns.stockQuantity:Stock Quantity`, key: 'stockQuantity' },
      {
        header: $localize`:@@orders.list.columns.expectingStockQuantity:Expecting Stock Quantity`,
        key: 'expectingStockQuantity',
      },
      {
        header: $localize`:@@orders.list.columns.projectedStockQuantity:Projected Stock Quantity`,
        key: 'projectedStockQuantity',
      },
      { header: $localize`:@@orders.list.columns.orderedQty:Ordered Qty`, key: 'orderedQuantity' },
    );
  }

  const dateCols = new Set(['deliveryDate']);
  const currencyCols = new Set([
    'stockQuantity',
    'expectingStockQuantity',
    'projectedStockQuantity',
    'orderedQuantity',
  ]);

  const { Workbook } = (await import('exceljs')).default;
  const workbook = new Workbook();

  const data = items.map(item => ({
    code: item.code,
    name: item.name?.en,
    category: item.category?.name
      ? isLocalizedData(item.category.name)
        ? item.category.name.en
        : item.category.name
      : '',
    deliveryDate: item.deliveryDate ? getUTCOverriddenDateTime(item.deliveryDate, ianaTimeZone) : null,
    stockQuantity: item.stockQuantity,
    expectingStockQuantity: item.expectingStockQuantity,
    projectedStockQuantity: item.projectedStockQuantity,
    orderedQuantity: item.orderedQuantity,
  }));

  await createWorksheet(workbook, 'Order Items', { data, columns, dateCols, currencyCols });

  const fileName = `ck-order-items-list-${new Date().toISOString()}.xlsx`;

  saveWorkbook(workbook, fileName);
}
