import { BaseRequestMetadata, IdType, SimpleUser } from '@supy/common';
import { SimpleEntity } from '@supy/orders';

import { CkMetadata } from './ck-common.model';

export enum CkCatalogItemState {
  Available = 'available',
  Archived = 'archived',
}

export enum CkCatalogItemMarginType {
  Value = 'value',
  Percentage = 'percentage',
}

export enum CkCatalogItemPricingMethod {
  AddMargin = 'add-margin',
  AtCost = 'at-cost',
  StaticPrice = 'static-price',
}

export interface CkCatalogPriceListRequest {
  readonly id: string;
  readonly price: number;
  readonly pricingMethod: CkCatalogItemPricingMethod;
  readonly addMargin: CkCatalogItemMargin;
}

export interface CkCatalogItemPackagingResponse {
  readonly name: string;
  readonly code: string;
  readonly id: string;
}

export interface CkCatalogItemRetailerItemResponse {
  readonly category?: SimpleEntity;
  readonly inventoryItem: string;
  readonly taxCode?: string;
}

export interface CkCatalogPriceListResponse {
  readonly id: string;
  readonly name: string;
  readonly price: number;
  readonly addMargin: CkCatalogItemMargin;
  readonly pricingMethod: CkCatalogItemPricingMethod;
}

export interface CkCatalogItemMargin {
  readonly amount: number;
  readonly type: CkCatalogItemMarginType;
}

export interface CkCatalogItemResponse {
  readonly centralKitchen: CkMetadata;
  readonly cost: number;
  readonly createdBy: SimpleUser;
  readonly id: string;
  readonly packaging: CkCatalogItemPackagingResponse;
  readonly priceLists: CkCatalogPriceListResponse[];
  readonly quantity: number;
  readonly retailerId: string;
  readonly retailerItem: CkCatalogItemRetailerItemResponse;
  readonly state: CkCatalogItemState;
  readonly updatedBy: SimpleUser;
}

export interface CkCatalogItemsQueryProps {
  readonly 'retailer.id': string;
  readonly 'centralKitchen.locationId': string;
  readonly 'priceLists.id': string;
  readonly 'packaging.name': string;
  readonly 'packaging.id': string;
  readonly 'packaging.code': string;
  readonly 'category.id': string[];
  readonly 'category.name': string;
  readonly state: string;
  readonly id: string;
  readonly 'centralKitchen.supplierId': string;
}

export interface CkCatalogItemRequest {
  readonly priceLists: CkCatalogPriceListRequest[];
}

export interface CkCatalogItemCreateRequest extends CkCatalogItemRequest {
  readonly centralKitchen: IdType;
  readonly packaging: IdType;
}

export type CkCatalogItemUpdateRequest = Pick<CkCatalogItemCreateRequest, 'priceLists'>;

export interface CkCatalogItemsRequestMetadata extends BaseRequestMetadata {
  readonly retailerId: string;
  readonly warehouseId: string;
}
