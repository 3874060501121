import { Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { inject, Injectable } from '@angular/core';

import { IdType, IQueryResponse, Query } from '@supy/common';

import { CK_CUSTOMER_RETURNS_URI } from '../config';
import {
  CkCustomerReturn,
  CkCustomerReturnApproveManyRequest,
  CkCustomerReturnCreateRequest,
  CkCustomerReturnDiscardManyRequest,
  CkCustomerReturnDownloadPdfResponse,
  CkCustomerReturnGenerateDocumentNumberRequest,
  CkCustomerReturnGenerateDocumentNumberResponse,
  CkCustomerReturnLockManyRequest,
  CkCustomerReturnPostManyRequest,
  CkCustomerReturnPostManyResponse,
  CkCustomerReturnQueryProps,
  CkCustomerReturnResponse,
  CkCustomerReturnUpdateRequest,
  CkCustomerReturnUploadResponse,
} from '../core';

@Injectable()
export class CkCustomerReturnsService {
  readonly #httpClient = inject(HttpClient);
  readonly #uri = inject(CK_CUSTOMER_RETURNS_URI);

  get(id: CkCustomerReturn['id']): Observable<CkCustomerReturnResponse> {
    return this.#httpClient.get<CkCustomerReturnResponse>(`${this.#uri}/${id}`);
  }

  getMany(query: Query<CkCustomerReturnQueryProps>): Observable<IQueryResponse<CkCustomerReturnResponse>> {
    return this.#httpClient.get<IQueryResponse<CkCustomerReturnResponse>>(this.#uri, { params: query.toQueryParams() });
  }

  create(body: CkCustomerReturnCreateRequest): Observable<IdType> {
    return this.#httpClient.post<IdType>(this.#uri, body);
  }

  update(id: CkCustomerReturn['id'], body: CkCustomerReturnUpdateRequest): Observable<void> {
    return this.#httpClient.patch<void>(`${this.#uri}/${id}`, body);
  }

  post(id: CkCustomerReturn['id']): Observable<void> {
    return this.#httpClient.post<void>(`${this.#uri}/${id}/post`, null);
  }

  postMany(body: CkCustomerReturnPostManyRequest): Observable<CkCustomerReturnPostManyResponse> {
    return this.#httpClient.post<CkCustomerReturnPostManyResponse>(`${this.#uri}/post`, body);
  }

  lock(id: CkCustomerReturn['id']): Observable<void> {
    return this.#httpClient.post<void>(`${this.#uri}/${id}/lock`, null);
  }

  lockMany(body: CkCustomerReturnLockManyRequest): Observable<void> {
    return this.#httpClient.post<void>(`${this.#uri}/lock`, body);
  }

  unlock(id: CkCustomerReturn['id']): Observable<void> {
    return this.#httpClient.post<void>(`${this.#uri}/${id}/unlock`, null);
  }

  approve(id: CkCustomerReturn['id']): Observable<void> {
    return this.#httpClient.patch<void>(`${this.#uri}/${id}/approve`, null);
  }

  approveMany(body: CkCustomerReturnApproveManyRequest): Observable<void> {
    return this.#httpClient.patch<void>(`${this.#uri}/approve`, body);
  }

  discard(id: CkCustomerReturn['id']): Observable<void> {
    return this.#httpClient.patch<void>(`${this.#uri}/${id}/discard`, null);
  }

  discardMany(body: CkCustomerReturnDiscardManyRequest): Observable<void> {
    return this.#httpClient.patch<void>(`${this.#uri}/discard`, body);
  }

  uploadFile(file: FormData, retailerId: string): Observable<CkCustomerReturnUploadResponse> {
    return this.#httpClient.post<CkCustomerReturnUploadResponse>(`${this.#uri}/upload`, file, {
      params: {
        retailerId,
      },
    });
  }

  generateDocumentNumber(
    body: CkCustomerReturnGenerateDocumentNumberRequest,
  ): Observable<CkCustomerReturnGenerateDocumentNumberResponse> {
    return this.#httpClient.post<CkCustomerReturnGenerateDocumentNumberResponse>(`${this.#uri}/generate`, body);
  }

  downloadPdf(id: string): Observable<CkCustomerReturnDownloadPdfResponse> {
    return this.#httpClient.get<CkCustomerReturnDownloadPdfResponse>(`${this.#uri}/${id}/download`);
  }
}
