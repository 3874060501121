import { getApiDetailsDecorator, IdType } from '@supy/common';
import { NonFunctionProperties, Nullable } from '@supy/core';
import { getLocalizedName } from '@supy/settings';

import { GrnChannelItem } from './grn-channel-item.entity';
import { GrnCreditNote } from './grn-credit-note.entity';
import { GrnCreditNoteType } from './grn-credit-note.model';
import {
  GrnConsolidatedInvoiceItemSource,
  GrnItemPrices,
  GrnItemQuantities,
  GrnItemResponse,
  GrnItemType,
} from './grn-item.model';
import { GrnItemTaxRate } from './grn-item-tax-rate.entity';

const ApiProperty = getApiDetailsDecorator<GrnItemResponse>();

export class GrnItem {
  protected constructor(args: NonFunctionProperties<GrnItem>) {
    this.appliedTax = args.appliedTax;
    this.allocatedCreditNote = args.allocatedCreditNote;
    this.channelItem = args.channelItem;
    this.comment = args.comment;
    this.id = args.id;
    this.adjustmentAmount = args.adjustmentAmount;
    this.partiallyReceived = args.partiallyReceived;
    this.prices = args.prices;
    this.quantities = args.quantities;
    this.source = args.source;
    this.type = args.type;
  }

  @ApiProperty() readonly allocatedCreditNote?: IdType;
  @ApiProperty() readonly appliedTax: Nullable<GrnItemTaxRate>;
  @ApiProperty() readonly channelItem: Nullable<GrnChannelItem>;
  @ApiProperty() readonly comment?: Nullable<string>;
  @ApiProperty() readonly id: string;
  @ApiProperty() readonly adjustmentAmount?: Nullable<number>;
  @ApiProperty() readonly partiallyReceived?: boolean;
  @ApiProperty() readonly prices: GrnItemPrices;
  @ApiProperty() readonly quantities: GrnItemQuantities;
  @ApiProperty() readonly source?: Nullable<GrnConsolidatedInvoiceItemSource>;
  @ApiProperty() readonly type: GrnItemType;

  static deserialize(data: GrnItemResponse): GrnItem {
    const type = data.type ?? GrnItemType.Item;

    return new GrnItem({
      allocatedCreditNote: data.allocatedCreditNote,
      appliedTax: data.appliedTax ? GrnItemTaxRate.deserialize(data.appliedTax) : null,
      channelItem: data.channelItem
        ? type
          ? GrnChannelItem.deserialize(data.channelItem)
          : GrnChannelItem.deserialize({
              ...data.channelItem,
              name: {
                en: `${data.quantities.document < 0 ? 'Quantity' : 'Price'} Credit - ${getLocalizedName(data.channelItem.name)}`,
              },
            })
        : null,
      comment: data.comment ?? null,
      id: data.id ?? crypto.randomUUID(),
      adjustmentAmount: type === GrnItemType.Item ? data.adjustmentAmount : null,
      prices: {
        invoice: Number(data.prices.invoice ?? 0),
        isCreditCreated: data.prices.isCreditCreated ?? false,
        isDiscounted: data.prices.isDiscounted ?? false,
        item: Number(data.prices.item ?? 0),
        itemHandlingFee: Number(data.prices.itemHandlingFee ?? 0),
        updatePrice: data.prices.updatePrice ?? false,
        updateStockPrice: data.prices.updateStockPrice ?? false,
      },
      partiallyReceived: data.partiallyReceived ?? false,
      quantities: {
        document: Number(data.quantities.document ?? 0),
        isCreditCreated: data.quantities.isCreditCreated ?? false,
        ordered: Number(data.quantities.ordered ?? 0),
        received: Number(data.quantities.received ?? 0),
      },
      source: data.source ?? null,
      type,
    });
  }

  static fromCreditNote(data: GrnCreditNote): GrnItem {
    return new GrnItem({
      id: crypto.randomUUID(),
      type: GrnItemType.Credit,
      appliedTax: data.appliedTax && GrnItemTaxRate.deserialize(data.appliedTax),
      comment: data.comment,
      allocatedCreditNote: {
        id: data.id,
      },
      quantities: {
        document: data.creditType === GrnCreditNoteType.Quantity ? -data.quantity : data.quantity,
        received: 0,
        ordered: 0,
      },
      prices: {
        invoice: data.creditType === GrnCreditNoteType.Price ? -data.price : data.price,
        item: data.price,
      },
      channelItem:
        data.item &&
        GrnChannelItem.deserialize({
          ...data.item,
          name: {
            en: `${data.creditType.charAt(0).toUpperCase() + data.creditType.slice(1)} Credit - ${getLocalizedName(
              data.item.name,
            )}`,
          },
        }),
    });
  }

  static calculateAppliedTaxAmount({
    quantities,
    prices,
    appliedTax,
    adjustmentRate,
  }: Pick<GrnItem, 'quantities' | 'prices' | 'appliedTax'> & {
    readonly adjustmentRate: number;
  }): number {
    return (
      (appliedTax?.rate / 100) *
      (prices.invoice + (prices.itemHandlingFee ?? 0)) *
      quantities.document *
      ((100 - adjustmentRate) / 100)
    );
  }

  static calculateTotal(data: GrnItem[]): number {
    return data.reduce<number>(
      (acc, { type, quantities, prices }) =>
        type === GrnItemType.Item
          ? acc + quantities.document * (prices.invoice + (prices.itemHandlingFee ?? 0))
          : acc + quantities.document * prices.invoice,
      0,
    );
  }
}
