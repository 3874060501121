import { getApiDetailsDecorator } from '@supy/common';
import { NonFunctionProperties } from '@supy/core';

import { CkCustomerReturnItemTaxRateResponse } from './ck-customer-return-item.model';

const ApiProperty = getApiDetailsDecorator<CkCustomerReturnItemTaxRateResponse>();

export abstract class CkCustomerReturnItemBaseTaxRate {
  protected constructor(args: NonFunctionProperties<CkCustomerReturnItemBaseTaxRate>) {
    this.name = args.name;
    this.taxCode = args.taxCode;
    this.rate = args.rate;
    this.amount = args.amount;
  }

  @ApiProperty() readonly name: string;
  @ApiProperty() readonly taxCode: string;
  @ApiProperty() readonly rate: number;
  @ApiProperty() readonly amount: number;
}

export class CkCustomerReturnItemTaxRate extends CkCustomerReturnItemBaseTaxRate {
  private constructor(args: NonFunctionProperties<CkCustomerReturnItemTaxRate>) {
    super(args);
  }

  static deserialize(data: CkCustomerReturnItemTaxRateResponse): CkCustomerReturnItemTaxRate {
    return new CkCustomerReturnItemTaxRate({
      name: data.name,
      taxCode: data.taxCode,
      rate: data.rate ?? 0,
      amount: data.amount,
    });
  }
}
