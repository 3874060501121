import { FilterActionsOptions, IdType } from '@supy/common';

import { GrnCreateRequest, GrnUpdateRequest } from '../../core';
import { GrnsFilters, GrnsRequestMetadata } from '../state';

export class GrnsGetMany {
  static readonly type = '[Grns] GetMany';
}

export class GrnsListExport {
  static readonly type = '[Grns] ListExport';
}

export class GrnsListPdfExport {
  static readonly type = '[Grns] ListPDFExport';
}

export class GrnsResetSequence {
  static readonly type = '[Grns] ResetSequence';
}

export class GrnsGet {
  static readonly type = '[Grns] Get';

  constructor(readonly payload?: { id: string; fromCache?: boolean }) {}
}

export class GrnsGetStatistics {
  static readonly type = '[Grns] GetStatistics';
}

export class GrnsCreate {
  static readonly type = '[Grns] Create';

  constructor(readonly payload: { body: GrnCreateRequest }) {}
}

export class GrnsUpdate {
  static readonly type = '[Grns] Update';

  constructor(readonly payload: { id: string; body: GrnUpdateRequest }) {}
}

export class GrnsPost {
  static readonly type = '[Grns] Post';

  constructor(readonly payload: { id: string }) {}
}

export class GrnsPostFailed {
  static readonly type = '[Grns] PostFailed';

  constructor(readonly payload: { statusCode: number; message: string }) {}
}

export class GrnsPostMany {
  static readonly type = '[Grns] PostMany';

  constructor(readonly payload: { grns: IdType[] }) {}
}

export class GrnsLock {
  static readonly type = '[Grns] Lock';

  constructor(readonly id: string) {}
}

export class GrnsLockMany {
  static readonly type = '[Grns] LockMany';

  constructor(readonly payload: { grns: IdType[] }) {}
}

export class GrnsUnlock {
  static readonly type = '[Grns] Unlock';

  constructor(readonly id: string) {}
}
export class GrnsClearPostMany {
  static readonly type = '[Grns] ClearPostMany';
}

export class GrnsInitFilters {
  static readonly type = '[Grns] InitFilters';
}

export class GrnsSetFilters {
  static readonly type = '[Grns] SetFilter';
  constructor(readonly payload?: GrnsFilters) {}
}

export class GrnsPatchFilters {
  static readonly type = '[Grns] PatchFilter';
  constructor(
    readonly payload?: Partial<GrnsFilters>,
    readonly options?: FilterActionsOptions,
  ) {}
}

export class GrnsResetFilters {
  static readonly type = '[Grns] ResetFilter';

  constructor(readonly payload?: Pick<GrnsFilters, 'start' | 'end' | 'status'>) {}
}

export class GrnsPatchRequestMeta {
  static readonly type = '[Grns] PatchRequestMeta';
  constructor(readonly payload: Partial<GrnsRequestMetadata>) {}
}

export class GrnsSync {
  static readonly type = '[Grns] Sync';

  constructor(readonly payload: { grnId: string }) {}
}

export class GrnsDownloadDetailsPdf {
  static readonly type = '[Grns] DownloadDetailsPdf';
  constructor(readonly orderId: string) {}
}

export class GrnsDiscard {
  static readonly type = '[Grns] Discard';
  constructor(readonly id: string) {}
}

export class GrnsDeconsolidate {
  static readonly type = '[Grns] Deconsolidate';
  constructor(readonly id: string) {}
}

export class GrnsLinkOrder {
  static readonly type = '[Grns] LinkOrder';
  constructor(readonly payload: { grnId: string; orderId: string }) {}
}
