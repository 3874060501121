import { BaseRequestMetadata, IdType } from '@supy/common';

import { CkCatalogItemMargin, CkCatalogItemPricingMethod, CkCatalogItemResponse } from '../catalog';
import { CkCustomerResponse } from '../customer';

export enum CkPriceListState {
  Active = 'active',
  Archived = 'archived',
}

export enum CkPriceListRoundPrice {
  RoundUp = 'round-up',
  RoundDown = 'round-down',
  None = 'none',
}

export interface CkPriceListResponse {
  readonly customerCount: number;
  readonly customers: CkCustomerResponse[];
  readonly id: string;
  readonly itemsCount: number;
  readonly name: string;
  readonly retailer: IdType;
  readonly roundPrice: CkPriceListRoundPrice;
  readonly state: CkPriceListState;
}

export type CkPriceListItemResponse = CkCatalogItemResponse;

export interface CkPriceListRequest {
  readonly name: string;
  readonly roundPrice?: CkPriceListRoundPrice;
  readonly customerIds: string[];
}

export interface CkPriceListCreateRequest extends CkPriceListRequest {
  readonly retailer: IdType;
}

export type CkPriceListUpdateRequest = Partial<CkPriceListRequest>;

export interface CkPriceListItemRequest {
  readonly id: string;
  readonly price: number;
  readonly addMargin: CkCatalogItemMargin;
  readonly pricingMethod: CkCatalogItemPricingMethod;
  readonly discount?: number;
}

export interface CkPriceListItemsRequest {
  readonly items: CkPriceListItemRequest[];
}

export interface CkPriceListItemImportRequest {
  readonly id: string;
  readonly packagingId: string;
  readonly price: number;
  readonly addMargin: CkCatalogItemMargin;
  readonly pricingMethod: CkCatalogItemPricingMethod;
}

export interface CkPriceListItemsImportRequest {
  readonly items: CkPriceListItemImportRequest[];
  readonly locationId: string;
  readonly priceListId: string;
}

export interface CkPriceListQueryProps {
  readonly id: string;
  readonly name: string;
  readonly state: string;
  readonly roundPrice: string;
  readonly 'retailer.id': string;
  readonly 'centralKitchen.locationId': string;
}

export interface CkPriceListsRequestMetadata extends BaseRequestMetadata {
  readonly retailerId: string;
}

export interface CkPriceListItemsRequestMetadata extends BaseRequestMetadata {
  readonly retailerId: string;
  readonly priceListId: string;
  readonly warehouseId: string;
}
