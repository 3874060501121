import { NonFunctionProperties } from '@supy/core';

import { CkCatalogItemCreateRequest, CkCatalogItemRequest, CkCatalogItemUpdateRequest } from './ck-catalog-item.model';
import { CkCatalogPriceLisPayload } from './ck-catalog-price-list.payload';

abstract class CkCatalogItemBasePayload {
  protected constructor(args: NonFunctionProperties<CkCatalogItemBasePayload>) {
    this.priceLists = args.priceLists;
  }

  readonly priceLists: CkCatalogPriceLisPayload[];

  protected serializeBase(): CkCatalogItemRequest {
    return {
      priceLists: this.priceLists.map(priceList => priceList.serialize()),
    };
  }
}

export class CkCatalogItemCreatePayload extends CkCatalogItemBasePayload {
  constructor(args: NonFunctionProperties<CkCatalogItemCreatePayload>) {
    super(args);
    this.centralKitchenId = args.centralKitchenId;
    this.packagingId = args.packagingId;
  }

  readonly centralKitchenId: string;
  readonly packagingId: string;

  serialize(): CkCatalogItemCreateRequest {
    return {
      ...super.serializeBase(),
      centralKitchen: { id: this.centralKitchenId },
      packaging: { id: this.packagingId },
    };
  }
}

export class CkCatalogItemUpdatePayload extends CkCatalogItemBasePayload {
  constructor(args: NonFunctionProperties<CkCatalogItemUpdatePayload>) {
    super(args);
  }

  serialize(): CkCatalogItemUpdateRequest {
    return {
      ...super.serializeBase(),
    };
  }
}
