<div class="supy-retailer-supplier-items">
  <div class="supy-retailer-supplier-items__header-container">
    <div class="supy-retailer-supplier-items__header" *ngIf="isIntegratedSupplier">
      <div class="supy-retailer-supplier-items__warning">
        <p class="supy-retailer-supplier-items__warning-item">
          <supy-icon name="info" color="error" size="small"></supy-icon>
          <span class="supy-retailer-supplier-items__warning-label">
            This is an Integrated Supplier. Kindly contact Supy or
            <a
              class="supy-retailer-supplier-items__warning-link"
              href="mailto:{{ integratedSupplierContact }}"
              target="_blank"
              rel="noopener noreferrer"
            >
              {{ supplierName }} Support
            </a>
            to create or manage items for this Supplier.
          </span>
        </p>
      </div>
    </div>

    <div class="supy-retailer-supplier-items__header">
      <supy-filter-group
        [filterGroup]="filtersGroup()"
        [latency]="400"
        (filtersChange)="onApplyFilter($event)"
        (filtersReset)="resetFilters.emit()"
      ></supy-filter-group>

      <div class="supy-retailer-supplier-items__actions">
        @if (canManageChannelItemsPerLocation()) {
          @if (hasInaccurateItem()) {
            <supy-banner
              text="Item has different prices across visible locations"
              i18n-text="@@items.suppliers.itemPrice"
              status="warn"
            ></supy-banner>
          }
          <supy-dropdown-tree
            placeholder="Select Location"
            i18n-placeholder="@@common.selectLocation"
            [value]="selectedLocationId()"
            (valueChange)="onLocationChange($event)"
            [clearable]="false"
            [data]="locations()"
          ></supy-dropdown-tree>
        }

        <supy-dropdown-toggle
          [dropdownRef]="supyDropdown"
          position="bottom-left"
          class="supy-retailer-supplier-items__dropdown"
          action
        >
          <supy-button class="supy-retailer-supplier-items__action" type="button" color="secondary" name="actions">
            <span i18n="@@actions">Actions</span>
            <supy-icon name="arrow-down"></supy-icon>
          </supy-button>

          <supy-dropdown #supyDropdown action>
            <supy-dropdown-item (click)="onImport()">
              <div class="supy-retailer-supplier-items__actions">
                <supy-icon name="document-upload"></supy-icon>
                <span i18n="@@common.actions.import">Import</span>
              </div>
            </supy-dropdown-item>
            <supy-dropdown-item (click)="onExportAll()">
              <div class="supy-retailer-supplier-items__actions">
                <supy-icon name="document-download"></supy-icon>
                <span i18n="@@common.actions.exportAll">Export All</span>
              </div>
            </supy-dropdown-item>
            <supy-dropdown-item (click)="onExportSelected()" [disabled]="!isSelectedRows">
              <div class="supy-retailer-supplier-items__actions">
                <supy-icon name="document-download"></supy-icon>
                <span i18n="@@common.actions.exportSelected">Export Selected</span>
              </div>
            </supy-dropdown-item>
            <supy-dropdown-item (click)="onDelete()" [disabled]="!isSelectedRows">
              <div class="supy-retailer-supplier-items__actions">
                <supy-icon name="delete"></supy-icon>
                <span i18n="@@deleteSelected">Delete Selected</span>
              </div>
            </supy-dropdown-item>
          </supy-dropdown>
        </supy-dropdown-toggle>

        @if (isEditable) {
          <supy-button name="save" color="primary" [disabled]="isSaveDisabled" (buttonClick)="onUpdate()">
            <supy-loader size="small" *ngIf="isSaving"></supy-loader>
            <span i18n="@@save">Save</span>
          </supy-button>
        }
      </div>
    </div>
  </div>

  <supy-retailer-supplier-items-grid
    class="supy-retailer-supplier-items__grid"
    [data]="supplierItems()"
    [isLocationSelected]="!isAllLocationsSelected()"
    [isLoading]="isLoading"
    [currency]="currency"
    [requestMetadata]="requestMetadata"
    [responseMetadata]="responseMetadata"
    [hidePrices]="hidePrices()"
    [isEditable]="isEditable"
    [currencyPrecision]="currencyPrecision"
    (pageChange)="onPageChange($event)"
  ></supy-retailer-supplier-items-grid>
</div>
