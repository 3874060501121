import { IANATimezone } from '@supy.api/dictionaries';

import { getApiDetailsDecorator, getRetailerTimeZoneShiftedDate } from '@supy/common';
import { NonFunctionProperties } from '@supy/core';

import { GrnBaseDocument, GrnDocumentAttachment, GrnDocumentRequest, GrnDocumentType } from '../../entities';

const ApiProperty = getApiDetailsDecorator<GrnDocumentRequest>();

export class GrnDocumentPayload extends GrnBaseDocument {
  constructor(args: NonFunctionProperties<GrnDocumentPayload>) {
    super(args);
    this.attachments = args.attachments ?? [];
    this.paymentDueDate = args.paymentDueDate;
    this.ianaTimeZone = args.ianaTimeZone;
    this.utcOffset = args.utcOffset;
  }

  @ApiProperty({ key: 'files' }) attachments?: GrnDocumentAttachment[];
  @ApiProperty() readonly paymentDueDate?: Date;

  readonly ianaTimeZone: IANATimezone;
  readonly utcOffset: number;

  serialize(): GrnDocumentRequest {
    return {
      number: this.number,
      type: this.type,
      files: this.attachments.map(({ path }) => path),
      isPaid: this.isPaid,
      pending: this.isPending,
      documentDate: getRetailerTimeZoneShiftedDate(new Date(this.documentDate), this.ianaTimeZone, this.utcOffset),
      ...(this.type === GrnDocumentType.Invoice || this.type === GrnDocumentType.ConsolidatedInvoice
        ? {
            paymentDueDate: getRetailerTimeZoneShiftedDate(
              new Date(this.paymentDueDate),
              this.ianaTimeZone,
              this.utcOffset,
            ),
            invoiceAmount: Number(this.invoiceAmount),
          }
        : {}),
    };
  }

  appendFiles(files: string[]): void {
    this.attachments = this.attachments.concat(files.map(file => ({ path: file, signedUrl: file })));
  }
}
