import { getApiDetailsDecorator, IdType } from '@supy/common';
import { NonFunctionProperties } from '@supy/core';
import { SimpleEntity } from '@supy/orders';

import { CkCustomerPaymentTerms, CkCustomerResponse, CkCustomerState, CustomerGroup } from './ck-customer.model';
import { CkCustomerContact } from './ck-customer-contact.entity';

const ApiProperty = getApiDetailsDecorator<CkCustomerResponse>();

export abstract class CkCustomerBase {
  protected constructor(args: NonFunctionProperties<CkCustomerBase>) {
    this.businessAddress = args.businessAddress;
    this.businessLegalName = args.businessLegalName;
    this.code = args.code;
    this.contacts = args.contacts;
    this.displayName = args.displayName;
    this.minimumOrderLimit = args.minimumOrderLimit;
    this.paymentTerms = args.paymentTerms;
    this.supyRetailerId = args.supyRetailerId;
    this.taxRegistrationNumber = args.taxRegistrationNumber;
  }

  @ApiProperty() readonly code: string;
  @ApiProperty() readonly contacts: CkCustomerContact[];
  @ApiProperty() readonly displayName: string;
  @ApiProperty() readonly taxRegistrationNumber: string;
  @ApiProperty({ key: 'address' }) readonly businessAddress: string;
  @ApiProperty({ key: 'externalId' }) readonly supyRetailerId: string;
  @ApiProperty({ key: 'legalName' }) readonly businessLegalName: string;

  readonly minimumOrderLimit: number | null;
  readonly paymentTerms: CkCustomerPaymentTerms | null;
}

export class CkCustomer extends CkCustomerBase {
  private constructor(args: NonFunctionProperties<Omit<CkCustomer, 'isInternal' | 'isExternal' | 'isActive'>>) {
    super(args);
    this.customerGroup = args.customerGroup;
    this.excludedCkItemIds = args.excludedCkItemIds;
    this.id = args.id;
    this.lastOrderDate = args.lastOrderDate;
    this.lastOrderValue = args.lastOrderValue;
    this.location = args.location;
    this.priceList = args.priceList;
    this.state = args.state;

    // Computed properties
    this.isActive = this.state === CkCustomerState.Active;
    this.isInternal = Boolean(this.location?.id);
    this.isExternal = !this.isInternal;
  }

  @ApiProperty() readonly customerGroup: CustomerGroup;
  @ApiProperty() readonly id: string;
  @ApiProperty() readonly priceList: SimpleEntity | null;
  @ApiProperty() readonly state: CkCustomerState;
  @ApiProperty({ key: 'excludedCKItems' }) readonly excludedCkItemIds: string[];
  readonly lastOrderDate: Date | null;
  readonly lastOrderValue: number | null;
  readonly location: IdType | null;

  // Computed properties
  readonly isActive: boolean;
  readonly isExternal: boolean;
  readonly isInternal: boolean;

  static deserialize(data: CkCustomerResponse): CkCustomer {
    return new CkCustomer({
      businessAddress: data.address,
      businessLegalName: data.legalName,
      code: data.code,
      contacts: CkCustomerContact.deserializeList(data.contacts ?? []),
      customerGroup: data.customerGroup,
      displayName: data.displayName,
      excludedCkItemIds: data.excludedCKItems.map(({ id }) => id),
      id: data.id,
      lastOrderDate: data.lastOrder?.date ?? null,
      lastOrderValue: data.lastOrder?.value ?? 0,
      minimumOrderLimit: data.preferences?.minimumOrderLimit,
      paymentTerms: data.preferences?.paymentTerms,
      priceList: data.priceList,
      state: data.state,
      supyRetailerId: data.externalId,
      taxRegistrationNumber: data.taxRegistrationNumber,
      location: data.location ?? null,
    });
  }

  static deserializeList(data: CkCustomerResponse[]): CkCustomer[] {
    return data.map(customer => this.deserialize(customer));
  }
}

export const CkCustomerPaymentTermsMapper: Record<CkCustomerPaymentTerms, string> = {
  [CkCustomerPaymentTerms.FifteenDays]: '15 Days',
  [CkCustomerPaymentTerms.ThirtyDays]: '30 Days',
  [CkCustomerPaymentTerms.SixtyDays]: '60 Days',
  [CkCustomerPaymentTerms.OnDelivery]: 'On Delivery',
};
