import { Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { inject, Injectable } from '@angular/core';

import { IdType, IQueryResponse, Query } from '@supy/common';

import { GRNS_URI } from '../../config';
import {
  AggregateDeliveryNoteItemsResponse,
  Grn,
  GrnCreateRequest,
  GrnDownloadPdfResponse,
  GrnLockManyRequest,
  GrnPostManyRequest,
  GrnPostManyResponse,
  GrnQueryProps,
  GrnResponse,
  GrnStatistics,
  GrnStatisticsRequest,
  GrnUpdateRequest,
  GrnUploadFileResponse,
  SimpleGrnResponse,
} from '../../core';

@Injectable()
export class GrnsService {
  readonly #httpClient = inject(HttpClient);
  readonly #uri = inject(GRNS_URI);

  get(id: string): Observable<GrnResponse> {
    return this.#httpClient.get<GrnResponse>(`${this.#uri}/${id}`);
  }

  getMany(query: Query<Grn & GrnQueryProps>): Observable<IQueryResponse<SimpleGrnResponse>> {
    return this.#httpClient.get<IQueryResponse<SimpleGrnResponse>>(this.#uri, { params: query.toQueryParams() });
  }

  aggregateDeliveryNoteItems(grns: IdType[], fetchItems?: boolean): Observable<AggregateDeliveryNoteItemsResponse> {
    return this.#httpClient.post<AggregateDeliveryNoteItemsResponse>(`${this.#uri}/aggregate/delivery-notes`, {
      grns,
      fetchItems,
    });
  }

  getStatistics(body: GrnStatisticsRequest): Observable<GrnStatistics> {
    return this.#httpClient.post<GrnStatistics>(`${this.#uri}/get-stats`, body);
  }

  create(body: GrnCreateRequest): Observable<IdType> {
    return this.#httpClient.post<IdType>(this.#uri, body);
  }

  update(id: string, body: GrnUpdateRequest): Observable<void> {
    return this.#httpClient.patch<void>(`${this.#uri}/${id}`, body);
  }

  post(id: string): Observable<void> {
    return this.#httpClient.post<void>(`${this.#uri}/${id}/post`, null);
  }

  postMany(body: GrnPostManyRequest): Observable<GrnPostManyResponse> {
    return this.#httpClient.post<GrnPostManyResponse>(`${this.#uri}/post`, body);
  }

  lock(id: string): Observable<void> {
    return this.#httpClient.post<void>(`${this.#uri}/${id}/lock`, null);
  }

  lockMany(body: GrnLockManyRequest): Observable<void> {
    return this.#httpClient.post<void>(`${this.#uri}/lock`, body);
  }

  unlock(id: string): Observable<void> {
    return this.#httpClient.post<void>(`${this.#uri}/${id}/unlock`, null);
  }

  discard(id: string): Observable<void> {
    return this.#httpClient.post<void>(`${this.#uri}/${id}/discard`, null);
  }

  delete(id: string): Observable<void> {
    return this.#httpClient.post<void>(`${this.#uri}/${id}/delete`, null);
  }

  linkOrder(id: string, orderId: string): Observable<void> {
    return this.#httpClient.post<void>(`${this.#uri}/${id}/link-order/${orderId}`, {});
  }

  uploadFile(file: FormData, retailerId: string): Observable<GrnUploadFileResponse> {
    return this.#httpClient.post<GrnUploadFileResponse>(`${this.#uri}/upload`, file, {
      params: {
        retailerId,
      },
    });
  }

  downloadPdf(id: string): Observable<GrnDownloadPdfResponse> {
    return this.#httpClient.get<GrnDownloadPdfResponse>(`${this.#uri}/${id}/download`);
  }

  downloadListPdf(query: Query<Grn & GrnQueryProps>): Observable<GrnDownloadPdfResponse> {
    return this.#httpClient.get<GrnDownloadPdfResponse>(`${this.#uri}/download-list-pdf`, {
      params: query.toQueryParams(),
    });
  }
}
