import { getApiDetailsDecorator } from '@supy/common';
import { NonFunctionProperties } from '@supy/core';

import { CkCustomerContactResponse } from './ck-customer.model';

const ApiProperty = getApiDetailsDecorator<CkCustomerContactResponse>();

export abstract class CkCustomerContactBase {
  protected constructor(args: NonFunctionProperties<CkCustomerContactBase>) {
    this.emails = args.emails;
    this.name = args.name;
    this.phones = args.phones;
  }

  @ApiProperty() readonly emails: string[];
  @ApiProperty() readonly name: string;
  @ApiProperty() readonly phones: string[];
}

export class CkCustomerContact extends CkCustomerContactBase {
  private constructor(args: NonFunctionProperties<CkCustomerContact>) {
    super(args);

    this.id = args.id;
  }

  readonly id: string;

  static deserialize(data: CkCustomerContactResponse): CkCustomerContact {
    return new CkCustomerContact({
      emails: data.emails ?? [],
      name: data.name,
      phones: data.phones ?? [],
      id: crypto.randomUUID().slice(0, 6),
    });
  }

  static deserializeList(data: CkCustomerContactResponse[]): CkCustomerContact[] {
    return data.map(contact => this.deserialize(contact));
  }
}
