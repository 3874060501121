import { getApiDetailsDecorator } from '@supy/common';
import { NonFunctionProperties, Nullable } from '@supy/core';
import { getLocalizedName, TaxRate } from '@supy/settings';

import { EditableCkCatalogItem } from '../catalog';
import { CkCustomerReturnItemResponse } from './ck-customer-return-item.model';
import { CkCustomerReturnItemTaxRate } from './ck-customer-return-tax-rate.entity';

const ApiProperty = getApiDetailsDecorator<CkCustomerReturnItemResponse>();

export abstract class BaseCkCustomerReturnItem {
  protected constructor(args: NonFunctionProperties<BaseCkCustomerReturnItem>) {
    this.comment = args.comment;
    this.id = args.id;
    this.price = args.price;
    this.quantity = args.quantity;
    this.taxRate = args.taxRate;
  }

  @ApiProperty() readonly comment: string;
  @ApiProperty() readonly id: string;
  @ApiProperty() readonly price: number;
  @ApiProperty() readonly quantity: number;
  @ApiProperty({ key: 'appliedTax' }) readonly taxRate: CkCustomerReturnItemTaxRate;
}

export class CkCustomerReturnItem extends BaseCkCustomerReturnItem {
  private constructor(args: NonFunctionProperties<CkCustomerReturnItem>) {
    super(args);
    this.ckItemId = args.ckItemId;
    this.name = args.name;
    this.code = args.code;
    this.inventoryItemId = args.inventoryItemId;
  }

  readonly ckItemId: string;
  readonly inventoryItemId: string;
  readonly name: string;
  readonly code: Nullable<string>;

  static deserialize(data: CkCustomerReturnItemResponse): CkCustomerReturnItem {
    return new CkCustomerReturnItem({
      ckItemId: data.ckItem.id,
      name: getLocalizedName(data.ckItem.name),
      code: data.ckItem?.packaging?.code ?? null,
      inventoryItemId: data.ckItem.inventoryItem,
      comment: data.comment,
      id: data.id,
      price: data.price ?? 0,
      quantity: data.quantity ?? 0,
      taxRate: data.appliedTax ? CkCustomerReturnItemTaxRate.deserialize(data.appliedTax) : null,
    });
  }

  static fromCatalogItem(
    data: EditableCkCatalogItem,
    { taxes, defaultOnReceivingTax, exemptTax }: FromCatalogItemDeserializedArgs,
  ): CkCustomerReturnItem {
    const tax =
      taxes.find(({ taxCode }) => data.retailerItem?.taxCode === taxCode) ?? defaultOnReceivingTax ?? exemptTax;

    return {
      ckItemId: data.id,
      name: data.name,
      code: data.packaging.code,
      inventoryItemId: data.retailerItem.inventoryItem,
      comment: null,
      id: crypto.randomUUID(),
      price: data.price ?? 0,
      quantity: data.quantity,
      taxRate: {
        ...tax,
        amount: data.quantity * (data.price ?? 0) * (tax.rate / 100),
      },
    };
  }
}

interface FromCatalogItemDeserializedArgs {
  readonly taxes: TaxRate[];
  readonly defaultOnReceivingTax: TaxRate;
  readonly exemptTax: TaxRate;
}
