import { NonFunctionProperties } from '@supy/core';

import { CkCustomerReturnItemTaxRateRequest } from './ck-customer-return-item.model';
import { CkCustomerReturnItemBaseTaxRate } from './ck-customer-return-tax-rate.entity';

export class CkCustomerReturnItemTaxRatePayload extends CkCustomerReturnItemBaseTaxRate {
  constructor(args: NonFunctionProperties<CkCustomerReturnItemTaxRatePayload>) {
    super(args);
    this.itemPrice = args.itemPrice;
    this.itemQuantity = args.itemQuantity;
  }

  readonly itemPrice: number;
  readonly itemQuantity: number;

  serialize(): CkCustomerReturnItemTaxRateRequest {
    return {
      name: this.name,
      rate: this.rate,
      taxCode: this.taxCode,
      amount: (this.rate / 100) * this.itemPrice * this.itemQuantity,
    };
  }
}
