import { IANATimezone } from '@supy.api/dictionaries';

import { getApiDetailsDecorator, getDateInTimeZone, LocalFile } from '@supy/common';
import { NonFunctionProperties } from '@supy/core';

import {
  CkCustomerReturnDocumentAttachment,
  CkCustomerReturnDocumentResponse,
} from './ck-customer-return-document.model';

const ApiProperty = getApiDetailsDecorator<CkCustomerReturnDocumentResponse>();

export class CkCustomerReturnDocument {
  private constructor(args: NonFunctionProperties<CkCustomerReturnDocument>) {
    this.number = args.number;
    this.attachments = args.attachments;
    this.localFiles = args.localFiles;
    this.returnDate = args.returnDate;
  }

  @ApiProperty() readonly number: string;
  @ApiProperty() readonly attachments: CkCustomerReturnDocumentAttachment[];
  @ApiProperty({ key: 'documentDate' }) readonly returnDate: Date;

  readonly localFiles: LocalFile[];

  static deserialize(
    data: CkCustomerReturnDocumentResponse,
    {
      ianaTimezone,
    }: {
      readonly ianaTimezone: IANATimezone;
    },
  ): CkCustomerReturnDocument {
    return new CkCustomerReturnDocument({
      number: data.number,
      returnDate: getDateInTimeZone(data.documentDate, ianaTimezone),
      attachments: data.attachments ?? [],
      localFiles: [],
    });
  }
}
