import { getApiDetailsDecorator, IdType } from '@supy/common';
import { NonFunctionProperties } from '@supy/core';

import {
  GrnConsolidatedInvoiceItemSource,
  GrnItemBaseRequest,
  GrnItemCreateRequest,
  GrnItemPricesRequest,
  GrnItemQuantitiesRequest,
  GrnItemTaxRate,
  GrnItemType,
  GrnItemUpdateRequest,
} from '../../entities';
import { GrnItemTaxRatePayload } from './grn-item-tax-rate.payload';

const ApiProperty = getApiDetailsDecorator<GrnItemCreateRequest | GrnItemUpdateRequest>();

export abstract class GrnItemBasePayload {
  constructor(args: NonFunctionProperties<GrnItemBasePayload>) {
    this.type = args.type;
    this.quantities = args.quantities;
    this.prices = args.prices;
    this.channelItem = args.channelItem;
    this.appliedTax = args.appliedTax;
    this.comment = args.comment;
    this.partiallyReceived = args.partiallyReceived;
    this.allocatedCreditNote = args.allocatedCreditNote;
    this.adjustmentRate = args.adjustmentRate;
    this.source = args.source;
  }

  @ApiProperty() readonly type: GrnItemType;
  @ApiProperty() readonly quantities: GrnItemQuantitiesRequest;
  @ApiProperty() readonly prices: GrnItemPricesRequest;
  @ApiProperty() readonly channelItem: IdType;
  @ApiProperty() readonly appliedTax?: GrnItemTaxRate;
  @ApiProperty() readonly comment?: string;
  @ApiProperty() readonly partiallyReceived?: boolean;
  @ApiProperty() readonly allocatedCreditNote?: IdType;
  @ApiProperty() readonly source?: GrnConsolidatedInvoiceItemSource;

  readonly adjustmentRate: number;

  protected serializeBase(): GrnItemBaseRequest {
    return {
      type: this.type,
      quantities: this.quantities,
      prices: this.prices,
      channelItem: { id: this.channelItem.id },
      appliedTax:
        this.appliedTax &&
        new GrnItemTaxRatePayload({
          ...this.appliedTax,
          itemHandlingFee: this.prices.itemHandlingFee,
          invoicePrice: this.prices.invoice,
          documentQuantity: this.quantities.document,
          adjustmentRate: this.adjustmentRate,
        }).serialize(),
      comment: this.comment,
      partiallyReceived: this.partiallyReceived,
      allocatedCreditNote: this.allocatedCreditNote?.id && {
        id: this.allocatedCreditNote.id,
      },
      source: this.source ?? null,
    };
  }
}

export class GrnItemCreatePayload extends GrnItemBasePayload {
  serialize(): GrnItemCreateRequest {
    return {
      ...this.serializeBase(),
    };
  }
}

export class GrnItemUpdatePayload extends GrnItemBasePayload {
  constructor(args: NonFunctionProperties<GrnItemUpdatePayload>) {
    super(args);

    this.id = args.id;
  }

  @ApiProperty() readonly id: string;

  serialize(): GrnItemUpdateRequest {
    return {
      ...this.serializeBase(),
      // Excluding random generated id.
      id: this.id.includes('-') ? null : this.id,
    };
  }
}
