import { CkCustomer, CkCustomerItemsRequestMetadata, CkCustomerItemsUpdateRequest } from '../../core';
import { CkCustomerItemsFilters } from '../state';

export class CkCustomerItemsGetMany {
  static readonly type = '[CkCustomerItems] GetMany';
}

export class CkCustomerItemsInitFilters {
  static readonly type = '[CkCustomerItems] InitFilters';
}

export class CkCustomerItemsPatchFilters {
  static readonly type = '[CkCustomerItems] PatchFilters';
  constructor(readonly payload?: Partial<CkCustomerItemsFilters>) {}
}

export class CkCustomerItemsResetFilters {
  static readonly type = '[CkCustomerItems] ResetFilters';
}

export class CkCustomerItemsPatchRequestMetadata {
  static readonly type = '[CkCustomerItems] PatchRequestMetadata';
  constructor(readonly payload: Partial<CkCustomerItemsRequestMetadata>) {}
}

export class CkCustomerItemsUpdate {
  static readonly type = '[CkCustomerItems] BulkActivate';
  constructor(readonly payload: CkCustomerItemsUpdateRequest) {}
}

export class CkCustomerItemsExport {
  static readonly type = '[CkCustomerItems] Export';
  constructor(readonly payload: { readonly customer: CkCustomer }) {}
}
