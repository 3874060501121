<supy-loading-overlay igxPreventDocumentScroll *ngIf="isLoading"></supy-loading-overlay>
<supy-grid
  class="retailer-orders-grid"
  cellSelection="none"
  primaryKey="id"
  [rowSelection]="isInventoryOrder ? 'multiple' : 'none'"
  [data]="orders"
  [sortStrategy]="sortStrategy"
  [emptyGridTemplate]="emptyGridTemplate"
  (cellClick)="cellClicked.emit($event.cell.row.data)"
>
  <supy-grid-column width="12.5%" header="PO #" i18n-header="@@grid.headers.poNumber.label" field="number">
    <ng-template supyGridCell let-rowData="rowData">
      <div class="retailer-orders-grid__po-container">
        <span
          class="retailer-orders-grid__po retailer-orders-grid__cursor"
          (click)="$event.stopImmediatePropagation(); poNumberClicked.emit(rowData)"
        >
          {{ rowData.number }}
        </span>
        <supy-icon
          *ngIf="rowData.creationSource === CreationSource.CentralKitchen"
          name="shop-add"
          color="primary"
          size="small"
        ></supy-icon>
      </div>
    </ng-template>
  </supy-grid-column>
  <supy-grid-column width="20%" header="Location" i18n-header="@@location">
    <ng-template supyGridCell let-rowData="rowData">
      {{ getLocalizedName(rowData.partyInfo.retailer?.outlet?.name) }} -
      {{ rowData.partyInfo.retailer?.name }}
    </ng-template>
  </supy-grid-column>
  <supy-grid-column width="15%" header="Supplier" i18n-header="@@supplier">
    <ng-template supyGridCell let-rowData="rowData">
      {{ rowData.channel?.displayName?.supplier ?? rowData.partyInfo.supplier.name }}
    </ng-template>
  </supy-grid-column>
  <supy-grid-column width="12.5%" header="Submitted On" i18n-header="@@common.submittedOn" field="createdAt">
    <ng-template supyGridCell let-value="value">
      <span class="retailer-orders-grid__date">{{ value | timezone: ianaTimeZone | date: 'd/M/yyyy' }}</span>
      <span class="retailer-orders-grid__time">{{ value | timezone: ianaTimeZone | date: 'h:mm a' }}</span>
    </ng-template>
  </supy-grid-column>
  <supy-grid-column
    width="12.5%"
    header="Exp. Delivery Date"
    i18n-header="@@common.expDeliveryDate"
    field="deliveryDate"
  >
    <ng-template supyGridCell let-value="value">
      <span class="retailer-orders-grid__date">{{ value | timezone: ianaTimeZone | date: 'd/M/yyyy' }}</span>
    </ng-template>
  </supy-grid-column>
  @if (!hidePriceColumn()) {
    <supy-grid-column width="10%" header="Total ({{ currency }})" i18n-header="@@common.totalInterpolation">
      <ng-template supyGridCell let-rowData="rowData">
        <span>
          {{ rowData.modifiedTotal || rowData.orderedTotal | supyPrecision: currencyPrecision }}
        </span>
      </ng-template>
    </supy-grid-column>
  }
  <supy-grid-column width="10%" header="Status" i18n-header="@@status" field="status">
    <ng-template supyGridCell let-value="value">
      <supy-retailer-order-status-badge
        [status]="value"
        [isInventoryOrder]="isInventoryOrder"
      ></supy-retailer-order-status-badge>
    </ng-template>
  </supy-grid-column>
  <supy-grid-footer>
    <supy-pagination
      [pageIndex]="requestMetadata?.page"
      [previousDisabled]="requestMetadata?.page === 0"
      [nextDisabled]="responseMetadata?.count < requestMetadata?.limit"
      (indexChange)="pageChanged.emit($event)"
    ></supy-pagination>
  </supy-grid-footer>
</supy-grid>

<ng-template #emptyGridTemplate>
  <div class="retailer-orders-grid__empty-template" *ngIf="!orders?.length && !isLoading">
    <supy-icon name="empty" size="extra-huge"></supy-icon>
    <span i18n="@@common.emptyState.noData">No Data</span>
  </div>
</ng-template>
