import { CkOrderItemsRequestMetadata } from '../../core';
import { CkOrderItemsFilters } from '../state';

export class CkOrderItemsGet {
  static readonly type = '[CkOrderItems] Get';
  constructor(readonly payload?: { id: string; fromCache?: boolean }) {}
}

export class CkOrderItemsGetMany {
  static readonly type = '[CkOrderItems] GetMany';
}

export class CkOrderItemsReset {
  static readonly type = '[CkOrderItems] Reset';
}

export class CkOrderItemsInitFilters {
  static readonly type = '[CkOrderItems] InitFilters';
}

export class CkOrderItemsPatchFilters {
  static readonly type = '[CkOrderItems] PatchFilters';
  constructor(readonly payload?: Partial<CkOrderItemsFilters>) {}
}

export class CkOrderItemsResetFilters {
  static readonly type = '[CkOrderItems] ResetFilter';
}

export class CkOrderItemsPatchRequestMetadata {
  static readonly type = '[CkOrderItems] PatchRequestMetadata';
  constructor(readonly payload: Partial<CkOrderItemsRequestMetadata>) {}
}

export class CkOrderItemsExportXLSX {
  static readonly type = '[CkOrderItems] ExportXLSX';
}
