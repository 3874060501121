import { createWorksheet, DataColumn, saveWorkbook } from '@supy/common';
import { getLocalizedName } from '@supy/settings';

import { CkCatalogItem, CkCatalogItemMarginType, CkCatalogPackaging, CkCustomerItem, CkPriceListItem } from '../core';

export async function downloadCkCatalogItemsList(
  items: CkCatalogItem[],
  options: { currency: string; hidePrices?: boolean },
): Promise<void> {
  const columns: DataColumn[] = [
    { header: 'Item Code', key: 'code' },
    { header: 'Item Packaging Name', key: 'name' },
    { header: 'Item Category', key: 'itemCategory' },
    { header: 'Qty', key: 'quantity' },
  ];

  if (!options.hidePrices) {
    columns.push({ header: `Cost (${options.currency})`, key: 'cost' });
  }

  columns.push({ header: 'No. Of Price Lists Enabled For', key: 'numberOfPriceLists' });

  const { Workbook } = (await import('exceljs')).default;
  const workbook = new Workbook();
  const data = items.map(item => {
    return {
      code: item.code,
      name: item.name,
      itemCategory: getLocalizedName(item.category),
      quantity: item.quantity,
      cost: item.cost,
      numberOfPriceLists: item.priceLists?.length ?? 0,
    };
  });

  await createWorksheet(workbook, `Ck Catalog Items`, { data, columns });

  const fileName = `ck-catalog-items-${Date.now()}.xlsx`;

  saveWorkbook(workbook, fileName);
}

export async function downloadCkCustomerItemsList(
  items: CkCustomerItem[],
  options: { currency: string; hidePrices?: boolean },
): Promise<void> {
  const columns: DataColumn[] = [
    { header: 'Item Code', key: 'code' },
    { header: 'Item Packaging Name', key: 'name' },
    { header: 'Item Category', key: 'itemCategory' },
  ];

  if (!options.hidePrices) {
    columns.push(
      { header: `Cost (${options.currency})`, key: 'cost' },
      { header: `Price (${options.currency})`, key: 'price' },
    );
  }

  const { Workbook } = (await import('exceljs')).default;
  const workbook = new Workbook();
  const data = items.map(item => {
    return {
      code: item.code,
      name: item.name,
      itemCategory: getLocalizedName(item.category),
      cost: item.cost,
      price: item.price,
    };
  });

  await createWorksheet(workbook, `Ck Customer Items`, { data, columns });

  const fileName = `ck-customer-items-${Date.now()}.xlsx`;

  saveWorkbook(workbook, fileName);
}

export async function downloadCkPriceListItemsList(
  items: CkPriceListItem[],
  options: { currency: string; hidePrices?: boolean },
): Promise<void> {
  const columns: DataColumn[] = [
    { header: 'Item Id', key: 'id' },
    { header: 'Item Code', key: 'code' },
    { header: 'Item Packaging Name', key: 'name' },
    { header: 'Item Category', key: 'itemCategory' },
  ];

  if (!options.hidePrices) {
    columns.push(
      { header: `Cost (${options.currency})`, key: 'cost' },
      { header: `Add Margin (%)`, key: 'addedMargin' },
      { header: `Price (${options.currency})`, key: 'price' },
      { header: 'Sell At Cost', key: 'sellAtCost' },
    );
  }

  const { Workbook } = (await import('exceljs')).default;
  const workbook = new Workbook();
  const data = items.map(item => {
    return {
      id: item.id,
      code: item.code,
      name: item.name,
      itemCategory: getLocalizedName(item.category),
      cost: item.cost,
      price: item.price,
      sellAtCost: item.sellAtCost,
      addedMargin: getRelativeAddedMarginInPercentage(item),
    };
  });

  await createWorksheet(workbook, `Ck Price List Items`, { data, columns });

  const fileName = `ck-price-list-items-${Date.now()}.xlsx`;

  saveWorkbook(workbook, fileName);
}

export async function downloadCkAllCatalogList(
  items: (CkPriceListItem | CkCatalogPackaging)[],
  options: { currency: string; hidePrices?: boolean },
): Promise<void> {
  const columns: DataColumn[] = [
    { header: 'Item Id', key: 'id' },
    { header: 'Item Code', key: 'code' },
    { header: 'Item Packaging Name', key: 'name' },
    { header: 'Item Category', key: 'itemCategory' },
  ];

  if (!options.hidePrices) {
    columns.push(
      { header: `Cost (${options.currency})`, key: 'cost' },
      { header: `Add Margin (%)`, key: 'addedMargin' },
      { header: `Price (${options.currency})`, key: 'price' },
      { header: 'Sell At Cost', key: 'sellAtCost' },
    );
  }

  const { Workbook } = (await import('exceljs')).default;
  const workbook = new Workbook();

  const data = items.map(item => {
    const isPriceListItem = item instanceof CkPriceListItem;

    return {
      id: item.id,
      code: item.code,
      name: item.name,
      itemCategory: getLocalizedName(item.category),
      cost: item.cost,
      price: isPriceListItem ? item.price : 0,
      sellAtCost: isPriceListItem ? item.sellAtCost : false,
      addedMargin: isPriceListItem ? getRelativeAddedMarginInPercentage(item) : 0,
    };
  });

  await createWorksheet(workbook, `Ck Entire Catalog`, { data, columns });

  const fileName = `ck-entire-catalog-${Date.now()}.xlsx`;

  saveWorkbook(workbook, fileName);
}

function getRelativeAddedMarginInPercentage(item: CkPriceListItem): number {
  if (!item.addMargin.amount || !item.cost) {
    return 0;
  }

  const value =
    item.addMargin.type === CkCatalogItemMarginType.Value
      ? (item.addMargin.amount / item.cost) * 100
      : item.addMargin.amount;

  return parseFloat(value.toFixed(2));
}
