import produce from 'immer';
import { map, tap } from 'rxjs';
import { inject, Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Action, createSelector, Selector, State, StateContext, StateToken } from '@ngxs/store';

import { BaseResponseMetadata, EntityListState, EntityListStateModel, Query, QueryBuilder } from '@supy/common';

import { CkPriceList, CkPriceListQueryProps, CkPriceListsRequestMetadata, CkPriceListState } from '../../core';
import { CkPriceListsService } from '../../services';
import {
  CkPriceListsCreate,
  CkPriceListsDeleteMany,
  CkPriceListsGet,
  CkPriceListsGetMany,
  CkPriceListsInitFilters,
  CkPriceListsPatchFilters,
  CkPriceListsPatchRequestMetadata,
  CkPriceListsResetFilters,
  CkPriceListsUpdate,
} from '../actions';

export interface CkPriceListsFilters {
  readonly search: string;
}

export interface CkPriceListsStateModel extends EntityListStateModel<CkPriceList> {
  readonly filters: CkPriceListsFilters;
  readonly requestMetadata: CkPriceListsRequestMetadata;
  readonly responseMetadata: BaseResponseMetadata;
}

const DEFAULT_FILTERS: CkPriceListsFilters = {
  search: null,
};

const DEFAULT_REQUEST_METADATA: CkPriceListsRequestMetadata = {
  retailerId: null,
  page: 0,
  limit: 25,
};

const DEFAULT_RESPONSE_METADATA: BaseResponseMetadata = {
  count: 0,
  total: 0,
};

const CK_PRICE_LISTS_STATE_TOKEN = new StateToken<CkPriceListsStateModel>('ckPriceLists');

@State<CkPriceListsStateModel>({
  name: CK_PRICE_LISTS_STATE_TOKEN,
  defaults: {
    ...EntityListState.default(),
    filters: DEFAULT_FILTERS,
    requestMetadata: DEFAULT_REQUEST_METADATA,
    responseMetadata: DEFAULT_RESPONSE_METADATA,
  },
})
@Injectable()
export class CkPriceListsState extends EntityListState {
  readonly #priceListsService = inject(CkPriceListsService);

  constructor(protected readonly router: Router) {
    super(router);
  }

  @Selector() static priceLists(state: CkPriceListsStateModel) {
    return EntityListState.all(state);
  }

  static priceList(id: string, next?: boolean) {
    return createSelector([CkPriceListsState], (state: CkPriceListsStateModel) => {
      return EntityListState.one(id, next)(state);
    });
  }

  @Selector()
  static currentPriceList(state: CkPriceListsStateModel) {
    return EntityListState.current(state);
  }

  @Selector()
  static isFirst(state: CkPriceListsStateModel) {
    return EntityListState.isFirst(state);
  }

  @Selector()
  static isLast(state: CkPriceListsStateModel) {
    return EntityListState.isLast(state);
  }

  @Selector()
  static filters(state: CkPriceListsStateModel) {
    return EntityListState.currentFilters<CkPriceListsFilters>(state);
  }

  @Selector()
  static requestMetadata(state: CkPriceListsStateModel) {
    return state.requestMetadata;
  }

  @Selector()
  static responseMetadata(state: CkPriceListsStateModel) {
    return state.responseMetadata;
  }

  @Action(CkPriceListsInitFilters)
  initFilters(ctx: StateContext<CkPriceListsStateModel>) {
    super.initFilter(ctx, DEFAULT_FILTERS);
  }

  @Action(CkPriceListsPatchFilters)
  patchFilters(ctx: StateContext<CkPriceListsStateModel>, { payload }: CkPriceListsPatchFilters) {
    super.patchFilter(ctx, payload, DEFAULT_FILTERS);
    ctx.dispatch([new CkPriceListsPatchRequestMetadata({ page: 0 }), new CkPriceListsGetMany()]);
  }

  @Action(CkPriceListsResetFilters)
  resetFilters(ctx: StateContext<CkPriceListsStateModel>) {
    super.resetFilter(ctx, {
      ...DEFAULT_FILTERS,
    });
  }

  @Action(CkPriceListsPatchRequestMetadata)
  patchRequestMetadata(ctx: StateContext<CkPriceListsStateModel>, { payload }: CkPriceListsPatchRequestMetadata) {
    ctx.setState(
      produce(draft => {
        draft.requestMetadata = {
          ...ctx.getState().requestMetadata,
          ...payload,
        };
      }),
    );
  }

  @Action(CkPriceListsGetMany)
  getMany(ctx: StateContext<CkPriceListsStateModel>) {
    return this.#priceListsService.getMany(this.getQuery(ctx.getState())).pipe(
      tap(({ data, metadata }) => {
        ctx.patchState({
          responseMetadata: metadata,
        });
        super.setMany(ctx, CkPriceList.deserializeList(data));
      }),
    );
  }

  @Action(CkPriceListsGet)
  get(ctx: StateContext<CkPriceListsStateModel>, { payload }: CkPriceListsGet) {
    return super.getOne(ctx, {
      id: payload.id,
      fetchApi: () => this.#priceListsService.get(payload.id).pipe(map(response => CkPriceList.deserialize(response))),
    });
  }

  @Action(CkPriceListsCreate)
  create(ctx: StateContext<CkPriceListsStateModel>, { payload }: CkPriceListsCreate) {
    return this.#priceListsService.create(payload).pipe(
      tap(response => {
        ctx.setState(
          produce(draft => {
            const customer = CkPriceList.deserialize(response);

            draft.entities.push(customer);
            draft.sequence.id = customer.id;
          }),
        );
      }),
    );
  }

  @Action(CkPriceListsUpdate)
  update(_: StateContext<CkPriceListsStateModel>, { id, payload }: CkPriceListsUpdate) {
    return this.#priceListsService.update(id, payload);
  }

  @Action(CkPriceListsDeleteMany)
  deleteMAny(_: StateContext<CkPriceListsStateModel>, { payload }: CkPriceListsDeleteMany) {
    return this.#priceListsService.deleteMany(payload);
  }

  private getQuery({ filters, requestMetadata }: CkPriceListsStateModel): Query<CkPriceListQueryProps> {
    const qb = new QueryBuilder<CkPriceListQueryProps>({
      filtering: [],
      paging: { offset: requestMetadata.page * requestMetadata.limit, limit: requestMetadata.limit },
    });

    qb.filtering.setFilter({
      by: 'state',
      match: CkPriceListState.Active,
      op: 'eq',
    });

    if (requestMetadata.retailerId) {
      qb.filtering.setFilter({
        by: 'retailer.id',
        match: requestMetadata.retailerId,
        op: 'eq',
      });
    }

    if (filters.search) {
      qb.filtering.setFilter({
        by: 'name',
        match: filters.search,
        op: 'like',
      });
    }

    return qb.build();
  }
}
