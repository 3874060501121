import { getApiDetailsDecorator } from '@supy/common';
import { NonFunctionProperties } from '@supy/core';

import { CkPriceList } from '../price-list';
import {
  CkCatalogItemMargin,
  CkCatalogItemMarginType,
  CkCatalogItemPricingMethod,
  CkCatalogPriceListResponse,
} from './ck-catalog-item.model';

const ApiProperty = getApiDetailsDecorator<CkCatalogPriceListResponse>();

export abstract class CkCatalogPriceListBase {
  protected constructor(args: NonFunctionProperties<CkCatalogPriceListBase>) {
    this.id = args.id;
    this.price = args.price;
    this.sellAtCost = args.sellAtCost;
    this.addMargin = args.addMargin;
    this.pricingMethod = args.pricingMethod;
  }

  @ApiProperty() readonly id: string;
  @ApiProperty() readonly price: number;
  @ApiProperty() readonly addMargin: CkCatalogItemMargin;
  @ApiProperty() readonly pricingMethod: CkCatalogItemPricingMethod;

  readonly sellAtCost: boolean;
}

export class CkCatalogPriceList extends CkCatalogPriceListBase {
  private constructor(args: NonFunctionProperties<CkCatalogPriceList>) {
    super(args);

    this.name = args.name;
    this.isActive = args.isActive;
  }

  @ApiProperty() readonly name: string;
  readonly isActive: boolean;

  static deserialize(data: CkCatalogPriceListResponse): CkCatalogPriceList {
    return new CkCatalogPriceList({
      id: data.id,
      name: data.name,
      price: data.price,
      sellAtCost: data.pricingMethod === CkCatalogItemPricingMethod.AtCost,
      addMargin: data.addMargin,
      pricingMethod: data.pricingMethod,
      isActive: true,
    });
  }

  static from(data: CkPriceList): CkCatalogPriceList {
    return new CkCatalogPriceList({
      id: data.id,
      name: data.name,
      price: 0,
      sellAtCost: false,
      addMargin: {
        amount: 0,
        type: CkCatalogItemMarginType.Percentage,
      },
      pricingMethod: CkCatalogItemPricingMethod.AtCost,
      isActive: false,
    });
  }
}
