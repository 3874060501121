import { removeEmpty } from '@supy/common';
import { NonFunctionProperties } from '@supy/core';

import { CkCustomerBase } from './ck-customer.entity';
import { CkCustomerCreateRequest, CkCustomerRequest, CkCustomerUpdateRequest } from './ck-customer.model';
import { CkCustomerContactPayload } from './ck-customer-contact.payload';

export abstract class CkCustomerPayload extends CkCustomerBase {
  constructor(args: NonFunctionProperties<CkCustomerPayload>) {
    super(args);
    this.customerGroupId = args.customerGroupId;
    this.priceListId = args.priceListId;
  }

  readonly customerGroupId: string;
  readonly priceListId: string;

  protected serializeBase(): CkCustomerRequest {
    return {
      ...removeEmpty({
        address: this.businessAddress,
        code: this.code,
        customerGroup: { id: this.customerGroupId },
        displayName: this.displayName,
        legalName: this.businessLegalName,
        preferences: {
          paymentTerms: this.paymentTerms,
          minimumOrderLimit: this.minimumOrderLimit,
        },
        taxRegistrationNumber: this.taxRegistrationNumber,
        priceList: { id: this.priceListId },
      }),
      contacts: this.contacts.map(contact => new CkCustomerContactPayload(contact).serialize()),
      externalId: this.supyRetailerId,
    };
  }
}

export class CkCustomerCreatePayload extends CkCustomerPayload {
  constructor(args: NonFunctionProperties<CkCustomerCreatePayload>) {
    super(args);
    this.retailerId = args.retailerId;
  }

  readonly retailerId: string;

  serialize(): CkCustomerCreateRequest {
    return {
      ...super.serializeBase(),
      externalId: this.supyRetailerId,
      retailer: { id: this.retailerId },
    };
  }
}

export class CkCustomerUpdatePayload extends CkCustomerPayload {
  constructor(args: NonFunctionProperties<CkCustomerUpdatePayload>) {
    super(args);
  }

  serialize(): CkCustomerUpdateRequest {
    return {
      ...super.serializeBase(),
    };
  }
}
