import { Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';

import { IdType, IQueryResponse, Query } from '@supy/common';

import { CREDIT_NOTES_URI } from '../../config';
import {
  CreditNoteQueryParams,
  CreditNoteResponse,
  CreditNoteUploadFileResponse,
  UpdateCreditNoteRequest,
} from '../../core';

@Injectable({
  providedIn: 'root',
})
export class CreditNoteService {
  constructor(
    protected readonly httpClient: HttpClient,
    @Inject(CREDIT_NOTES_URI) private readonly uri: string,
  ) {}

  getCreditNotes(query: Query<CreditNoteQueryParams>): Observable<IQueryResponse<CreditNoteResponse>> {
    return this.httpClient.get<IQueryResponse<CreditNoteResponse>>(this.uri, { params: query.toQueryParams() });
  }

  getCreditNote(grnId: string): Observable<CreditNoteResponse> {
    return this.httpClient.get<CreditNoteResponse>(`${this.uri}/${grnId}`);
  }

  updateCreditNote(id: string, body: UpdateCreditNoteRequest): Observable<CreditNoteResponse> {
    return this.httpClient.patch<CreditNoteResponse>(`${this.uri}/${id}`, body);
  }

  archiveCreditNotes(creditNotes: IdType[]): Observable<CreditNoteResponse> {
    return this.httpClient.post<CreditNoteResponse>(`${this.uri}/archive`, { creditNotes });
  }

  uploadFile(file: FormData, retailerId: string): Observable<CreditNoteUploadFileResponse> {
    return this.httpClient.post<CreditNoteUploadFileResponse>(`${this.uri}/upload`, file, {
      params: {
        retailerId,
      },
    });
  }
}
