import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

export interface CkWidget {
  readonly header: string;
  readonly content: string | number;
}

@Component({
  selector: 'supy-ck-widget',
  templateUrl: './ck-widget.component.html',
  styleUrls: ['./ck-widget.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CkWidgetComponent {
  @Input() protected readonly isInitialLoading: boolean;
  @Input() protected readonly header: string;
  @Input() protected readonly content: string | number;
}
