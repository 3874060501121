import { BaseRequestMetadata, BaseResponseMetadata, IdType, LocalizedData, SupplierItem } from '@supy/common';
import { OrderStatus, SimpleEntity, StatefulOrderEntity } from '@supy/orders';
import { Packaging } from '@supy/packaging';

export enum CkOrderItemType {
  Item = 'item',
  SemiFinished = 'semi-finished',
}

export interface CkOrderItemResponse {
  readonly supplier: IdType;
  readonly packaging?: Packaging;
  readonly category: SimpleEntity;
  readonly name: LocalizedData;
  readonly type: CkOrderItemType;
  readonly price: StatefulOrderEntity;
  readonly retailerItem: IdType;
  readonly supplierItem?: SupplierItem;
  readonly inventoryItem?: IdType;
  readonly recipe?: IdType;
  readonly deliveryDate: Date | number;
  readonly status: OrderStatus;
  readonly quantity: CkOrderItemQuantity;
  readonly orders: CkAggregatedOrderResponse[];
  readonly orderItemIds: string[];
  readonly retailerItemCode: string;
}

export interface CkOrderItemQuantity extends StatefulOrderEntity {
  readonly stock: number;
  readonly expectingStock: number;
  readonly expected: number;
}

export interface CkAggregatedOrderLocationResponse extends SimpleEntity {
  readonly branch: SimpleEntity;
}

export interface CkAggregatedOrderResponse {
  readonly orderId: string;
  readonly orderNumber: string;
  readonly location: CkAggregatedOrderLocationResponse;
  readonly quantity: StatefulOrderEntity;
  readonly price: StatefulOrderEntity;
}

export interface CkOrderItemsRequestProps {
  readonly 'retailer.id': string;
  readonly 'supplier.id': string;
  readonly deliveryDate: number;
  readonly retailerItemCode: string;
  readonly 'name.en': string;
  readonly 'name.ar': string;
  readonly orderId: string;
  readonly status: string;
  readonly type: string;
  readonly 'category.id': string;
}

export interface CkOrdersRequestMetadata extends BaseRequestMetadata {
  readonly retailerId: string;
}

export interface CkOrderItemsRequestMetadata extends BaseRequestMetadata {
  readonly retailerId: string;
  readonly supplierId?: string;
  readonly orderId?: string;
}

export interface GetCkOrderItemsResponse {
  readonly data: CkOrderItemResponse[];
  readonly metadata: BaseResponseMetadata;
}
