import { NonFunctionProperties } from '@supy/core';

import { CkCustomerGroupBase } from './ck-customer-group.entity';
import {
  CkCustomerGroupCreateRequest,
  CkCustomerGroupRequest,
  CkCustomerGroupUpdateRequest,
} from './ck-customer-group.model';

export abstract class CkCustomerGroupPayload extends CkCustomerGroupBase {
  protected constructor(args: NonFunctionProperties<CkCustomerGroupPayload>) {
    super(args);
  }

  protected serializeBase(): CkCustomerGroupRequest {
    return {
      name: this.name,
      defaultTaxCode: this.defaultTaxCode,
    };
  }
}

export class CkCustomerGroupCreatePayload extends CkCustomerGroupPayload {
  constructor(args: NonFunctionProperties<CkCustomerGroupCreatePayload>) {
    super(args);
    this.retailerId = args.retailerId;
  }

  readonly retailerId: string;

  serialize(): CkCustomerGroupCreateRequest {
    return {
      ...super.serializeBase(),
      retailer: { id: this.retailerId },
    };
  }
}

export class CkCustomerGroupUpdatePayload extends CkCustomerGroupPayload {
  constructor(args: NonFunctionProperties<CkCustomerGroupUpdatePayload>) {
    super(args);
  }

  serialize(): CkCustomerGroupUpdateRequest {
    return {
      ...super.serializeBase(),
    };
  }
}
