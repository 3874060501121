import { IANATimezone } from '@supy.api/dictionaries';

import { getApiDetailsDecorator, getRetailerTimeZoneShiftedDate } from '@supy/common';
import { NonFunctionProperties } from '@supy/core';

import {
  CkCustomerReturnDocumentAttachment,
  CkCustomerReturnDocumentRequest,
} from './ck-customer-return-document.model';

const ApiProperty = getApiDetailsDecorator<CkCustomerReturnDocumentRequest>();

export class CkCustomerReturnDocumentPayload {
  constructor(args: NonFunctionProperties<CkCustomerReturnDocumentPayload>) {
    this.number = args.number;
    this.returnDate = args.returnDate;
    this.attachments = args.attachments;
    this.ianaTimeZone = args.ianaTimeZone;
    this.utcOffset = args.utcOffset;
  }

  @ApiProperty() readonly number: string;
  @ApiProperty({ key: 'documentDate' }) readonly returnDate: Date;
  @ApiProperty({ key: 'files' }) attachments?: CkCustomerReturnDocumentAttachment[];

  readonly ianaTimeZone: IANATimezone;
  readonly utcOffset: number;

  serialize(): CkCustomerReturnDocumentRequest {
    return {
      number: this.number,
      files: this.attachments.map(({ path }) => path),
      documentDate: getRetailerTimeZoneShiftedDate(new Date(this.returnDate), this.ianaTimeZone, this.utcOffset),
    };
  }

  appendFiles(files: string[]): void {
    this.attachments = this.attachments.concat(files.map(file => ({ path: file, signedUrl: file })));
  }
}
