import { ChangeDetectionStrategy, Component, Input, input, output, ViewChild } from '@angular/core';
import { NoopSortingStrategy } from '@infragistics/igniteui-angular';
import { Currency, IANATimezone } from '@supy.api/dictionaries';

import { GridComponent } from '@supy/components';
import { getLocalizedName } from '@supy/settings';

import { CreationSourceEnum, Order } from '../../../core';
import { OrdersRequestMetadata, OrdersResponseMetadata } from '../../../store';

@Component({
  selector: 'supy-retailer-orders-grid',
  templateUrl: './retailer-orders-grid.component.html',
  styleUrls: ['./retailer-orders-grid.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class RetailerOrdersGridComponent {
  @ViewChild(GridComponent) protected readonly grid: GridComponent;
  @Input() protected readonly currency: Currency;
  @Input() protected readonly currencyPrecision: number;
  @Input() protected readonly isLoading: boolean;
  @Input() protected readonly orders: Order[];
  @Input() protected readonly requestMetadata: OrdersRequestMetadata;
  @Input() protected readonly responseMetadata: OrdersResponseMetadata;
  @Input() protected readonly isInventoryOrder: boolean;
  @Input() protected readonly ianaTimeZone: IANATimezone;

  protected readonly hidePriceColumn = input<boolean>();

  readonly pageChanged = output<number>();
  readonly poNumberClicked = output<Order>();
  readonly cellClicked = output<Order>();

  protected readonly sortStrategy = NoopSortingStrategy.instance();
  protected readonly CreationSource = CreationSourceEnum;

  get selectedRows(): Order[] {
    return this.grid ? this.orders?.filter(({ id }) => this.grid.getSelectedRows().includes(id)) : [];
  }

  protected readonly getLocalizedName = getLocalizedName;
}
