<header class="retailer-order-details-drawer-header">
  <div class="retailer-order-details-drawer-header__icons-wrapper">
    <div class="retailer-order-details-drawer-header__icons">
      <supy-icon name="close-circle" title="Close" size="medium" (click)="closeClicked.emit()"></supy-icon>
    </div>
    <div class="retailer-order-details-drawer-header__icons">
      <ng-content></ng-content>
      <supy-button
        type="icon"
        title="Previous"
        color="default"
        name="previous"
        [disabled]="previousDisabled"
        (click)="previousClicked.emit(order)"
      >
        <supy-icon name="long-arrow-left-circle"></supy-icon>
      </supy-button>
      <supy-button
        type="icon"
        title="Next"
        color="default"
        name="next"
        [disabled]="nextDisabled"
        (click)="nextClicked.emit(order)"
      >
        <supy-icon name="long-arrow-right-circle"></supy-icon>
      </supy-button>
      <supy-dropdown-toggle [dropdownRef]="supyDropdown" position="bottom-left" action>
        <supy-button color="default" type="icon" name="more-actions">
          <supy-icon name="dots"></supy-icon>
        </supy-button>
      </supy-dropdown-toggle>
      <supy-dropdown #supyDropdown action>
        @if (!hidePrices()) {
          <supy-dropdown-item (click)="downloadPdfClicked.emit()">
            <supy-icon name="document-download"></supy-icon>
            <span i18n="@@common.actions.downloadPdf">Download PDF</span>
          </supy-dropdown-item>
        }
      </supy-dropdown>
    </div>
  </div>
  <div class="retailer-order-details-drawer-header__title-wrapper">
    <div *ngIf="isInitialLoading" class="retailer-order-details-drawer-header__title-wrapper--loading"></div>
    <div class="retailer-order-details-drawer-header__title" (click)="expandClicked.emit(order)">
      <h4>Order # {{ order?.number }}</h4>
      <supy-icon name="maximize" size="small" title="Expand"></supy-icon>
    </div>
    <supy-retailer-order-status-badge
      [status]="order?.status"
      [isInventoryOrder]="isInventoryOrder"
      [isCkOrder]="isCkOrder"
    ></supy-retailer-order-status-badge>
  </div>
</header>
