export enum GrnDocumentType {
  Invoice = 'invoice',
  DeliveryNote = 'delivery-note',
  ConsolidatedInvoice = 'consolidated-invoice',
}

export interface GrnDocumentAttachment {
  readonly path: string;
  readonly signedUrl: string;
}

export interface GrnBaseDocumentResponse {
  readonly type: GrnDocumentType;
  readonly number: string;
  readonly documentDate?: Date;
  readonly invoiceAmount?: number;
  readonly isPaid?: boolean;
  readonly pending?: boolean;
}

export interface GrnSimpleDocumentResponse extends GrnBaseDocumentResponse {
  readonly files?: string[];
}

export interface GrnDocumentResponse extends GrnBaseDocumentResponse {
  readonly paymentDueDate?: Date;
  readonly signedPdfUrl?: string;
  readonly attachments?: GrnDocumentAttachment[];
}

export type GrnDocumentRequest = Omit<GrnDocumentResponse, 'attachments'> & {
  readonly files?: string[];
};
