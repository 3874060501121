import { getApiDetailsDecorator, LocalizedData } from '@supy/common';
import { NonFunctionProperties } from '@supy/core';

import { CkCatalogItemPackagingResponse, CkCatalogPriceList } from '../catalog';
import { CkCustomer } from './ck-customer.entity';
import { CkCustomerItemResponse } from './ck-customer.model';

const ApiProperty = getApiDetailsDecorator<CkCustomerItemResponse>();

export class CkCustomerItem {
  private constructor(args: NonFunctionProperties<CkCustomerItem>) {
    this.category = args.category;
    this.code = args.code;
    this.cost = args.cost;
    this.id = args.id;
    this.isActive = args.isActive;
    this.name = args.name;
    this.packaging = args.packaging;
    this.price = args.price;
    this.priceLists = args.priceLists;
  }

  @ApiProperty() readonly cost: number;
  @ApiProperty() readonly id: string;
  @ApiProperty() readonly packaging: CkCatalogItemPackagingResponse;
  @ApiProperty() readonly priceLists: CkCatalogPriceList[];
  readonly category: LocalizedData | null;
  readonly code: string;
  readonly isActive: boolean;
  readonly name: string;
  readonly price: number;

  static deserialize(data: CkCustomerItemResponse, { customer }: DeserializedArgs): CkCustomerItem {
    const priceLists = data.priceLists.map(priceList => CkCatalogPriceList.deserialize(priceList));
    const customerExcludedItemIds = new Set(customer.excludedCkItemIds);
    const matchedPriceList = priceLists.find(({ id }) => id === customer.priceList?.id);
    const price = matchedPriceList ? matchedPriceList.price : 0;

    return new CkCustomerItem({
      category: data.retailerItem.category.name as LocalizedData,
      code: data.packaging.code,
      cost: data.cost,
      id: data.id,
      isActive: !customerExcludedItemIds.has(data.id),
      name: data.packaging.name,
      packaging: data.packaging,
      price,
      priceLists,
    });
  }
}

interface DeserializedArgs {
  readonly customer: CkCustomer;
}
