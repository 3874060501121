import { Observable } from 'rxjs';
import { Injectable } from '@angular/core';
import { GrnActionEnum, ResourceEnum } from '@supy.api/permissions';

import { IsAllowedRequest, PermissionHelper, PermissionStrategy, Principal, Resource } from '@supy/authz';

@Injectable({ providedIn: 'root' })
class Lock extends PermissionStrategy {
  constructor(private readonly permissionHelper: PermissionHelper) {
    super();
  }

  request(resource?: Resource, principal?: Principal): Observable<IsAllowedRequest> {
    return this.permissionHelper.checkIsAllowed({
      store: this.store,
      kind: ResourceEnum.Grn,
      action: GrnActionEnum.Lock,
      resource,
      principal,
    });
  }
}

@Injectable({ providedIn: 'root' })
class Create extends PermissionStrategy {
  constructor(private readonly permissionHelper: PermissionHelper) {
    super();
  }

  request(resource?: Resource, principal?: Principal): Observable<IsAllowedRequest> {
    return this.permissionHelper.checkIsAllowed({
      store: this.store,
      kind: ResourceEnum.Grn,
      action: GrnActionEnum.Create,
      resource,
      principal,
    });
  }
}

@Injectable({ providedIn: 'root' })
class CreateWithoutOrder extends PermissionStrategy {
  constructor(private readonly permissionHelper: PermissionHelper) {
    super();
  }

  request(resource?: Resource, principal?: Principal): Observable<IsAllowedRequest> {
    return this.permissionHelper.checkIsAllowed({
      store: this.store,
      kind: ResourceEnum.Grn,
      action: GrnActionEnum.CreateWithoutOrder,
      resource,
      principal,
    });
  }
}

@Injectable({ providedIn: 'root' })
class UpdateQuantities extends PermissionStrategy {
  constructor(private readonly permissionHelper: PermissionHelper) {
    super();
  }

  request(resource?: Resource, principal?: Principal): Observable<IsAllowedRequest> {
    return this.permissionHelper.checkIsAllowed({
      store: this.store,
      kind: ResourceEnum.Grn,
      action: GrnActionEnum.UpdateQuantities,
      resource,
      principal,
    });
  }
}

@Injectable({ providedIn: 'root' })
class UpdateInvoicePrices extends PermissionStrategy {
  constructor(private readonly permissionHelper: PermissionHelper) {
    super();
  }

  request(resource?: Resource, principal?: Principal): Observable<IsAllowedRequest> {
    return this.permissionHelper.checkIsAllowed({
      store: this.store,
      kind: ResourceEnum.Grn,
      action: GrnActionEnum.UpdateInvoicePrices,
      resource,
      principal,
    });
  }
}

@Injectable({ providedIn: 'root' })
class UpdateItemPrices extends PermissionStrategy {
  constructor(private readonly permissionHelper: PermissionHelper) {
    super();
  }

  request(resource?: Resource, principal?: Principal): Observable<IsAllowedRequest> {
    return this.permissionHelper.checkIsAllowed({
      store: this.store,
      kind: ResourceEnum.Grn,
      action: GrnActionEnum.UpdateItemPrices,
      resource,
      principal,
    });
  }
}

@Injectable({ providedIn: 'root' })
class Draft extends PermissionStrategy {
  constructor(private readonly permissionHelper: PermissionHelper) {
    super();
  }

  request(resource?: Resource, principal?: Principal): Observable<IsAllowedRequest> {
    return this.permissionHelper.checkIsAllowed({
      store: this.store,
      kind: ResourceEnum.Grn,
      action: GrnActionEnum.Draft,
      resource,
      principal,
    });
  }
}

@Injectable({ providedIn: 'root' })
class UpdateLocked extends PermissionStrategy {
  constructor(private readonly permissionHelper: PermissionHelper) {
    super();
  }

  request(resource?: Resource, principal?: Principal): Observable<IsAllowedRequest> {
    return this.permissionHelper.checkIsAllowed({
      store: this.store,
      kind: ResourceEnum.Grn,
      action: GrnActionEnum.UpdateLocked,
      resource,
      principal,
    });
  }
}

@Injectable({ providedIn: 'root' })
class Post extends PermissionStrategy {
  constructor(private readonly permissionHelper: PermissionHelper) {
    super();
  }

  request(resource?: Resource, principal?: Principal): Observable<IsAllowedRequest> {
    return this.permissionHelper.checkIsAllowed({
      store: this.store,
      kind: ResourceEnum.Grn,
      action: GrnActionEnum.Post,
      resource,
      principal,
    });
  }
}

@Injectable({ providedIn: 'root' })
class ViewList extends PermissionStrategy {
  constructor(private readonly permissionHelper: PermissionHelper) {
    super();
  }

  request(resource?: Resource, principal?: Principal): Observable<IsAllowedRequest> {
    return this.permissionHelper.checkIsAllowed({
      store: this.store,
      kind: ResourceEnum.Grn,
      action: GrnActionEnum.ViewList,
      resource,
      principal,
    });
  }
}

@Injectable({ providedIn: 'root' })
class ExportList extends PermissionStrategy {
  constructor(private readonly permissionHelper: PermissionHelper) {
    super();
  }

  request(resource?: Resource, principal?: Principal): Observable<IsAllowedRequest> {
    return this.permissionHelper.checkIsAllowed({
      store: this.store,
      kind: ResourceEnum.Grn,
      action: GrnActionEnum.ExportList,
      resource,
      principal,
    });
  }
}

@Injectable({ providedIn: 'root' })
class Export extends PermissionStrategy {
  constructor(private readonly permissionHelper: PermissionHelper) {
    super();
  }

  request(resource?: Resource, principal?: Principal): Observable<IsAllowedRequest> {
    return this.permissionHelper.checkIsAllowed({
      store: this.store,
      kind: ResourceEnum.Grn,
      action: GrnActionEnum.Export,
      resource,
      principal,
    });
  }
}

@Injectable({ providedIn: 'root' })
class View extends PermissionStrategy {
  constructor(private readonly permissionHelper: PermissionHelper) {
    super();
  }

  request(resource?: Resource, principal?: Principal): Observable<IsAllowedRequest> {
    return this.permissionHelper.checkIsAllowed({
      store: this.store,
      kind: ResourceEnum.Grn,
      action: GrnActionEnum.View,
      resource,
      principal,
    });
  }
}

@Injectable({ providedIn: 'root' })
class ResolveDispute extends PermissionStrategy {
  constructor(private readonly permissionHelper: PermissionHelper) {
    super();
  }

  request(resource?: Resource, principal?: Principal): Observable<IsAllowedRequest> {
    return this.permissionHelper.checkIsAllowed({
      store: this.store,
      kind: ResourceEnum.Grn,
      action: GrnActionEnum.ResolveDispute,
      resource,
      principal,
    });
  }
}

export class GrnPermissionStrategy {
  static Lock = Lock;
  static Create = Create;
  static CreateWithoutOrder = CreateWithoutOrder;
  static UpdateQuantities = UpdateQuantities;
  static UpdateInvoicePrices = UpdateInvoicePrices;
  static UpdateItemPrices = UpdateItemPrices;
  static Draft = Draft;
  static UpdateLocked = UpdateLocked;
  static Post = Post;
  static ViewList = ViewList;
  static ExportList = ExportList;
  static Export = Export;
  static View = View;
  static ResolveDispute = ResolveDispute;
}
