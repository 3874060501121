import { NonFunctionProperties } from '@supy/core';

import { CkCatalogPriceListRequest } from './ck-catalog-item.model';
import { CkCatalogPriceListBase } from './ck-catalog-price-list.entity';

export class CkCatalogPriceLisPayload extends CkCatalogPriceListBase {
  constructor(args: NonFunctionProperties<CkCatalogPriceLisPayload>) {
    super(args);
  }

  serialize(): CkCatalogPriceListRequest {
    return {
      id: this.id,
      price: this.price,
      addMargin: this.addMargin,
      pricingMethod: this.pricingMethod,
    };
  }
}
