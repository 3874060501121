import { NonFunctionProperties } from '@supy/core';
import { DiscountType } from '@supy/orders';

import { CkOrderItemB2b } from './ck-order-item-b2b.entity';
import { CreateCkOrderItemB2bRequest, UpdateCkOrderItemB2bRequest } from './ck-order-item-b2b.model';

export class CkOrderItemB2bPayload {
  constructor(args: NonFunctionProperties<CkOrderItemB2b>) {
    this.id = args.id;
    this.catalogItemId = args.catalogItemId;
    this.price = args.price;
    this.quantity = args.quantity;
    this.confirmedQuantity = args.confirmedQuantity;
    this.discountType = args.discountType;
    this.discountAmount = args.discountAmount;
    this.taxCode = args.tax?.taxCode;
  }

  readonly id: string;
  readonly catalogItemId: string;
  readonly price: number;
  readonly quantity: number;
  readonly confirmedQuantity: number;
  readonly discountType: DiscountType;
  readonly discountAmount: number;
  readonly taxCode: string;

  serializeCreate(): CreateCkOrderItemB2bRequest {
    return {
      ckItemId: this.catalogItemId,
      price: this.price,
      discount: {
        type: this.discountType,
        amount: this.discountAmount ?? 0,
      },
      quantity: this.quantity,
      taxCode: this.taxCode,
    };
  }

  serializeUpdate(): UpdateCkOrderItemB2bRequest {
    return {
      ...this.serializeCreate(),
      quantity: this.confirmedQuantity ?? this.quantity,
      id: this.id,
    };
  }
}
