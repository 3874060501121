import { ColDef, ICellRendererParams, ValueFormatterParams, ValueGetterParams } from '@ag-grid-community/core';
import {
  ChangeDetectionStrategy,
  Component,
  effect,
  EventEmitter,
  inject,
  Injector,
  input,
  OnInit,
  Output,
  signal,
  ViewChild,
} from '@angular/core';
import { Currency } from '@supy.api/dictionaries';

import { LocalizedData, PrecisionPipe } from '@supy/common';
import {
  GridPocComponent,
  InputCellEditorComponent,
  TextWithTooltipCellRendererComponent,
  TextWithTooltipCellRendererContext,
} from '@supy/components';
import { Writable } from '@supy/core';
import { RetailerSupplierItem } from '@supy/retailers';
import { getLocalizedName } from '@supy/settings';

@Component({
  selector: 'supy-retailer-supplier-items-grid',
  templateUrl: './retailer-supplier-items-grid.component.html',
  styleUrls: ['./retailer-supplier-items-grid.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class RetailerSupplierItemsGridComponent implements OnInit {
  @ViewChild(GridPocComponent, { static: false }) protected readonly grid: GridPocComponent<RetailerSupplierItem>;

  readonly #injector = inject(Injector);
  readonly #precision = inject(PrecisionPipe);

  readonly data = input<RetailerSupplierItem[]>();

  readonly isLocationSelected = input<boolean>();
  readonly currency = input<Currency>(Currency.UnitedArabEmiratesDirham);
  readonly currencyPrecision = input<number>();
  readonly isEditable = input<boolean>();
  readonly hidePrices = input<boolean>();
  readonly requestMetadata = input<{ page: number; limit: number }>();
  readonly responseMetadata = input<{ total: number; count: number }>();
  readonly isLoading = input<boolean>();

  @Output() readonly rowSelected = new EventEmitter<string[]>();
  @Output() readonly pageChange = new EventEmitter<number>();

  protected readonly columnDefs = signal<ColDef<RetailerSupplierItem>[]>([]);
  protected readonly defaultColDefs = signal<Partial<ColDef<RetailerSupplierItem>>>({
    suppressHeaderKeyboardEvent: () => true,
  });

  protected supplierItems: Writable<RetailerSupplierItem>[] = [];

  get rows(): RetailerSupplierItem[] {
    return this.grid ? this.grid.data : [];
  }

  get selectedRows(): RetailerSupplierItem[] {
    return this.grid ? this.grid.api?.getSelectedRows() : [];
  }

  ngOnInit(): void {
    effect(
      () => {
        const data = this.data();

        this.supplierItems = structuredClone(data ?? []);

        this.grid.setRowData(this.supplierItems);
      },
      { injector: this.#injector, allowSignalWrites: true },
    );
  }

  resetSelectedRows(): void {
    this.grid.api?.deselectAll();
  }

  protected setColumnDefs(): void {
    effect(
      () => {
        this.columnDefs.set([
          {
            width: 50,
            headerCheckboxSelection: true,
            checkboxSelection: true,
          },
          {
            headerName: $localize`:@@common.itemCode:Item Code`,
            field: 'retailerItem.code',
            cellStyle: { border: 'none' },
            flex: 0.5,
          },
          {
            headerName: $localize`:@@common.itemName:Item Name`,
            field: 'name',
            cellStyle: { border: 'none' },
            valueFormatter: ({ value }: ValueFormatterParams<RetailerSupplierItem, LocalizedData>) =>
              getLocalizedName(value),
            flex: 1.5,
          },
          {
            headerName: $localize`:@@categoryItem:Item Category`,
            field: 'category.name',
            cellStyle: { border: 'none' },
            valueFormatter: ({ value }: ValueFormatterParams<RetailerSupplierItem, LocalizedData>) =>
              getLocalizedName(value),
            flex: 1,
          },
          {
            headerName: $localize`:@@common.itemSubcategory:Item Subcategory`,
            field: 'subCategory.name',
            cellStyle: { border: 'none' },
            valueFormatter: ({ value }: ValueFormatterParams<RetailerSupplierItem, LocalizedData>) =>
              getLocalizedName(value),
            flex: 1,
          },
          {
            headerName: $localize`:@@supplier_item_name:Supplier Item Name`,
            field: 'supplierItem.name.en',
            cellEditor: InputCellEditorComponent,
            cellEditorParams: {
              context: {
                placeholder: 'Enter Name',
              },
            },
            valueFormatter: ({ data }: ValueFormatterParams<RetailerSupplierItem>) =>
              getLocalizedName(data?.supplierItem.name),
            editable: this.isEditable() && !this.isLocationSelected(),
            flex: 2,
          },
          {
            headerName: $localize`:@@supplier_item_code:Supplier Item Code`,
            field: 'itemCode',
            cellEditor: InputCellEditorComponent,
            cellEditorParams: {
              context: {
                placeholder: 'Enter Code',
              },
            },
            editable: this.isEditable() && !this.isLocationSelected(),

            flex: 1,
          },
          {
            headerName: $localize`:@@grid.headers.currentPriceCurrency.label:Current Price (${this.currency()})`,
            hide: this.hidePrices(),
            field: 'price',
            valueGetter: (params: ValueGetterParams<RetailerSupplierItem>) =>
              this.#precision.transform(params.data?.price as number, this.currencyPrecision()),
            valueFormatter: (params: ValueFormatterParams<RetailerSupplierItem, number>) =>
              this.#precision.transform(params.value as number, this.currencyPrecision()),
            editable: this.isEditable(),
            cellRendererSelector: ({ data }) => {
              const isAccurate = data?.isAccurate;

              if (!isAccurate) {
                return { component: TextWithTooltipCellRendererComponent };
              }

              return undefined;
            },
            cellRendererParams: ({
              value,
            }: ICellRendererParams<RetailerSupplierItem, number, TextWithTooltipCellRendererContext>) => {
              const context: TextWithTooltipCellRendererContext = {
                text: this.#precision.transform(value as number, this.currencyPrecision()),

                icon: {
                  name: 'info',
                  color: 'warn-strong',
                  size: 'small',
                },
              };

              return { context };
            },
            cellEditor: InputCellEditorComponent,
            cellEditorParams: {
              context: {
                numeric: true,
                placeholder: '0.00',
                precision: this.currencyPrecision(),
              },
            },
            type: 'rightAligned',
            flex: 0.8,
          },
          {
            headerName: $localize`:@@common.pricingUom:Pricing UOM`,
            field: 'supplierItem.invoiceUnit.name',
            cellStyle: { border: 'none' },
            flex: 0.7,
          },
          {
            headerName: $localize`:@@grid.headers.pricingPerPackageUom.label:Pricing Per Package UOM (${this.currency()})`,
            valueGetter: (params: ValueGetterParams<RetailerSupplierItem>) =>
              this.#precision.transform(
                this.calculateTotalPrice(params.data as RetailerSupplierItem),
                this.currencyPrecision(),
              ),
            hide: this.hidePrices(),
            cellStyle: { border: 'none' },
            type: 'rightAligned',
            flex: 1.2,
          },
        ]);
      },
      {
        injector: this.#injector,
        allowSignalWrites: true,
      },
    );
  }

  protected onPageChange(page: number): void {
    this.pageChange.emit(page);
  }

  private calculateTotalPrice(supplierItems: RetailerSupplierItem): number {
    if (
      supplierItems &&
      supplierItems.price &&
      Number(supplierItems.packaging?.baseUnit?.conversionToAtom) &&
      Number(supplierItems.supplierItem?.invoiceUnit?.conversionToAtom)
    ) {
      return (
        (supplierItems.price *
          (supplierItems.packaging?.baseUnit?.conversionToAtom ?? 0) *
          supplierItems.packaging?.quantity?.baseUnit) /
        supplierItems.supplierItem?.invoiceUnit?.conversionToAtom
      );
    }

    return 0;
  }

  clearSelection(): void {
    this.grid.clearSelection();
  }
}
