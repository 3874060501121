import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { NgxsModule } from '@ngxs/store';

import { OrdersService } from '@supy/orders';

import { CkWidgetComponent } from './components';
import {
  CkCustomerGroupsService,
  CkCustomerReturnsService,
  CkCustomersService,
  CkItemsService,
  CkOrdersService,
  CkPriceListsService,
} from './services';
import {
  CkCatalogItemsState,
  CkCustomerGroupsState,
  CkCustomerItemsState,
  CkCustomerReturnsState,
  CkCustomersState,
  CkOrderItemsState,
  CkOrdersState,
  CkPriceListItemsState,
  CkPriceListsState,
} from './store';

@NgModule({
  declarations: [CkWidgetComponent],
  imports: [
    CommonModule,
    NgxsModule.forFeature([
      CkCatalogItemsState,
      CkCustomerGroupsState,
      CkCustomerItemsState,
      CkCustomerReturnsState,
      CkCustomersState,
      CkOrderItemsState,
      CkOrdersState,
      CkPriceListItemsState,
      CkPriceListsState,
    ]),
  ],
  providers: [
    CkCustomerGroupsService,
    CkCustomerReturnsService,
    CkCustomersService,
    CkItemsService,
    CkOrdersService,
    CkPriceListsService,
    OrdersService,
  ],
  exports: [CkWidgetComponent],
})
export class CentralKitchenModule {}
