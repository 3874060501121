import { removeEmpty } from '@supy/common';
import { NonFunctionProperties } from '@supy/core';

import { CkPriceListBase } from './ck-price-list.entity';
import { CkPriceListCreateRequest, CkPriceListRequest, CkPriceListUpdateRequest } from './ck-price-list.model';

export abstract class CkPriceListPayload extends CkPriceListBase {
  protected constructor(args: NonFunctionProperties<CkPriceListPayload>) {
    super(args);
  }

  protected serializeBase(): CkPriceListRequest {
    return {
      name: this.name,
      roundPrice: this.roundPrice,
      customerIds: this.customers.map(({ id }) => id),
    };
  }
}

export class CkPriceListCreatePayload extends CkPriceListPayload {
  constructor(args: NonFunctionProperties<CkPriceListCreatePayload>) {
    super(args);
    this.retailerId = args.retailerId;
  }

  retailerId: string;

  serialize(): CkPriceListCreateRequest {
    return removeEmpty({
      ...super.serializeBase(),
      retailer: { id: this.retailerId },
    });
  }
}

export class CkPriceListUpdatePayload extends CkPriceListPayload {
  constructor(args: NonFunctionProperties<CkPriceListUpdatePayload>) {
    super(args);
  }

  serialize(): CkPriceListUpdateRequest {
    return {
      ...super.serializeBase(),
    };
  }
}
