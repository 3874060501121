import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';

import { INTEGRATION_PROVIDER_CONFIG, ProviderEnum, Tenant, TenantHealthEnum } from '../../../tenant';

@Component({
  selector: 'supy-accounting-mapping-menu',
  templateUrl: './accounting-mapping-menu.component.html',
  styleUrls: ['./accounting-mapping-menu.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AccountingMappingMenuComponent {
  @Input() readonly tenants: Tenant[];
  @Input() readonly tenantId: string;
  @Input() readonly isLoading: boolean;

  @Output() readonly tenantSelected: EventEmitter<string> = new EventEmitter<string>();

  protected readonly tenantHealth = TenantHealthEnum;

  protected onSelectTenant(tenant?: Tenant): void {
    if (tenant?.status === TenantHealthEnum.OK) {
      this.tenantSelected.emit(tenant?.id);
    }
  }

  protected getProviderLogoUrl(provider: ProviderEnum): string {
    return `/assets/images/${provider}.svg`;
  }

  protected getProviderDisplayName(provider: ProviderEnum): string {
    return `${INTEGRATION_PROVIDER_CONFIG[provider]?.displayName}`;
  }
}
