import {
  CkDeleteManyRequest,
  CkPriceListCreatePayload,
  CkPriceListsRequestMetadata,
  CkPriceListUpdatePayload,
} from '../../core';
import { CkPriceListsFilters } from '../state';

export class CkPriceListsGet {
  static readonly type = '[CkPriceLists] Get';
  constructor(readonly payload: { readonly id: string }) {}
}

export class CkPriceListsGetMany {
  static readonly type = '[CkPriceLists] GetMany';
}

export class CkPriceListsInitFilters {
  static readonly type = '[CkPriceLists] InitFilters';
}

export class CkPriceListsPatchFilters {
  static readonly type = '[CkPriceLists] PatchFilters';
  constructor(readonly payload?: Partial<CkPriceListsFilters>) {}
}

export class CkPriceListsResetFilters {
  static readonly type = '[CkPriceLists] ResetFilters';
}

export class CkPriceListsPatchRequestMetadata {
  static readonly type = '[CkPriceLists] PatchRequestMetadata';
  constructor(readonly payload?: Partial<CkPriceListsRequestMetadata>) {}
}

export class CkPriceListsCreate {
  static readonly type = '[CkPriceLists] Create';
  constructor(readonly payload: CkPriceListCreatePayload) {}
}

export class CkPriceListsUpdate {
  static readonly type = '[CkPriceLists] Update';
  constructor(
    readonly id: string,
    readonly payload: CkPriceListUpdatePayload,
  ) {}
}

export class CkPriceListsDeleteMany {
  static readonly type = '[CkPriceLists] DeleteMany';
  constructor(readonly payload: CkDeleteManyRequest) {}
}
