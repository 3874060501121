import {
  getApiDetailsDecorator,
  isExistent,
  LocalizedSkeletonObjectType,
  SkeletonObjectType,
  Supplier,
} from '@supy/common';
import { NonFunctionProperties } from '@supy/core';

import { AggregatedLinkedOrder, SimpleChannel } from '../order';
import { GrnMetadata, GrnStatus, SimpleGrnResponse } from './grn.model';
import { GrnSimpleDocument } from './grn-document.entity';
import { GrnDocumentType } from './grn-document.model';
import { GrnTotals } from './grn-totals.entity';

const ApiProperty = getApiDetailsDecorator<SimpleGrnResponse>();

type SimpleGrnArgs = Pick<
  SimpleGrn,
  | 'channel'
  | 'creditIssued'
  | 'document'
  | 'hasBeenConsolidated'
  | 'id'
  | 'isSynced'
  | 'linkedOrder'
  | 'location'
  | 'metadata'
  | 'outlet'
  | 'status'
  | 'supplier'
  | 'totals'
>;

export class SimpleGrn {
  private constructor(args: NonFunctionProperties<SimpleGrnArgs>) {
    this.channel = args.channel;
    this.creditIssued = args.creditIssued ?? false;
    this.document = args.document;
    this.hasBeenConsolidated = args.hasBeenConsolidated;
    this.id = args.id;
    this.isSynced = args.isSynced ?? false;
    this.linkedOrder = args.linkedOrder ?? null;
    this.location = args.location;
    this.metadata = args.metadata ?? null;
    this.outlet = args.outlet;
    this.status = args.status;
    this.supplier = args.supplier;
    this.totals = args.totals;

    // Computed properties
    this.isConsolidatedInvoiceType = this.document.type === GrnDocumentType.ConsolidatedInvoice;
    this.isDeliveryNote = this.document.type === GrnDocumentType.DeliveryNote;
    this.isInvoice = this.document.type === GrnDocumentType.Invoice;
    this.isPosted = this.status === GrnStatus.Posted;
    this.isSaved = this.status === GrnStatus.Saved;
    this.poNumber = this.linkedOrder?.number ?? null;
  }

  @ApiProperty() readonly channel: SimpleChannel;
  @ApiProperty() readonly creditIssued?: boolean;
  @ApiProperty() readonly document: GrnSimpleDocument;
  @ApiProperty() readonly id: string;
  @ApiProperty() readonly isSynced?: boolean;
  @ApiProperty() readonly location: SkeletonObjectType;
  @ApiProperty() readonly metadata: GrnMetadata | null;
  @ApiProperty() readonly outlet: LocalizedSkeletonObjectType;
  @ApiProperty() readonly status: GrnStatus;
  @ApiProperty() readonly supplier: Supplier;
  @ApiProperty() readonly totals?: GrnTotals;
  @ApiProperty({ key: 'consolidatedInvoice' }) readonly hasBeenConsolidated?: boolean;
  @ApiProperty({ key: 'linkedOrders' }) readonly linkedOrder: AggregatedLinkedOrder | null;

  // Computed properties
  readonly isConsolidatedInvoiceType: boolean;
  readonly isDeliveryNote: boolean;
  readonly isInvoice: boolean;
  readonly isPosted: boolean;
  readonly isSaved: boolean;
  readonly poNumber: string;

  static deserialize(data: SimpleGrnResponse): SimpleGrn {
    return new SimpleGrn({
      channel: data.channel,
      creditIssued: data.creditIssued,
      document: GrnSimpleDocument.deserialize(data.document),
      hasBeenConsolidated: isExistent(data.consolidatedInvoice),
      id: data.id,
      isSynced: data.isSynced,
      linkedOrder: data.linkedOrders?.at(0),
      location: data.location,
      metadata: data.metadata,
      outlet: data.outlet,
      status: data.status,
      supplier: data.supplier,
      totals: GrnTotals.deserialize(data.totals),
    });
  }
}
