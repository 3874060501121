import { IANATimezone } from '@supy.api/dictionaries';

import { getApiDetailsDecorator, IdType, removeEmpty } from '@supy/common';
import { NonFunctionProperties } from '@supy/core';

import {
  AggregatedLinkedOrder,
  Grn,
  GrnBaseRequest,
  GrnCreateRequest,
  GrnDocument,
  GrnItemCreateRequest,
  GrnItemUpdateRequest,
  GrnStatus,
  GrnTotals,
  GrnUpdateRequest,
} from '../../entities';
import { GrnDocumentPayload } from './grn-document.payload';
import { GrnItemCreatePayload, GrnItemUpdatePayload } from './grn-item.payload';
import { GrnTotalsPayload } from './grn-totals.payload';

const ApiProperty = getApiDetailsDecorator<GrnCreateRequest | GrnUpdateRequest>();

type GrnBasePayloadArgs = Pick<Grn, 'status' | 'document' | 'items' | 'comment' | 'totals'> & {
  readonly pushToStock?: boolean;
  readonly ianaTimeZone: IANATimezone;
  readonly utcOffset: number;
  readonly closeOrders: boolean;
  readonly adjustmentRate: number;
  readonly updatePackaging?: boolean;
};

export abstract class GrnBasePayload {
  protected constructor(args: GrnBasePayloadArgs) {
    this.status = args.status;
    this.document = args.document;
    this.comment = args.comment;
    this.pushToStock = args.pushToStock ?? false;
    this.closeOrders = args.closeOrders ?? false;
    this.ianaTimeZone = args.ianaTimeZone;
    this.utcOffset = args.utcOffset;
  }

  @ApiProperty() readonly status: GrnStatus;
  @ApiProperty() document: GrnDocument;
  @ApiProperty() readonly comment: string;
  @ApiProperty() readonly pushToStock?: boolean;
  @ApiProperty() readonly closeOrders?: boolean;

  readonly ianaTimeZone: IANATimezone;
  readonly utcOffset: number;

  protected serializeBase(): GrnBaseRequest {
    return {
      status: this.status,
      document: new GrnDocumentPayload({
        ...this.document,
        number: this.document.number.trim(),
        ianaTimeZone: this.ianaTimeZone,
        utcOffset: this.utcOffset,
      }).serialize(),
      comment: this.comment,
      pushToStock: this.pushToStock,
      closeOrders: this.closeOrders,
    };
  }

  appendFiles(files: string[]): void {
    this.document = {
      ...this.document,
      attachments: this.document.attachments.concat(files.map(file => ({ path: file, signedUrl: file }))),
    };
  }
}

type GrnCreatePayloadArgs = GrnBasePayloadArgs & Pick<Grn, 'linkedOrder' | 'supplier' | 'location' | 'channel'>;

export class GrnCreatePayload extends GrnBasePayload {
  constructor(args: NonFunctionProperties<GrnCreatePayloadArgs>) {
    super(args);
    this.items = args.items;
    this.linkedOrders = args.linkedOrder ? [args.linkedOrder] : [];
    this.totals = args.totals;
    this.supplier = args.supplier;
    this.location = args.location;
    this.channel = args.channel;
  }

  @ApiProperty() readonly items: GrnItemCreateRequest[];
  @ApiProperty() readonly linkedOrders: AggregatedLinkedOrder[];
  @ApiProperty() readonly totals: GrnTotals;
  @ApiProperty() readonly supplier: IdType;
  @ApiProperty() readonly location: IdType;
  @ApiProperty() readonly channel: IdType;

  serialize(): GrnCreateRequest {
    return removeEmpty({
      ...this.serializeBase(),
      items: this.items.map(item =>
        new GrnItemCreatePayload({
          channelItem: item.channelItem,
          prices: item.prices,
          type: item.type,
          quantities: item.quantities,
          appliedTax: item.appliedTax,
          allocatedCreditNote: item.allocatedCreditNote,
          comment: item.comment,
          partiallyReceived: item.partiallyReceived,
          adjustmentRate: this.totals.adjustmentRate,
          source: item.source,
        }).serialize(),
      ),
      linkedOrders: this.linkedOrders.map(({ id }) => ({ id })),
      supplier: { id: this.supplier.id },
      location: { id: this.location.id },
      channel: { id: this.channel.id },
      totals: new GrnTotalsPayload(this.totals).serialize(),
    });
  }
}

export class GrnUpdatePayload extends GrnBasePayload {
  constructor(args: GrnUpdatePayloadArgs) {
    super(args);
    this.items = args.items;
    this.totals = args.totals;
    this.updatePackaging = args.updatePackaging;
  }

  @ApiProperty() readonly items: GrnItemUpdateRequest[];
  @ApiProperty() readonly totals?: GrnTotals;
  readonly updatePackaging?: boolean;

  serialize(): GrnUpdateRequest {
    return removeEmpty({
      ...this.serializeBase(),
      items: this.items.map(item =>
        new GrnItemUpdatePayload({
          id: item.id,
          channelItem: item.channelItem,
          prices: item.prices,
          type: item.type,
          quantities: item.quantities,
          appliedTax: item.appliedTax,
          allocatedCreditNote: item.allocatedCreditNote,
          comment: item.comment,
          partiallyReceived: item.partiallyReceived,
          adjustmentRate: this.totals.adjustmentRate,
          source: item.source,
        }).serialize(),
      ),
      totals: new GrnTotalsPayload(this.totals).serialize(),
    });
  }
}

type GrnUpdatePayloadArgs = GrnBasePayloadArgs & Pick<Grn, 'totals'>;
