import { capital } from 'case';
import { IANATimezone } from '@supy.api/dictionaries';

import {
  createWorksheet,
  DataColumn,
  DEFAULT_IANA_TIMEZONE,
  getUTCOverriddenDateTime,
  saveWorkbook,
} from '@supy/common';
import { getLocalizedName } from '@supy/settings';

import { CreditNote } from '../core';

export async function downloadCreditNotesList(
  items: CreditNote[],
  currency: string,
  ianaTimeZone: IANATimezone = DEFAULT_IANA_TIMEZONE,
  hidePrices?: boolean,
): Promise<void> {
  const columns: DataColumn[] = [
    { header: 'Branch', key: 'name' },
    { header: 'Supplier', key: 'supplierName' },
    { header: 'Type', key: 'creditType' },
    { header: 'Package Item Name', key: 'itemName' },
    { header: 'Created Date', key: 'createdAt' },
    { header: 'Origin Document No.', key: 'issuerGrn' },
    { header: 'Status', key: 'status' },
    { header: 'Allocated Document No.', key: 'allocatedGrn' },
  ];

  if (!hidePrices) {
    columns.splice(4, 0, { header: `Total (${currency})`, key: 'total' });
  }

  const currencyCols = new Set(['total']);
  const dateCols = new Set(['createdAt']);

  const { Workbook } = (await import('exceljs')).default;
  const workbook = new Workbook();

  const data = items.map(item => {
    return {
      ...item,
      name: `${getLocalizedName(item?.outlet?.name)} - ${item.location?.name}`,
      supplierName: item.supplier?.name,
      creditType: `${capital(item.creditType)} Credit`,
      itemName: getLocalizedName(item.item?.name),
      status: capital(item.status),
      createdAt: getUTCOverriddenDateTime(new Date(item.createdAt), ianaTimeZone),
      issuerGrn: item.originDocument?.number,
      allocatedGrn: item.allocatedDocument?.number,
    };
  });

  await createWorksheet(workbook, `Credit Notes`, { data, columns, currencyCols, dateCols });

  const fileName = `credit-notes-${new Date().toISOString()}.xlsx`;

  saveWorkbook(workbook, fileName);
}
