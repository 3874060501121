import { capital } from 'case';
import { Currency, IANATimezone } from '@supy.api/dictionaries';

import { createWorksheet, DataColumn, DEFAULT_IANA_TIMEZONE, getDateInTimeZone, saveWorkbook } from '@supy/common';
import { getLocalizedName } from '@supy/settings';

import { GrnDocumentType, SimpleGrn } from '../core';

const GrnDocumentTypeMapping: Record<GrnDocumentType, string> = {
  [GrnDocumentType.Invoice]: $localize`:@@invoice:Invoice`,
  [GrnDocumentType.DeliveryNote]: $localize`:@@deliveryNote:Delivery Note`,
  [GrnDocumentType.ConsolidatedInvoice]: $localize`:@@grns.list.typeMapping.consolidatedInvoice:Consolidated Invoice`,
};

export async function downloadGrnList(
  items: SimpleGrn[],
  currency: Currency,
  ianaTimeZone: IANATimezone = DEFAULT_IANA_TIMEZONE,
  hidePrices?: boolean,
): Promise<void> {
  const columns: DataColumn[] = [
    { header: 'Document #', key: 'documentNumber' },
    { header: 'Branch & Location', key: 'locationName' },
    { header: 'Supplier', key: 'supplierName' },
    { header: 'Document Date', key: 'documentDate' },
  ];

  if (!hidePrices) {
    columns.push(
      { header: `Grand Total (${currency})`, key: 'total' },
      { header: `Tax Amount (${currency})`, key: 'taxAmount' },
    );
  }

  columns.push(
    { header: 'Document Type', key: 'type' },
    { header: 'Status', key: 'status' },
    { header: 'Document Pending', key: 'pending' },
    { header: 'Credit Issued', key: 'creditIssued' },
  );

  const currencyCols = new Set(['total', 'taxAmount']);
  const dateCols = new Set(['documentDate']);

  const { Workbook } = (await import('exceljs')).default;
  const workbook = new Workbook();

  const data = items.map(item => {
    return {
      ...item,
      documentNumber: item.document.number,
      supplierName: item.supplier.name,
      documentDate: getDateInTimeZone(new Date(item.document.documentDate), ianaTimeZone),
      locationName: `${getLocalizedName(item.outlet?.name)} - ${item.location.name}`,
      total: item.isDeliveryNote ? '-' : item.totals.total,
      taxAmount: item.isDeliveryNote ? '-' : item.totals.taxAmount,
      type: GrnDocumentTypeMapping[item.document.type],
      status: capital(item.status),
      pending: item.document.isPending ?? false,
      isSynced: item.isSynced ?? false,
      creditIssued: item.creditIssued ?? false,
    };
  });

  await createWorksheet(workbook, 'Goods Received Notes', { data, columns, currencyCols, dateCols });

  const fileName = `grns-list-${new Date().toISOString()}.xlsx`;

  saveWorkbook(workbook, fileName);
}
